import { DateTime } from 'luxon';

import Enums from '../../../generated-types/Enums';
import { QuoteStatus } from '../../../generated-types/QuoteStatus/QuoteStatus';
import { UserInfo } from '../../../generated-types/UserInfo/UserInfo';
import { FormikQuote, QuoteFormikType, QuoteSubmitType } from '../QuoteFormik';

type canEditCurrentRevisionProps = {
  requiresQuoteApprovals: boolean;
  savedQuoteStatus: QuoteStatus | undefined;
};

/**
 * CanEditCurrentRevision returns true if the user can save changes to this
 * Quote, and false if they must "save as new" to create a new Quote revision
 * upon making (non-status) changes.
 *
 * A user can edit a quote if there are no quote policies or quote approvals
 * are not enabled, or if the quote's status has isDraft=true.
 */
export const CanEditCurrentRevision = ({
  requiresQuoteApprovals,
  savedQuoteStatus,
}: canEditCurrentRevisionProps): boolean =>
  !requiresQuoteApprovals ||
  savedQuoteStatus === undefined ||
  savedQuoteStatus.isDraft ||
  savedQuoteStatus.isApprovalRequest;

export type QuoteDrawerState =
  | 'quoteLoading'
  | 'edit'
  | 'violationsLoading'
  | 'reviewViolations'
  | 'confirmApproved'
  | 'send'
  | 'error';

export const makeDefaultFormikQuote = ({
  userInfo,
  submitType,
}: {
  userInfo: UserInfo;
  submitType: QuoteSubmitType;
}): QuoteFormikType =>
  FormikQuote(
    {
      // Default to 30 days from now.
      expirationDate: DateTime.now().plus({ days: 30 }),
      user: userInfo.userIfRoles([Enums.RoleName.Salesperson]),
    },
    userInfo.hasFlags([Enums.FeatureFlagName.FeatureFlagDispatchCustomer]),
    submitType,
  );
