import { Add, Edit } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { TabBar } from '../../components/TabBar/TabBar';
import { NewContact } from '../../generated-types/Contact/Contact';
import Enums from '../../generated-types/Enums';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { NIL_UUID, randomUUID } from '../../utils/UUID';
import { ContactDrawer } from '../Contacts/ContactDrawer';
import { ProjectTable } from '../Projects/ProjectTable';
import { QuoteTable } from '../Quotes/QuoteTable';
import { CompanyDrawer } from './CompanyDrawer';
import { CompanyActivities } from './components/CompanyActivities';
import { ContactTable } from './components/ContactTable';

export const CompanyDetailsPage = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const [contactDrawerKey, setContactDrawerKey] = useState(randomUUID());

  const companyID = params?.id ?? NIL_UUID;

  const {
    isLoading,
    isError,
    data: company,
  } = useSlabQuery('GET company by ID', {
    pathParams: {
      id: companyID,
    },
  });

  const { toastHook: companyToastHook, ...companyDrawerProps } = useDrawerManager({
    baseUrl: '/companies',
    resourceTypeName: 'company',
    drawerProps: {
      resourceID: company?.id ?? null,
    },
  });

  const { toastHook: contactToastHook, ...contactDrawerProps } = useDrawerManager({
    baseUrl: '/companies',
    resourceTypeName: 'contact',
    drawerProps: {
      resourceID: null,
      ensureDefined: () =>
        NewContact({
          company,
        }),
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || company === undefined) {
    return <div>ERROR</div>;
  }

  const title = company.name;

  const displayCompany = {
    ...company,
    ...company.address,
    dispatchCustomerName: company.dispatchCustomer?.name ?? 'Unlinked',
    dispatchCustomerCode: company.dispatchCustomer?.code ?? 'Unlinked',
    dispatchCustomerCreditStatusCode: company.dispatchCustomer?.creditStatusCode ?? 'Unlinked',
    dispatchCustomerCreditStatusDescription:
      company.dispatchCustomer?.creditStatusDescription ?? 'Unlinked',
    dispatchCustomerExplanation: (
      <Box sx={{ color: theme.palette.error.main, width: '12rem' }}>
        Projects for this Company will not be created in your dispatch system until the company is
        linked to a Dispatch Customer.
      </Box>
    ),
    marketName: company.market?.name,
  };

  return (
    <Page title={title}>
      {companyToastHook.toast}
      <CompanyDrawer {...companyDrawerProps} resourceID={company.id} />
      {contactToastHook.toast}
      <ContactDrawer
        {...contactDrawerProps}
        // Use a key that changes onSuccess to force reinitialize the formik state
        key={contactDrawerKey}
        resourceID={null}
        onSave={(): void => {
          contactToastHook.showToast('success', 'Contact created');
          contactDrawerProps.setIsOpen(false);
          setContactDrawerKey(randomUUID());
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Typography variant='h1'>{title}</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='create a contact'
            variant='secondary'
            onClick={(): void => contactDrawerProps.setIsOpen(true)}
            icon={Add}
          />
          <ButtonPill
            text='edit company'
            variant='primary'
            onClick={(): void => companyDrawerProps.setIsOpen(true)}
            icon={Edit}
          />
        </Box>
      </Box>
      <Box display='flex'>
        <Box width='fit-content'>
          <DisplayField
            title='Company Details'
            onEditClick={(): void => companyDrawerProps.setIsOpen(true)}
            value={displayCompany}
            displayMatrix={[
              [
                { type: 'default', label: 'ID', key: 'alternateID' },
                { type: 'default', label: 'Category', key: 'category' },
              ],
              [{ type: 'default', label: 'Market', key: 'marketName' }],
            ]}
          />
          <Box paddingTop='1.25rem' />
          {!usesDispatchCustomer ? null : (
            <>
              <DisplayField
                title='Dispatch Customer'
                onEditClick={(): void => companyDrawerProps.setIsOpen(true)}
                value={displayCompany}
                displayMatrix={
                  company.dispatchCustomer !== null
                    ? [
                        [
                          { type: 'default', label: 'Code', key: 'dispatchCustomerCode' },
                          { type: 'default', label: 'Name', key: 'dispatchCustomerName' },
                        ],
                        [
                          {
                            type: 'default',
                            label: 'Credit Code',
                            key: 'dispatchCustomerCreditStatusCode',
                          },
                          {
                            type: 'default',
                            label: 'Credit Description',
                            key: 'dispatchCustomerCreditStatusDescription',
                          },
                        ],
                      ]
                    : [[{ type: 'default', label: '', key: 'dispatchCustomerExplanation' }]]
                }
              />
              <Box paddingTop='1.25rem' />
            </>
          )}
          <DisplayField
            title='Office Address'
            onEditClick={(): void => companyDrawerProps.setIsOpen(true)}
            value={displayCompany}
            displayMatrix={[
              [
                { type: 'default', label: 'Line 1', key: 'line1' },
                { type: 'default', label: 'Line 2', key: 'line2' },
              ],
              [
                { type: 'default', label: 'Zip/Postal', key: 'postalCode' },
                { type: 'default', label: 'City/Town', key: 'city' },
              ],
              [
                { type: 'default', label: 'State/County', key: 'state' },
                { type: 'default', label: 'Country', key: 'country' },
              ],
            ]}
          />
          <Box paddingTop='1.25rem' />
          <DisplayField
            title='Notes'
            onEditClick={(): void => companyDrawerProps.setIsOpen(true)}
            value={displayCompany}
            displayMatrix={[[{ key: 'notes', type: 'notes', maxWidth: '25rem', label: '' }]]}
          />
        </Box>
        <Box paddingLeft='2.5rem' flexGrow={1} sx={{ overflowX: 'hidden', overflowY: 'visible' }}>
          <TabBar
            tabs={[
              {
                label: 'Activity',
                element: <CompanyActivities company={company} />,
              },
              {
                label: 'Contacts',
                element: <ContactTable companyID={companyID} />,
              },
              {
                label: 'Projects',
                element: (
                  <Box padding='1rem'>
                    <Box height='400px'>
                      <ProjectTable
                        tableName='company-projects'
                        typeAndReferences={{ type: 'company projects', companyID: company.id }}
                      />
                    </Box>
                  </Box>
                ),
              },
              {
                label: 'Quotes',
                element: (
                  <Box padding='1rem'>
                    <Box height='400px'>
                      <QuoteTable
                        tableName='company-quotes'
                        initialSortBy={{
                          name: 'name',
                          direction: Enums.SortDirection.Ascending,
                        }}
                        initialFilterBy={[
                          {
                            operation: Enums.FilterOperation.Equals,
                            name: 'companyId',
                            value: company.id,
                          },
                        ]}
                        // Empty function passed in because the "send message" column is omitted.
                        onSendClick={(): void => {}}
                        optionalColumnRemovals={['isApprovedToSend']}
                      />
                    </Box>
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Page>
  );
};
