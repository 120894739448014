import { Add } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { TaskList } from '../../components/TaskList/TaskList';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { UserActivities } from './components/UserActivities';

export const ActivitiesList = (): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityID, setActivityID] = useState<string | null>(null);

  const { isLoading, isError, data: activityList } = useSlabQuery('GET activities', {});

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || activityList === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  return (
    <Page title='Activities'>
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Activities</Typography>
        <ButtonPill
          text='add activity'
          variant='primary'
          onClick={(): void => {
            setActivityID(null);
            setIsDrawerOpen(true);
          }}
          icon={Add}
        />
      </Box>

      <Grid container height='800px'>
        <Grid item xs={9}>
          <UserActivities
            currentDate={currentDate}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            activityID={activityID}
            setActivityID={setActivityID}
          />
        </Grid>
        <Grid item xs={3}>
          <Box paddingX='1.5rem'>
            <TaskList
              activities={activityList.items}
              currentDate={currentDate}
              setIsDrawerOpen={setIsDrawerOpen}
              setActivityID={setActivityID}
            />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
