import { Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import { useLocalTableData } from '../../../components/DataTable/useLocalTableData';
import { Currency } from '../../../generated-types/Currency/Currency';
import { QuotePriceEscalation } from '../../../generated-types/QuotePriceEscalation/QuotePriceEscalation';
import { formatAsPercent } from '../../../utils/DomainHelpers';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';
import { randomUUID } from '../../../utils/UUID';

type PriceEscalationRow = {
  id: string;
  escalationDate: DateTime;
  changeRatio: number | null;
  flatChange: Currency | null;
};

type AdditonalProductTableProps = {
  escalationsResult: PossiblyAsyncResult<QuotePriceEscalation[] | undefined>;
  onEditEscalations: () => void;
};

export const PriceEscalationTable = ({
  escalationsResult,
  onEditEscalations,
}: AdditonalProductTableProps): JSX.Element => {
  const escalations = escalationsResult?.data ?? [];
  const rows = escalations.map(
    (qpe): PriceEscalationRow => ({
      id: randomUUID(),
      escalationDate: qpe.escalationDate,
      changeRatio: qpe.changeRatio === null ? null : Number(qpe.changeRatio) * 100,
      flatChange: qpe.flatChange,
    }),
  );

  const columns: ColumnConfig<PriceEscalationRow, NestedKeyOf<PriceEscalationRow>>[] = [
    {
      id: 'escalationDate',
      label: 'Escalation date',
      type: 'DateTime',
      isDisplayed: true,
    },
    {
      id: 'changeRatio',
      label: 'Percent change',
      type: 'number',
      isDisplayed: true,
      formatValueToString: formatAsPercent,
    },
    {
      id: 'flatChange',
      label: 'Flat change',
      type: 'Currency',
      isDisplayed: true,
    },
  ];

  const tableModel = useLocalTableData({
    rowData: { ...escalationsResult, data: rows },
    transformRows: (r) => r,
    columns,
    initialSortPath: 'escalationDate',
  });

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' paddingBottom='1.5rem' alignItems='center'>
        <Typography variant='h4'>Price escalations</Typography>
        <ButtonPill
          text='edit escalations'
          variant='primary'
          size='small'
          onClick={onEditEscalations}
          icon={Edit}
        />
      </Box>
      <DataTable tableName='quote-price-escalations' tableModel={tableModel} />
    </Box>
  );
};
