import { Add, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { useToast } from '../../components/Toast/useToast';
import { NewQuoteConfig, QuoteConfig } from '../../generated-types/QuoteConfig/QuoteConfig';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { NIL_UUID } from '../../utils/UUID';
import { QuoteConfigSection } from './components/QuoteConfigDrawerSections';
import { QuoteConfigProductTable } from './components/QuoteConfigProductTable';
import { QuoteConfigDrawer } from './QuoteConfigDrawer';

export const QuoteConfigDetailsPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const [isCloning, setIsCloning] = useState(false);

  const [initialSection, setInitialSection] = useState<QuoteConfigSection | undefined>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { showToast, toast } = useToast('quote config');

  const {
    isLoading,
    isError,
    data: quoteConfig,
  } = useSlabQuery('GET quote config by ID', {
    pathParams: {
      id: params?.id ?? '',
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || quoteConfig === undefined) {
    return <div>ERROR</div>;
  }

  const displayQuoteConfig = {
    ...quoteConfig,
    plantName: quoteConfig.plant.name,
  };

  const title = quoteConfig.name;

  return (
    <Page title={title}>
      {toast}
      <QuoteConfigDrawer
        quoteConfigID={quoteConfig.id}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        initialSection={initialSection}
        onSuccess={(qc): void => {
          showToast('success');
          setIsDrawerOpen(false);
          if (isCloning) {
            setIsCloning(false);
            navigate(`/quoteconfigs/${qc.id}`);
          }
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
        onClose={(): void => {
          setIsCloning(false);
          setInitialSection(undefined);
        }}
        ensureDefined={(curQuoteConfig): QuoteConfig => {
          if (isCloning && curQuoteConfig !== undefined) {
            return NewQuoteConfig({
              ...curQuoteConfig,
              id: NIL_UUID,
              name: `${curQuoteConfig.name} - Copy`,
            });
          }
          return curQuoteConfig ?? QuoteConfig.zero();
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Typography variant='h1'>{title}</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='clone'
            variant='secondary'
            onClick={(): void => {
              setInitialSection('Details*');
              setIsCloning(true);
              setIsDrawerOpen(true);
            }}
            icon={Add}
          />
          <ButtonPill
            text='edit quote config'
            variant='primary'
            onClick={(): void => {
              setInitialSection('Details*');
              setIsDrawerOpen(true);
            }}
            icon={Edit}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        <DisplayField
          title='Details'
          onEditClick={(): void => {
            setInitialSection('Details*');
            setIsDrawerOpen(true);
          }}
          value={displayQuoteConfig}
          displayMatrix={[
            [
              {
                key: 'plantName',
                label: 'Location',
                type: 'uri',
                uri: `/plants/${quoteConfig.plant.id}`,
              },
            ],
            [{ key: 'terms', label: 'Terms', type: 'default' }],
          ]}
        />
      </Box>
      <Box paddingTop='2.5rem'>
        <QuoteConfigProductTable
          quoteConfigID={quoteConfig.id}
          onEditProducts={(): void => {
            setInitialSection('Default additional products');
            setIsDrawerOpen(true);
          }}
        />
      </Box>
    </Page>
  );
};
