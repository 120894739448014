import { useTheme } from '@mui/material';
import { Logout } from 'iconsax-react';
import LogRocket from 'logrocket';
import { FC, useCallback } from 'react';

import { useSlabAuth } from '../../hooks/useSlabAuth';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from './components/styles';

interface AppLogoutProps {
  isOpen: boolean;
}

const AppLogout: FC<AppLogoutProps> = ({ isOpen }) => {
  const theme = useTheme();
  const { logout } = useSlabAuth();

  const logoutHandler = useCallback(() => {
    LogRocket.startNewSession();

    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={logoutHandler} isOpen={isOpen}>
        <ListItemIcon>
          <Logout color={theme.palette.SlabGray['500']} />
        </ListItemIcon>
        <ListItemText isOpen={isOpen} primary='Log Out' />
      </ListItemButton>
    </ListItem>
  );
};

export default AppLogout;
