import { Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { PlantMap } from '../../components/Map/PlantMap';
import { Page } from '../../components/Page/Page';
import { TabBar } from '../../components/TabBar/TabBar';
import { useToast } from '../../components/Toast/useToast';
import Enums from '../../generated-types/Enums';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { NullableCostString, NullableCurrencyString } from '../../utils/Currency';
import { NIL_UUID } from '../../utils/UUID';
import { ForecastTable } from './components/ForecastTable';
import { PlantSection } from './components/PlantDrawerSections';
import { PlantDrawer } from './PlantDrawer';

export const PlantDetailsPage = (): JSX.Element => {
  const params = useParams();

  const [initialSection, setInitialSection] = useState<PlantSection | undefined>();
  const tablePreservedState = localStorage.getItem('plants-forecasts');
  const tableState = tablePreservedState !== null && JSON.parse(tablePreservedState);
  const defaultYear =
    tableState.yearFilter?.value !== null ? tableState.yearFilter?.value : undefined;
  const [selectedYear, setSelectedYear] = useState(defaultYear ?? DateTime.now().year);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { showToast, toast } = useToast('location');

  const plantID = params?.id ?? NIL_UUID;

  const {
    isLoading,
    isError,
    data: plant,
  } = useSlabQuery('GET plant by ID', {
    pathParams: {
      id: plantID,
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || plant === undefined) {
    return <div>ERROR</div>;
  }

  const title = plant.name;

  const displayPlant = {
    ...plant,
    sgaCostDisplay: NullableCostString({ cost: plant?.readyMixConstants?.sgaCost ?? null }),
    operatingCostDisplay: NullableCostString({
      cost: plant?.readyMixConstants?.operatingCost ?? null,
    }),
    minimumMarginDisplay: plant?.readyMixConstants?.minimumMarginDisplay(),
    targetMarginDisplay: plant?.readyMixConstants?.targetMarginDisplay(),
    deliveryCostOverrideDisplay: NullableCostString({
      cost: plant?.deliveryCosts?.deliveryCostOverride ?? null,
      nullPlaceholder: 'N/A',
      omitNull: false,
    }),
    totalDeliveryCostDisplay: NullableCostString({
      cost: plant?.deliveryCosts?.totalDeliveryCost ?? null,
    }),
    flatChangeDisplay: NullableCurrencyString({ cur: plant.materialCostRule?.flatChange ?? null }),
    percentageChangeDisplay: `${String(
      parseFloat((parseFloat(plant.materialCostRule?.markupRatio ?? '0') * 100).toFixed(3)),
    )}%`,
    marketName: plant.market.name,
  };

  const displayFields = {
    'Location Details': (
      <DisplayField
        title='Location Details'
        onEditClick={(): void => {
          setInitialSection('Location details*');
          setIsDrawerOpen(true);
        }}
        value={displayPlant}
        displayMatrix={[
          [
            { type: 'default', label: 'Name', key: 'name' },
            { type: 'default', label: 'Market', key: 'marketName' },
          ],
        ]}
      />
    ),
    'Location Address': (
      <DisplayField
        title='Location Address'
        onEditClick={(): void => {
          setInitialSection('Location address*');
          setIsDrawerOpen(true);
        }}
        value={plant.address}
        displayMatrix={[
          [
            { type: 'default', label: 'Line 1', key: 'line1' },
            { type: 'default', label: 'Line 2', key: 'line2' },
            { type: 'default', label: 'City', key: 'city' },
          ],
          [
            { type: 'default', label: 'State', key: 'state' },
            { type: 'default', label: 'Zip', key: 'postalCode' },
            { type: 'default', label: 'Country', key: 'country' },
          ],
        ]}
      />
    ),
    'Delivery costs': (
      <DisplayField
        title='Delivery costs'
        onEditClick={(): void => {
          setInitialSection('Delivery costs');
          setIsDrawerOpen(true);
        }}
        value={displayPlant}
        displayMatrix={[
          [
            {
              type: 'default',
              label: 'Delivery cost override',
              key: 'deliveryCostOverrideDisplay',
            },
            { type: 'default', label: 'Total delivery cost', key: 'totalDeliveryCostDisplay' },
          ],
        ]}
      />
    ),
    Constants: (
      <DisplayField
        title='Constants'
        onEditClick={(): void => {
          setInitialSection('Constants*');
          setIsDrawerOpen(true);
        }}
        value={displayPlant}
        displayMatrix={[
          [
            { type: 'default', label: 'SGA Cost', key: 'sgaCostDisplay' },
            { type: 'default', label: 'Operating Cost', key: 'operatingCostDisplay' },
          ],
          [
            { type: 'default', label: 'Minimum Margin', key: 'minimumMarginDisplay' },
            { type: 'default', label: 'Target Margin', key: 'targetMarginDisplay' },
          ],
        ]}
      />
    ),
    'Material cost adjustment': (
      <DisplayField
        title='Material cost adjustment'
        onEditClick={(): void => {
          setInitialSection('Material cost adjustment');
          setIsDrawerOpen(true);
        }}
        value={displayPlant}
        displayMatrix={[
          [
            displayPlant.materialCostRule !== null &&
            displayPlant.materialCostRule.kind === Enums.CostRuleKind.Flat
              ? { type: 'default', label: 'Flat change', key: 'flatChangeDisplay' }
              : { type: 'default', label: 'Percentage change', key: 'percentageChangeDisplay' },
          ],
        ]}
      />
    ),
    Notes: (
      <DisplayField
        title='Notes'
        onEditClick={(): void => {
          setInitialSection('Notes');
          setIsDrawerOpen(true);
        }}
        value={displayPlant}
        displayMatrix={[[{ key: 'notes', type: 'notes', maxWidth: '30rem', label: '' }]]}
      />
    ),
  };

  const mapLatLng =
    plant.address.latitude != null && plant.address.longitude !== null
      ? {
          lat: parseFloat(plant.address.latitude),
          lng: parseFloat(plant.address.longitude),
        }
      : undefined;

  return (
    <Page title={title}>
      {toast}
      <PlantDrawer
        plantID={plant.id}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        initialSection={initialSection}
        initialForecastYear={selectedYear}
        onSuccess={(): void => {
          setIsDrawerOpen(false);
          showToast('success');
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
        onClose={(): void => setInitialSection(undefined)}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Typography variant='h1'>{title}</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='edit location'
            variant='primary'
            onClick={(): void => {
              setIsDrawerOpen(true);
              setInitialSection('Location details*');
            }}
            icon={Edit}
          />
        </Box>
      </Box>
      <Box display='flex' gap='2.5rem'>
        <Box display='flex' flexDirection='column' width='35%'>
          {displayFields['Location Details']}
          <Box paddingTop='1.25rem' />
          {displayFields['Location Address']}
          {plant.deliveryCosts !== null && (
            <>
              <Box paddingTop='1.25rem' />
              {displayFields['Delivery costs']}
            </>
          )}
          {plant.readyMixConstants !== null && (
            <>
              <Box paddingTop='1.25rem' />
              {displayFields.Constants}
            </>
          )}
          {plant.materialCostRule !== null && (
            <>
              <Box paddingTop='1.25rem' />
              {displayFields['Material cost adjustment']}
            </>
          )}
          <Box paddingTop='1.25rem' />
          {displayFields.Notes}
        </Box>
        <Box flexGrow={1} width='65%' sx={{ overflowX: 'hidden', overflowY: 'visible' }}>
          <TabBar
            tabs={[
              {
                label: 'Map',
                element: <PlantMap location={mapLatLng} />,
              },
              {
                label: 'Forecasts',
                element: (
                  <ForecastTable
                    plantID={plant.id}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    onEditForecasts={(): void => {
                      setInitialSection('Budget forecasts');
                      setIsDrawerOpen(true);
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Page>
  );
};
