import _ from 'lodash';
import LogRocket from 'logrocket';
import { PartialDeep } from 'type-fest';
import * as Yup from 'yup';

import {
  DeliveryCost,
  NewDeliveryCostFromDomainObject,
} from '../../generated-types/DeliveryCost/DeliveryCost';
import Enums from '../../generated-types/Enums';
import { Forecast } from '../../generated-types/Forecast/Forecast';
import { NewPlantFromDomainObject, Plant } from '../../generated-types/Plant/Plant';
import { ReadyMixConstants } from '../../generated-types/ReadyMixConstants/ReadyMixConstants';
import { DomainObject } from '../../utils/ApiClient';
import {
  FormikAddress,
  FormikCost,
  FormikCostRule,
  FormikDeliveryCosts,
} from '../../utils/FormikHelpers';
import { RemoveNullProperties } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import {
  marginPercentage,
  nonNegativeCost,
  TestForUndefined,
  YupAddress,
  YupCost,
  YupDeliveryCosts,
  YupForecasts,
  YupNullableCostRule,
  YupNullableString,
  YupPercentage,
  YupReference,
  YupSchemaDeliveryCostsType,
  YupSchemaForecastsType,
  YupSchemaNullableDeliveryCostsType,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type PlantFormikType = DomainObject<
  Omit<Plant, 'forecasts' | 'deliveryCosts' | 'market' | 'customFields'>
> & {
  budgetForecasts: YupSchemaForecastsType;
  capacityForecasts: YupSchemaForecastsType;
  deliveryCosts: YupSchemaDeliveryCostsType;
  market: YupSchemaReferenceType;
};

const sharedPlantSchemaFields = {
  name: YupString('Required'),
  address: YupAddress('Address'),
  market: YupReference('Market'),
  notes: YupNullableString('Notes'),
  category: YupString('Location category'),
  deliveryCosts: YupDeliveryCosts('Delivery costs'),
  readyMixConstants: Yup.object()
    .label('Ready mix constants')
    .shape({
      sgaCost: YupCost('SGA cost').test(nonNegativeCost),
      operatingCost: YupCost('Operating cost').test(nonNegativeCost),
      minimumMargin: YupPercentage('Minimum margin')
        .test(marginPercentage)
        .test(
          'minimum-lower-or-equal-to-target',
          'Must be less than or equal to target',
          (value, ctx) =>
            parseFloat(value ?? String(parseFloat(ctx.parent.targetMargin) + 1)) <=
            ctx.parent.targetMargin,
        ),
      targetMargin: YupPercentage('Target margin')
        .test(marginPercentage)
        .test(
          'target-higher-or-equal-to-minimum',
          'Must be more than or equal to minimum',
          (value, ctx) => parseFloat(value ?? '0') >= ctx.parent.minimumMargin,
        ),
    }),
  materialCostRule: YupNullableCostRule('Material cost rule'),
  truckingTypes: Yup.array().of(
    Yup.object().shape({
      id: YupString('Trucking type ID'),
      name: YupString('Trucking type name'),
      plantId: YupString('Location ID'),
    }),
  ),
};

/** This schema will be used for yup validation */
export const PlantSchemaFormik: Yup.SchemaOf<Omit<PlantFormikType, 'id'>> = Yup.object()
  .shape({
    ...sharedPlantSchemaFields,

    budgetForecasts: YupForecasts('Budget forecast'),
    capacityForecasts: YupForecasts('Capacity forecast'),
  })
  .test(TestForUndefined('PlantSchemaFormik'));

/** This schema will be used for yup validation */
export const PlantSchemaWire: Yup.SchemaOf<
  Omit<PlantFormikType, 'id' | 'budgetForecasts' | 'capacityForecasts'>
> = Yup.object().shape({
  ...sharedPlantSchemaFields,

  forecasts: YupForecasts('Forecasts'),
});

const FormikReadyMixConstants = (
  rmcData: ReadyMixConstants | undefined,
): DomainObject<ReadyMixConstants> => ({
  sgaCost: FormikCost(rmcData?.sgaCost),
  operatingCost: FormikCost(rmcData?.operatingCost),
  targetMargin:
    rmcData?.targetMargin !== undefined && rmcData?.targetMargin !== null
      ? String(parseFloat((parseFloat(rmcData.targetMargin) * 100).toFixed(3)))
      : '',
  minimumMargin:
    rmcData?.minimumMargin !== undefined && rmcData?.minimumMargin !== null
      ? String(parseFloat((parseFloat(rmcData.minimumMargin) * 100).toFixed(3)))
      : '',
});

export const FormikPlant = (plantData: Partial<Plant> | undefined): PlantFormikType => ({
  id: plantData?.id ?? NIL_UUID,
  name: plantData?.name ?? '',
  address: FormikAddress(plantData?.address),
  market: {
    id: plantData?.market?.id ?? null,
    option:
      plantData?.market === undefined
        ? null
        : {
            value: plantData.market.id,
            label: plantData.market.name,
          },
  },
  notes: plantData?.notes ?? null,
  category: plantData?.category ?? Enums.PlantCategory.ReadyMix,
  readyMixConstants: FormikReadyMixConstants(plantData?.readyMixConstants ?? undefined),
  deliveryCosts: FormikDeliveryCosts(plantData?.deliveryCosts ?? undefined),
  budgetForecasts: Forecast.fillForecasts({
    currentForecasts: plantData?.forecasts ?? [],
    kind: Enums.ForecastKind.Budget,
  }).map((pf): PlantFormikType['budgetForecasts'][number] => ({
    kind: pf.kind,
    value: pf.value,
    unit: pf.unit,
    intervalLength: pf.intervalLength.toISO() ?? '',
    intervalStart: pf.intervalStart.toISO() ?? '',
  })),
  capacityForecasts: Forecast.fillForecasts({
    currentForecasts: plantData?.forecasts ?? [],
    kind: Enums.ForecastKind.Capacity,
  }).map((pf): PlantFormikType['capacityForecasts'][number] => ({
    kind: pf.kind,
    value: pf.value,
    unit: pf.unit,
    intervalLength: pf.intervalLength.toISO() ?? '',
    intervalStart: pf.intervalStart.toISO() ?? '',
  })),
  materialCostRule: FormikCostRule(plantData?.materialCostRule),
  truckingTypes: plantData?.truckingTypes ?? [],
});

export const NewDeliveryCostOrNullFromFormik = (
  values: YupSchemaNullableDeliveryCostsType,
  dirtyEdits?: PartialDeep<YupSchemaNullableDeliveryCostsType>,
): DeliveryCost | null => {
  if (values === null && Object.keys(dirtyEdits ?? {}).length === 0) {
    return null;
  }

  const schema = YupDeliveryCosts('Delivery costs');
  const input: YupSchemaNullableDeliveryCostsType = _.merge({}, values, dirtyEdits);

  const drivingTimeAdjustmentPercentage = Number.isNaN(
    parseFloat(input.drivingTimeAdjustmentPercentage ?? ''),
  )
    ? null
    : (parseFloat(input.drivingTimeAdjustmentPercentage ?? '') / 100).toFixed(3);
  const unengagedTimeAdjustmentPercentage = Number.isNaN(
    parseFloat(input.unengagedTimeAdjustmentPercentage ?? ''),
  )
    ? null
    : (parseFloat(input.unengagedTimeAdjustmentPercentage ?? '') / 100).toFixed(3);

  const transformed = _.merge({}, input, {
    drivingTimeAdjustmentPercentage,
    unengagedTimeAdjustmentPercentage,
  });
  let castData: Parameters<typeof NewDeliveryCostFromDomainObject>[0];
  try {
    castData = RemoveNullProperties(schema.cast(transformed));
    // HACK: fill in default zero values for nullable sub-structs that are always non-null in practice.
    // This can't happen as part of the earlier merge because then nullable properties get their zero
    // defaults stripped by RemoveNullProperties.
    castData = _.merge(DeliveryCost.zero(), castData);
  } catch (e) {
    try {
      schema.validateSync(transformed, { strict: true, abortEarly: false });
      LogRocket.error('NewDeliveryCostFromFormik parsing failed, but validation succeeded?!');
    } catch (error) {
      const details =
        error instanceof Yup.ValidationError
          ? error.inner.map((inner) => `${inner.path}: ${inner.type}`).join('\n')
          : '';
      LogRocket.error(`NewDeliveryCostFromFormik parsing failed. ${error}\n${details}`);
    }
    castData = {};
  }
  return NewDeliveryCostFromDomainObject(castData);
};

export const NewPlantFromFormik = (
  values: PlantFormikType,
  dirtyEdits?: PartialDeep<PlantFormikType>,
): Plant => {
  const input: PlantFormikType = _.merge({}, values, dirtyEdits);

  const rmc = input.readyMixConstants ?? ReadyMixConstants.zero();
  const targetMargin = Number.isNaN(parseFloat(rmc.targetMargin))
    ? '0.000'
    : (parseFloat(rmc.targetMargin) / 100).toFixed(3);
  const minimumMargin = Number.isNaN(parseFloat(rmc.minimumMargin))
    ? '0.000'
    : (parseFloat(rmc.minimumMargin) / 100).toFixed(3);
  const strippedMaterialCostMarkupRatio = (input.materialCostRule?.markupRatio ?? '').replaceAll(
    ',',
    '',
  );
  const materialCostMarkupRatio = Number.isNaN(parseFloat(strippedMaterialCostMarkupRatio))
    ? null
    : (parseFloat(strippedMaterialCostMarkupRatio) / 100).toFixed(3);

  const transformed = _.merge({}, input, {
    readyMixConstants: {
      targetMargin,
      minimumMargin,
    },
    forecasts: [...input.capacityForecasts, ...input.budgetForecasts].map((f) => ({
      ...f,
      value: f.value?.replaceAll(',', ''),
    })),
    materialCostRule: {
      markupRatio: materialCostMarkupRatio,
    },
    deliveryCosts: NewDeliveryCostOrNullFromFormik(input.deliveryCosts),
  });
  let castData: Parameters<typeof NewPlantFromDomainObject>[0];
  try {
    castData = RemoveNullProperties(PlantSchemaFormik.cast(transformed));
    // HACK: fill in default zero values for nullable sub-structs that are always non-null in practice.
    // This can't happen as part of the earlier merge because then nullable properties get their zero
    // defaults stripped by RemoveNullProperties.
    castData = _.merge(
      {
        deliveryCosts: DeliveryCost.zero(),
        readyMixConstants: ReadyMixConstants.zero(),
      },
      castData,
    );
  } catch (e) {
    try {
      PlantSchemaFormik.validateSync(transformed, { strict: true, abortEarly: false });
      LogRocket.error('NewPlantFromFormik parsing failed, but validation succeeded?!');
    } catch (error) {
      const details =
        error instanceof Yup.ValidationError
          ? error.inner.map((inner) => `${inner.path}: ${inner.type}`).join('\n')
          : '';
      LogRocket.error(`NewPlantFromFormik parsing failed. ${error}\n${details}`);
    }
    castData = {};
  }
  return NewPlantFromDomainObject(castData);
};
