import { FC, useContext, useMemo } from 'react';

import { SlabContext } from '../../SlabContext';
import { BoundSlabRoutes } from '../../SlabRoutes';
import AppDrawerLogo from './AppDrawerLogo';
import AppLogout from './AppLogout';
import { Drawer, SectionWrapper } from './components/styles';
import NavOption from './NavOption';
import { SectionList } from './SectionList';

interface AppDrawerProps {
  isOpen: boolean;
  isMobile: boolean;
  closeDrawer(): void;
  openDrawer(): void;
}

const AppDrawer: FC<AppDrawerProps> = ({ isOpen, isMobile, closeDrawer, openDrawer }) => {
  const { userInfo } = useContext(SlabContext);

  const navigationSections = useMemo(() => BoundSlabRoutes(userInfo), [userInfo]);

  return (
    <Drawer variant='permanent' isOpen={isOpen} onClose={closeDrawer}>
      <AppDrawerLogo isOpen={isOpen} closeDrawer={isOpen ? closeDrawer : openDrawer} />

      <SectionList
        sectionWrapper={<SectionWrapper />}
        sections={navigationSections}
        keyExtractor={(_, index) => String(index)}
        sectionKeyExtractor={(_, index) => String(index)}
        renderItem={({ item }) => (
          <NavOption isMobile={isMobile} closeDrawer={closeDrawer} isOpen={isOpen} route={item} />
        )}
      />

      <SectionWrapper>
        <AppLogout isOpen={isOpen} />
      </SectionWrapper>
    </Drawer>
  );
};

export default AppDrawer;
