import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { NylasFlyout } from '../../../components/Nylas/NylasFlyout';
import Enums from '../../../generated-types/Enums';
import { QueryError } from '../../../utils/Query';
import { NIL_UUID } from '../../../utils/UUID';
import { QuoteTable } from '../../Quotes/QuoteTable';

type ProjectQuoteTableProps = {
  projectID: string;
  onEditPress: Parameters<typeof QuoteTable>[number]['onEditPress'];
  onCreatePress: () => void;
  onSendQuote: (quoteID: string | null) => void;
  onSendQuoteError: (err: QueryError) => void;
};

export const ProjectQuoteTable = ({
  projectID,
  onEditPress,
  onCreatePress,
  onSendQuote,
  onSendQuoteError,
}: ProjectQuoteTableProps): JSX.Element => {
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [quoteRowID, setQuoteRowID] = useState<string | null>(null);

  return (
    <Box padding='1rem'>
      <NylasFlyout
        isOpen={isEmailOpen}
        setIsOpen={setIsEmailOpen}
        onClose={(): void => {
          setIsEmailOpen(false);
        }}
        onSuccess={(): void => {
          onSendQuote(quoteRowID);
        }}
        onError={(err): void => {
          onSendQuoteError(err);
        }}
        quoteID={quoteRowID ?? NIL_UUID}
      />
      <Box height='400px'>
        <Box display='flex' justifyContent='flex-end' paddingBottom='1.5rem'>
          <ButtonPill
            text='create quote'
            variant='secondary'
            size='small'
            onClick={onCreatePress}
            icon={Add}
          />
        </Box>
        <QuoteTable
          tableName='project-quotes'
          initialSortBy={{
            name: 'name',
            direction: Enums.SortDirection.Ascending,
          }}
          initialFilterBy={[
            {
              operation: Enums.FilterOperation.Equals,
              name: 'projectId',
              value: projectID,
            },
          ]}
          onEditPress={onEditPress}
          onSendClick={(q): void => {
            setQuoteRowID(q.id);
            setIsEmailOpen(true);
          }}
          optionalColumnRemovals={['projectName']}
        />
      </Box>
    </Box>
  );
};
