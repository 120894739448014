import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { InitialTableData, useLocalTableData } from '../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { QuoteConfig } from '../../generated-types/QuoteConfig/QuoteConfig';
import { QuoteConfigListRow } from '../../generated-types/row-types';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { List } from '../../utils/List';
import { PossiblyAsyncResult } from '../../utils/Query';
import { NestedKeyOf } from '../../utils/Types';
import { QuoteConfigDrawer } from './QuoteConfigDrawer';

const buildTableDataModelConfig = (
  quoteConfigsResult: PossiblyAsyncResult<List<QuoteConfig> | undefined>,
): InitialTableData<QuoteConfigListRow, { id: string }, List<QuoteConfig> | undefined> => {
  const transformRows = (quoteConfigList: List<QuoteConfig> = List.zero()): QuoteConfigListRow[] =>
    quoteConfigList.items.map(
      (qc): QuoteConfigListRow => ({
        id: qc.id,
        name: qc.name,
        plant: qc.plant.name,
      }),
    );

  const columns: ColumnConfig<QuoteConfigListRow, NestedKeyOf<QuoteConfigListRow>>[] = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/quoteconfigs/${row.id}` }),
    },
    {
      id: 'plant',
      label: 'Location',
      type: 'string',
      isDisplayed: true,
    },
  ];

  return {
    rowData: quoteConfigsResult,
    transformRows,
    columns,
    initialSortPath: 'name',
  };
};

export const QuoteConfigList = (): JSX.Element => {
  const navigate = useNavigate();

  const [quoteConfigID, setQuoteConfigID] = useState<string | null>(null);

  const { toastHook: quoteConfigToastHook, ...quoteConfigDrawerProps } = useDrawerManager({
    baseUrl: '/quoteconfigs',
    resourceTypeName: 'quoteConfig',
    drawerProps: {
      resourceID: quoteConfigID,
    },
  });

  const quoteConfigsResult = useSlabQuery('GET quote configs', {});
  const { isLoading, isError, data: quoteConfigList } = quoteConfigsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig(quoteConfigsResult));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || quoteConfigList === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Page title='Quote Configs'>
      {quoteConfigToastHook.toast}
      <QuoteConfigDrawer
        {...quoteConfigDrawerProps}
        quoteConfigID={quoteConfigDrawerProps.resourceID}
        onSuccess={(quoteConfig: QuoteConfig): void => {
          if (quoteConfigID === null) {
            navigate(`/quoteconfigs/${quoteConfig.id}`);
          } else {
            quoteConfigDrawerProps.setIsOpen(false);
            quoteConfigToastHook.showToast('success');
          }
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Quote Configs</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='create config'
            variant='primary'
            onClick={(): void => {
              setQuoteConfigID(null);
              quoteConfigDrawerProps.setIsOpen(true);
            }}
            icon={Add}
          />
        </Box>
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='quote-configs'
          tableModel={tableModel}
          onEditPress={(row): void => {
            setQuoteConfigID(row.id);
            quoteConfigDrawerProps.setIsOpen(true);
          }}
          includePrint={{ title: 'Quote configs' }}
        />
      </Box>
    </Page>
  );
};
