// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class ParameterizedQuotePolicyThreshold {
  readonly kind: Enums.QuotePolicyThresholdKindShort;
  readonly productCategory: Enums.ProductCategory;
  readonly productAggregationKind: Enums.QuotePolicyThresholdProductAggregationKind;
  readonly comparator: Enums.Comparator;
  readonly description: string;
  readonly thresholdValue: string;

  constructor(data: { [key: string]: any } = {}) {
    this.kind =
      data.kind === undefined ? Enums.QuotePolicyThresholdKindShort.MarginPercent : data.kind;
    this.productCategory =
      data.productCategory === undefined ? Enums.ProductCategory.Mix : data.productCategory;
    this.productAggregationKind =
      data.productAggregationKind === undefined
        ? Enums.QuotePolicyThresholdProductAggregationKind.LineItem
        : data.productAggregationKind;
    this.comparator = data.comparator === undefined ? Enums.Comparator.Lt : data.comparator;
    this.description = data.description === undefined ? '' : data.description;
    this.thresholdValue = data.thresholdValue === undefined ? '0' : data.thresholdValue;
  }

  static zero(): ParameterizedQuotePolicyThreshold {
    const zeroValues: ExcludeMethodsDeep<ParameterizedQuotePolicyThreshold> = {
      kind: Enums.QuotePolicyThresholdKindShort.MarginPercent,
      productCategory: Enums.ProductCategory.Mix,
      productAggregationKind: Enums.QuotePolicyThresholdProductAggregationKind.LineItem,
      comparator: Enums.Comparator.Lt,
      description: '',
      thresholdValue: '0',
    };
    return new ParameterizedQuotePolicyThreshold(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewParameterizedQuotePolicyThreshold = (
  props: PartialDeep<ParameterizedQuotePolicyThreshold, { recurseIntoArrays: true }>,
): ParameterizedQuotePolicyThreshold => new ParameterizedQuotePolicyThreshold(props);

export const NewParameterizedQuotePolicyThresholdFromDomainObject = (
  props: PartialDeep<DomainObject<ParameterizedQuotePolicyThreshold>, { recurseIntoArrays: true }>,
): ParameterizedQuotePolicyThreshold => new ParameterizedQuotePolicyThreshold(props);
