import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { SlabStep, SlabStepper } from '../../../components/SlabStepper/SlabStepper';
import { Plant } from '../../../generated-types/Plant/Plant';
import { lookups } from '../../../utils/DomainHelpers';
import { ClearFormikFields } from '../../../utils/FormikHelpers';
import { DynamicCompanyLookup } from '../../Projects/components/DynamicCompanyLookup';
import { EmptyFormikProjectConfig, ProjectConfigFormikType } from '../ProjectConfigFormik';
import { ProjectConfigProductLookup } from './ProjectConfigProductLookup';

const ProjectConfigDetailsSection = (): JSX.Element => {
  const formikBag = useFormikContext<ProjectConfigFormikType>();

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='name' label='Name*' />
        </Grid>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            name='plant'
            label='Location*'
            route={{
              barrel: 'GET plants',
              args: ConstructListQueryParams,
              transform: (plantList): LookupInputOption[] =>
                lookups({
                  ...plantList,
                  label: (p: Plant) => p.name,
                }),
            }}
            onMatchChange={(plantID): void => {
              const prevPlantID = formikBag.values.plant.id;
              if (plantID === null || (plantID !== null && plantID !== prevPlantID)) {
                ClearFormikFields(formikBag, EmptyFormikProjectConfig, 'products');
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='estimatedVolumeOverride' label='Estimated Volume Override' type='number' />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='revenueOverride'
            label='Revenue Override'
            type='currency'
            startAdornment='$'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export type ProjectConfigSection = 'Details*' | 'Contractors' | 'Other companies' | 'Products';

const steps: SlabStep<ProjectConfigFormikType>[] = [
  {
    label: 'Details*',
    content: ProjectConfigDetailsSection,
    maybeErrorFieldNames: ['name', 'plant', 'estimatedVolumeOverride', 'revenueOverride'],
  },
  {
    label: 'Contractors',
    content: () => DynamicCompanyLookup({ sectionType: 'contractors' }),
    maybeErrorFieldNames: ['contractors'],
  },
  {
    label: 'Other companies',
    content: () => DynamicCompanyLookup({ sectionType: 'otherCompanies' }),
    maybeErrorFieldNames: ['otherCompanies'],
  },
  {
    label: 'Products',
    content: ProjectConfigProductLookup,
    maybeErrorFieldNames: ['products'],
  },
];

type ProjectConfigDrawerSectionsProps = {
  initialSection: ProjectConfigSection;
};

export const ProjectConfigDrawerSections = ({
  initialSection,
}: ProjectConfigDrawerSectionsProps): JSX.Element => {
  const formikBag = useFormikContext<ProjectConfigFormikType>();

  const initialStep = steps.map((s) => s.label).indexOf(initialSection);

  const stepStateHook = useState(initialStep);
  const [, setActiveStep] = stepStateHook;

  // If the initialSection changes, we use this hook to update the state
  useEffect(() => {
    const newInitialStep = steps.map((s) => s.label).indexOf(initialSection);
    setActiveStep(newInitialStep);
  }, [initialSection]);

  return (
    <SlabStepper
      steps={steps}
      activeStepHook={stepStateHook}
      isStepDisabled={(step: SlabStep<ProjectConfigFormikType>): boolean =>
        step.label === 'Products' && formikBag.values.plant.id === null
      }
    />
  );
};
