import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';

export type SelectorOption = {
  name: string;
  id: string | null;
  subLabel?: string;
};

type SelectorProps = {
  label: string;
  selectedID: string | null;
  onSelectedID: (arg0: string | null) => void;
  selectorOpts: SelectorOption[];
  /** @default '15rem' */
  selectMinWidth?: string;
  /** @default '25rem' */
  selectMaxWidth?: string;
};

const NULL_VALUE = '-_NULL-_';

/**
 * Construct a simple selector
 */
export const Selector = ({
  label,
  selectedID,
  onSelectedID,
  selectorOpts,
  selectMinWidth = '15rem',
  selectMaxWidth = '25rem',
}: SelectorProps): JSX.Element => {
  const theme = useTheme();

  return (
    <FormControl
      sx={{
        minWidth: selectMinWidth,
        maxWidth: selectMaxWidth,
        [theme.breakpoints.down('lg')]: {
          maxWidth: '100%',
          flex: 1,
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectedID ?? NULL_VALUE}
        onChange={(e): void => {
          onSelectedID(e.target.value === NULL_VALUE ? null : e.target.value);
        }}
        label={label}
      >
        {selectorOpts.map((opt) => (
          <MenuItem key={opt.id} value={opt.id ?? NULL_VALUE}>
            <Box>
              <Typography variant='body2'>
                {opt.name}
                {opt.subLabel !== undefined && (
                  <Typography className='subLabel' variant='body3'>
                    {' '}
                    {opt.subLabel}
                  </Typography>
                )}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
