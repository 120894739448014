import { ChevronLeft } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logo from '@public/logo.png';
import { FC } from 'react';

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from './components/styles';

interface AppDrawerLogoProps {
  isOpen: boolean;
  closeDrawer(): void;
}

const AppDrawerLogo: FC<AppDrawerLogoProps> = ({ isOpen, closeDrawer }) => {
  const theme = useTheme();

  return (
    <ListItem disablePadding>
      <ListItemButton isOpen={isOpen} onClick={closeDrawer} sx={{ height: '74px' }}>
        <ListItemIcon>
          {isOpen ? (
            <Box sx={{ marginLeft: '-10px' }} component='img' height={48} src={logo} />
          ) : (
            <Box component='div' sx={{ marginTop: '5px' }}>
              <MenuIcon sx={{ color: theme.palette.SlabGray[0] }} />
            </Box>
          )}
        </ListItemIcon>
        <ListItemText isOpen={isOpen} />
        {isOpen && <ChevronLeft />}
      </ListItemButton>
    </ListItem>
  );
};

export default AppDrawerLogo;
