import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { CurrencyToString } from '../../../../components/DataTable/TableDataModel';
import { Currency } from '../../../../generated-types/Currency/Currency';
import Enums from '../../../../generated-types/Enums';
import { Quote } from '../../../../generated-types/Quote/Quote';
import { NewQuoteProduct } from '../../../../generated-types/QuoteProduct/QuoteProduct';
import { NIL_UUID } from '../../../../utils/UUID';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { QuotePdf } from '../../../QuotePdf';
import { StyleFrom } from '../../../StyleHelpers';
import { PdfNotes } from '../../components/PdfNotes';
import { PdfPriceEscalationTable } from '../../components/PdfPriceEscalationTable';
import { PdfProductsTable, ProductRow } from '../../components/PdfProductsTable';
import { PdfSpacer } from '../../components/PdfSpacer';
import { PdfHeaderTable } from '../components/PdfHeaderTable';
import { PdfSignatures } from '../components/PdfSignatures';

const headerColor: Style = {
  backgroundColor: '#d8d8d8',
};

const header: Style = {
  fontSize: '10pt',
  fontFamily: 'Helvetica-Bold',
  color: '#002060',
};

const cell: Style = {
  fontSize: '8pt',
};

const centered: Style = {
  textAlign: 'center',
  justifyContent: 'center',
};

const customUnitLabels = (label: string, row: ProductRow): string => {
  if (row.id === NIL_UUID) {
    return '';
  }
  switch (label) {
    case 'CUYD':
      return '/yd3';
    case '/CUYD':
      return '/yd3';
    default:
      return label;
  }
};

type LaurenDefaultTemplateProps = {
  quote: Quote;
};

export const LaurenDefaultTemplate = ({ quote }: LaurenDefaultTemplateProps): JSX.Element => {
  const mainProducts = quote.filterProducts(Enums.QuoteProductKind.Primary);
  for (let i = 0; i <= 10; i += 1) {
    if (i > mainProducts.length) {
      mainProducts.push(NewQuoteProduct({}));
    }
  }

  return (
    <QuotePdf quote={quote} disableHyphenation includePageNumbers>
      <PdfHeaderTable quote={quote} />
      <PdfSpacer height='0.25in' />
      <PdfTextBlock
        content='Ready Mixed Concrete'
        viewStyle={{
          alignItems: 'center',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfProductsTable
        products={mainProducts}
        displayHeaders
        columnConfigs={[
          {
            id: 'index',
            type: 'number',
            label: '',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '5%',
          },
          {
            id: 'name',
            type: 'string',
            label: 'Mix Number',
            headerViewStyle: StyleFrom([headerColor, centered]),
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '15%',
          },
          {
            id: 'psi',
            type: 'number',
            label: 'PSI',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '8%',
          },
          {
            id: 'slump',
            type: 'string',
            label: 'Slump',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '8%',
          },
          {
            id: 'waterCementRatio',
            type: 'string',
            label: 'W/C',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '6%',
          },
          {
            id: 'aggregateSize',
            type: 'string',
            label: 'Agg Size',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '8%',
          },
          {
            id: 'flyAshPercentage',
            type: 'string',
            label: 'Fly %',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '8%',
          },
          {
            id: 'usage',
            type: 'string',
            label: 'Use',
            headerViewStyle: StyleFrom([headerColor, centered]),
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '24%',
          },
          {
            id: 'price',
            type: 'Currency',
            label: 'Price',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '10%',
            formatValueToString: (value: Currency, row: ProductRow): string => {
              if (row.id === NIL_UUID) {
                return '';
              }
              return CurrencyToString(value);
            },
          },
          {
            id: 'measurementUnit',
            type: 'string',
            label: 'UOM',
            headerViewStyle: StyleFrom([headerColor, centered]),
            rowViewStyle: centered,
            headerTextStyle: header,
            rowTextStyle: cell,
            width: '8%',
            formatValueToString: customUnitLabels,
          },
        ]}
        bordered
      />
      <PdfSpacer height='0.25in' />
      <PdfTextBlock
        content='Additional Products and Services'
        viewStyle={{
          alignItems: 'center',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfProductsTable
        products={quote.filterProducts(Enums.QuoteProductKind.Additional)}
        displayHeaders
        columnConfigs={[
          {
            id: 'name',
            label: 'Product Description',
            type: 'string',
            headerViewStyle: centered,
            headerTextStyle: { fontSize: '12pt' },
            rowViewStyle: centered,
            width: '60%',
          },
          {
            id: 'price',
            label: 'Price',
            type: 'Currency',
            headerViewStyle: centered,
            headerTextStyle: { fontSize: '12pt' },
            rowViewStyle: centered,
            width: '20%',
          },
          {
            id: 'measurementUnit',
            label: 'Unit',
            type: 'string',
            headerViewStyle: centered,
            headerTextStyle: { fontSize: '12pt' },
            rowViewStyle: centered,
            width: '20%',
            formatValueToString: customUnitLabels,
          },
        ]}
        striped
        bordered
      />
      {quote.priceEscalations.length === 0 ? null : (
        <View>
          <PdfSpacer height='0.25in' />
          <PdfTextBlock
            content='Escalations and Expiration'
            viewStyle={{
              alignItems: 'center',
            }}
          />
          <PdfSpacer height='0.1in' />
          <PdfPriceEscalationTable
            quotePriceEscalations={quote.priceEscalations}
            columnConfigs={[
              {
                id: 'escalationDate',
                label: 'Escalation Date',
                headerViewStyle: { textAlign: 'center' },
                rowViewStyle: { textAlign: 'center' },
              },
              {
                id: 'amount',
                label: 'Amount',
                headerViewStyle: { textAlign: 'center' },
                rowViewStyle: { textAlign: 'center' },
              },
            ]}
            displayHeaders
            striped
            bordered
          />
        </View>
      )}
      <View break />
      {quote.terms !== null && (
        <View>
          <PdfTextBlock
            content='Additional Costs and Terms:'
            viewStyle={{
              alignItems: 'center',
            }}
          />
          <PdfSpacer height='0.1in' />
          <PdfNotes quote={quote} fontSize={10} />
          <PdfSpacer height='0.25in' />
        </View>
      )}
      <PdfSignatures />
    </QuotePdf>
  );
};
