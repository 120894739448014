import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import {
  FormatErrorCell,
  FormatLinkCell,
  FormatNotSetCell,
} from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithoutAggregationsParams,
  useApiTableDataWithoutAggregations,
} from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { Mix } from '../../generated-types/Mix/Mix';
import { MixSummary } from '../../generated-types/MixSummary/MixSummary';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { SlabContext } from '../../SlabContext';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { MixDrawer } from './MixDesignDrawer';

const buildTableDataModelConfig = (
  isExternalIDEnabled: boolean,
): ApiTableDataWithoutAggregationsParams<
  MixSummary,
  'GET mix summaries',
  QueryRouteBarrelTypes['GET mix summaries']
> => {
  const columns: ColumnConfig<MixSummary, NestedKeyOf<MixSummary>>[] = [
    {
      id: 'alternateId',
      label: 'Mix ID',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/mixdesigns/${row.id}` }),
    },
    {
      id: 'plantName',
      label: 'Location',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'psi',
      label: 'PSI',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'materialCost',
      label: 'Cost per unit',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'incompatibleBatchUnits',
      label: 'Incompatible batch unit',
      tooltipText:
        '"UOM issue" mix designs have a mix material whose batch unit is incompatible with its underlying material’s cost unit.',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatErrorCell(v),
    },
    {
      id: 'slump',
      label: 'Slump',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'aggregateSize',
      label: 'Aggregate size',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'waterCementPercentage',
      label: 'Water cement ratio',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'flyAshPercentage',
      label: 'Fly ash percentage',
      type: 'string',
      isDisplayed: false,
    },
  ];

  if (isExternalIDEnabled) {
    const externalIDColumn: ColumnConfig<MixSummary, NestedKeyOf<MixSummary>> = {
      id: 'externalId',
      label: 'External ID',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatNotSetCell(v),
    };

    const nameIndex = columns.findIndex((column) => column.id === 'name');

    columns.splice(nameIndex + 1, 0, externalIDColumn);
  }

  return {
    rowsRouteKey: 'GET mix summaries',
    extractRows: (list) => list.items,
    initialSortBy: {
      name: 'name',
      direction: Enums.SortDirection.Ascending,
    },
    makeColumnConfigs: () => columns,
  };
};

export const MixList = (): JSX.Element => {
  const navigate = useNavigate();

  const ctx = useContext(SlabContext);
  const isExternalIDEnabled = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagIntegrationImport,
  ]);

  const [mixID, setMixID] = useState<string | null>(null);

  const { toastHook: mixToastHook, ...mixDrawerProps } = useDrawerManager({
    baseUrl: '/mixdesigns',
    resourceTypeName: 'mix',
    drawerProps: {
      resourceID: mixID,
    },
  });

  const tableModel = useApiTableDataWithoutAggregations(
    buildTableDataModelConfig(isExternalIDEnabled),
  );

  return (
    <Page title='Mix Designs'>
      {mixToastHook.toast}
      <MixDrawer
        {...mixDrawerProps}
        mixID={mixDrawerProps.resourceID}
        onSuccess={(mix: Mix): void => {
          if (mixID === null) {
            navigate(`/mixdesigns/${mix.id}`);
          } else {
            mixDrawerProps.setIsOpen(false);
            mixToastHook.showToast('success');
          }
        }}
        initialSection='Mix design details*'
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Mix Designs</Typography>
        <ButtonPill
          text='create mix design'
          variant='primary'
          onClick={(): void => {
            setMixID(null);
            mixDrawerProps.setIsOpen(true);
          }}
          icon={Add}
        />
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='mix-designs'
          tableModel={tableModel}
          onEditPress={(row): void => {
            setMixID(row.id);
            mixDrawerProps.setIsOpen(true);
          }}
          includePrint={{ title: 'Mix Designs' }}
          marketSelectorOpts={{ enabled: true }}
          plantSelectorOpts={{ enabled: true }}
        />
      </Box>
    </Page>
  );
};
