import { isPossiblePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';
import LogRocket from 'logrocket';
import { DateTime, Duration } from 'luxon';
import * as Yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import { MixedSchema } from 'yup/lib/mixed';
import { RequiredNumberSchema } from 'yup/lib/number';
import type { OptionalObjectSchema, RequiredObjectSchema, TypeOfShape } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import { Defined } from 'yup/lib/util/types';

import { LookupInputOption } from '../components/LookupInput/LookupInputSharedComponents';
import { Cost } from '../generated-types/Cost/Cost';
import { Currency } from '../generated-types/Currency/Currency';
import { DeliveryCost } from '../generated-types/DeliveryCost/DeliveryCost';
import Enums from '../generated-types/Enums';
import { Forecast } from '../generated-types/Forecast/Forecast';
import { Base64, stringAsBase64 } from '../types/Base64';
import { DomainObject } from './ApiClient';
import { NIL_UUID } from './UUID';

// Modifications to default Yup error messages. See
// https://github.com/jquense/yup/blob/master/src/locale.ts.
Yup.setLocale({
  mixed: {
    default: 'Invalid',
    required: 'Required',
    oneOf: 'Select an option',
  },
  number: {
    integer: 'Must be a whole number',
    positive: 'Must be positive',
  },
  string: {
    email: 'Must be a valid email',
  },
});

/** Checks that a `string | null` is a number that is non-negative. Null is considered valid. */
export const nonNegativeStringNumber = {
  name: 'number string must be non-negative',
  message: 'Must not be negative',
  test: (value: string | null): boolean => parseFloat(value ?? '0') >= 0,
};

type YupEnumType<T> = MixedSchema<NonNullable<T>, AnyObject, NonNullable<T>>;

/**
 * WARNING: This is unable to be type-safe due to Yup's MixedSchema typing.
 * For example, it will accept `Enums.Unit` as well as `Enums.Unit | null`.
 */
export const YupEnum = <T,>(obj: { [s: string]: T }, label: string): YupEnumType<T> =>
  // Yup has a bug where it doesn't narrow the type of mixed when using oneOf
  // (https://github.com/jquense/yup/issues/1230), so we need to tell it that
  // the result isn't T | undefined.
  Yup.mixed<T | ''>()
    .label(label)
    .oneOf([...Object.values(obj), ''])
    .default('')
    .required()
    .test({
      name: 'value-is-not-empty-string',
      message: 'Required',
      test: (v) => v !== '',
    }) as MixedSchema<NonNullable<T>, AnyObject>;

type YupNullableEnumType<T> = MixedSchema<Defined<T>, AnyObject, Defined<T>>;

export const YupNullableEnum = <T,>(
  obj: { [s: string]: T },
  label: string,
): YupNullableEnumType<T> =>
  Yup.mixed<T>()
    .label(label)
    .oneOf([...Object.values(obj), null])
    .default(null)
    .transform((v) => (v === '' ? null : v));

/** Yup validator for a base64-encoded string of binary data. */
export const YupBase64 = (label: string): RequiredStringSchema<Base64> =>
  Yup.string()
    .label(label)
    .required()
    .test({
      name: 'value-is-base64',
      message: 'Must be base64',
      test: (v) => stringAsBase64(v ?? '') !== undefined,
    }) as RequiredStringSchema<Base64>;

export type YupCurrencyType = RequiredObjectSchema<
  {
    number: RequiredStringSchema<string, AnyObject>;
    currency: RequiredStringSchema<string, AnyObject>;
  },
  AnyObject,
  TypeOfShape<{
    number: RequiredStringSchema<string, AnyObject>;
    currency: RequiredStringSchema<string, AnyObject>;
  }>
>;

/**
 * Basic `Currency` in a Yup shape.
 */
export const YupCurrency = (label: string): YupCurrencyType =>
  Yup.object({
    number: Yup.string()
      .label(label)
      .default('')
      .required()
      .transform((v: string) => v.replaceAll(',', ''))
      .test((v: string) => !Number.isNaN(parseFloat(v))),
    currency: Yup.string().label(`${label} currency`).default('USD').required(),
  }).required();

export type YupSchemaNullableCurrencyType = DomainObject<Currency> | null;

type YupNullableCurrencyType = OptionalObjectSchema<
  {
    number: Yup.StringSchema<string, AnyObject, string>;
    currency: Yup.StringSchema<string, AnyObject, string>;
  },
  AnyObject,
  TypeOfShape<{
    number: Yup.StringSchema<string, AnyObject, string>;
    currency: Yup.StringSchema<string, AnyObject, string>;
  }> | null
>;

/**
 * `Currency | null` in a Yup shape. Empty string numbers become null.
 */
export const YupNullableCurrency = (label: string): YupNullableCurrencyType =>
  Yup.object({
    number: Yup.string()
      .label(label)
      .default('')
      .transform((v: string) => v.replaceAll(',', ''))
      .test((v: string) => !Number.isNaN(parseFloat(v))),
    currency: Yup.string().label(`${label} currency`).default('USD').required(),
  })
    .transform((o: YupSchemaNullableCurrencyType) =>
      o === null || o.number === undefined || o.number === null || o.number === ''
        ? null
        : {
            number: o.number,
            currency: o.currency,
          },
    )
    .nullable();

type YupCurrencyReturnType = TypeOfShape<{
  number: Yup.StringSchema<string, AnyObject, string>;
  currency: Yup.StringSchema<string, AnyObject, string>;
}>;

/** Checks that a `Currency | null` has a number that is non-negative. Null is considered valid. */
export const nonNegativeCurrency = {
  name: 'currency number must be non-negative',
  message: 'Must not be negative',
  test: (value: YupCurrencyReturnType | null): boolean => parseFloat(value?.number ?? '0') >= 0,
};

export type YupSchemaCostType = {
  amount: DomainObject<Currency>;
  unit: Enums.Unit;
};

type YupCostType = RequiredObjectSchema<
  {
    amount: YupCurrencyType;
    unit: YupEnumType<Enums.Unit>;
  },
  AnyObject,
  TypeOfShape<{
    amount: YupCurrencyType;
    unit: YupEnumType<Enums.Unit>;
  }>
>;

/**
 * Basic `Cost` in a Yup shape.
 */
export const YupCost = (label: string): YupCostType =>
  Yup.object()
    .shape({
      amount: YupCurrency(label),
      unit: YupEnum(Enums.Unit, `${label} unit`),
    })
    .required();

/** Used in Yup.SchemaOf<> type declarations for ease of reading. */
export type YupSchemaNullableCostType = {
  amount: YupSchemaNullableCurrencyType;
  unit: Enums.Unit | null;
} | null;

type YupNullableCostType = OptionalObjectSchema<
  {
    amount: YupNullableCurrencyType;
    unit: YupNullableEnumType<Enums.Unit | null>;
  },
  AnyObject,
  TypeOfShape<{
    amount: YupNullableCurrencyType;
    unit: YupNullableEnumType<Enums.Unit | null>;
  }> | null
>;

/**
 * `Cost | null` in a Yup shape. Empty string numbers become null.
 */
export const YupNullableCost = (label: string): YupNullableCostType =>
  Yup.object({
    amount: YupNullableCurrency(label),
    unit: Yup.mixed<Enums.Unit>()
      .label(`${label} unit`)
      .label(`${label} unit`)
      .default(null)
      .nullable()
      .oneOf([...Object.values(Enums.Unit), null]),
  })
    .nullable()
    .transform((o: Cost | null) => {
      // Inner transforms run after outer transforms so cast it beforehand
      const transformedAmount = YupNullableCurrency(label).cast(o?.amount ?? null);
      const v = {
        amount: transformedAmount,
        unit: o?.unit ?? null,
      };
      return v === null || v.amount === null
        ? null
        : {
            amount: v.amount,
            unit: v.unit,
          };
    })
    .test('unit-required-if-amount-not-null', 'Unit Required', (value) => {
      if ((value?.amount?.number ?? '') !== '') {
        if (value?.unit === null) {
          // Cost has an amount number, but the entire cost has no unit
          return false;
        }
        // Cost has an amount number and a valid unit
        return true;
      }
      // Cost is null
      return true;
    });

type YupCostReturnType = TypeOfShape<{
  amount: YupNullableCurrencyType;
  unit: YupNullableEnumType<Enums.Unit | null>;
}>;

/** Checks that a `Cost | null` has a number that is non-negative. Null is considered valid. */
export const nonNegativeCost = {
  name: 'currency number must be non-negative',
  message: 'Must not be negative',
  test: (value: YupCostReturnType | null): boolean => parseFloat(value?.amount?.number ?? '0') >= 0,
};

type YupNullableStringType = Yup.StringSchema<string | null, AnyObject, string | null>;

/**
 * `string | null` in a Yup shape. Empty strings become null.
 */
export const YupNullableString = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .default(null)
    .nullable()
    .transform((v) => (v === '' ? null : v));

export const YupSometimesNullableString = (
  label: string,
  required: boolean,
): YupNullableStringType =>
  required ? YupNullableString(label).required() : YupNullableString(label);

export const YupString = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string().label(label).default('').required();

/** `string | undefined` in a Yup shape. */
export const YupOptionalString = (label: string): Yup.StringSchema<string | undefined, AnyObject> =>
  Yup.string().label(label).default(undefined).optional();

/**
 * `string | null` in a Yup shape. Empty strings become null. Default email formatting.
 */
export const YupNullableEmail = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .email()
    .nullable()
    .default(null)
    .transform((v) => (v === '' ? null : v));

/**
 * `string` in a Yup shape. Default email formatting.
 */
export const YupEmail = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string().label(label).email().default('').required();

// Any number of characters, followed by `.pdf`, is valid.
const pdfRegex = /.+\.pdf$/;

export const YupPDFFileName = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string()
    .label(label)
    .default('')
    .required()
    .test({
      name: 'is-valid-pdf-filename',
      message: 'Must be a valid PDF file name',
      test: (v) => pdfRegex.test(v),
    });

/**
 * `string` in a Yup shape, with default formatting for phone numbers.
 */
export const YupTel = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string()
    .label(label)
    .default('')
    .required()
    .transform((v) => {
      if (typeof v !== 'string') {
        LogRocket.error(
          'Yup attempted to transform a non-string to a phone number:',
          JSON.stringify(v),
        );
        return '';
      }
      try {
        return parsePhoneNumberWithError(v, 'US').formatInternational();
      } catch {
        return v;
      }
    })
    .test({
      name: 'must be phone number',
      message: 'Must be a valid phone number',
      test: (v) => isPossiblePhoneNumber(v, 'US'),
    });

/**
 * `string | null` in a Yup shape, with default formatting for phone numbers.
 */
export const YupNullableTel = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .nullable()
    .default(null)
    .transform((v) => {
      if (!['string', 'null'].includes(typeof v)) {
        LogRocket.error(
          'Yup attempted to transform a non-null non-string to a nullable phone number:',
          JSON.stringify(v),
        );
        return null;
      }
      try {
        return parsePhoneNumberWithError(v, 'US').formatInternational();
      } catch {
        return v === '' ? null : v;
      }
    })
    .test({
      name: 'must be phone number',
      message: 'Must be a valid phone number',
      test: (v) => v === null || isPossiblePhoneNumber(v, 'US'),
    });

/**
 * `string` in a Yup decimal shape.
 */
export const YupDecimal = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string()
    .label(label)
    .default('')
    .required()
    .transform((v) => v?.replaceAll(',', ''))
    .test({
      name: 'must be decimal',
      message: 'Must be a valid decimal',
      test: (v) => v === null || !Number.isNaN(Number(v)),
    });

/**
 * `string | null` in a Yup decimal shape. non-numbers are converted to null.
 */
export const YupNullableDecimal = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .nullable()
    .default(null)
    .transform((v) => (v === '' ? null : v?.replaceAll(',', '')))
    .test({
      name: 'must be decimal',
      message: 'Must be a valid decimal',
      test: (v) => v === null || !Number.isNaN(Number(v)),
    });

/**
 * YupSchemaNumberType is to be used for any field with type `number`. It allows
 * the beginning input to be an empty string and throwing the correct error when
 * that state is encountered. It pairs with the `YupNumber` helper.
 */
export type YupSchemaNumberType = number | string;

type YupNumberType = Lazy<
  | RequiredNumberSchema<number, AnyObject>
  | MixedSchema<string | number | null, AnyObject, string | number | null>,
  AnyObject
>;

type YupNumberOpts = {
  label: string;
  nullable?: boolean;
  positive?: boolean;
  integer?: boolean;
};

const yupNumber = (opts: YupNumberOpts): YupNumberType =>
  Yup.lazy((value: YupSchemaNumberType) => {
    let schema;
    switch (typeof value) {
      case 'number':
        schema = Yup.number().label(opts.label).default(0).required();
        if (opts.positive === true) {
          schema = schema.positive();
        }
        if (opts.integer === true) {
          schema = schema.integer();
        }
        return schema;
      case 'string':
      default:
        return (
          Yup
            // NOTE: Must use `mixed` so that we can cast it to type `number`
            .mixed<string | number | null>()
            .label(opts.label)
            .default('')
            .transform((n: string | null) =>
              n === '' || n === null ? null : Number(n?.replaceAll(',', '')),
            )
            .test({
              name: 'empty value not allowed',
              message: 'Required',
              test: (s) => {
                if (opts.nullable === true) {
                  return true;
                }
                // NOTE: we check empty string because if the original value is unchanged,
                // the transform above does not run.
                return s !== null && s !== '';
              },
            })
        );
    }
  });

// yupNumber is typed correctly, but due to the use of the `.lazy` operator, it does not adhere
//   to the underlying type of `YupSchemaNumberType` (number | '') when used in Formik.
export const YupNumber = yupNumber as unknown as (
  opts: YupNumberOpts,
) => RequiredNumberSchema<number, AnyObject>;

type YupNullableNumberType = Yup.NumberSchema<number | null, AnyObject, number | null>;

export const YupNullableNumber = (opts: Omit<YupNumberOpts, 'nullable'>): YupNullableNumberType =>
  YupNumber({
    ...opts,
    nullable: true,
  });

export type MaxFractionDigits = 0 | 1 | 2 | 3;

const fractionDigitNomenclature: Record<MaxFractionDigits, string> = {
  0: 'tenths',
  1: 'hundredths',
  2: 'thousandths',
  3: 'ten thousandths',
};

type YupTest<T> = {
  name?: string;
  message?: string;
  test: Yup.TestFunction<T, AnyObject>;
};

/**
 * This test helper verifies that the provided string (or null) only has a maximum
 * number of digits past the decimal point. It is label agnostic, as it will display
 * that the specific fractionDigit is not supported. If a parameter is not provided,
 * it defaults to `1`, allowing up to a tenths place.
 *
 * @example
 * const YupMaxHundredths = Yup
 *   .string()
 *   .test(TestForFractionDigit(2));
 */
const TestForFractionDigit = (maxFractionDigits?: MaxFractionDigits): YupTest<string | null> => {
  const fractionDigits =
    maxFractionDigits === undefined || maxFractionDigits > 3 || maxFractionDigits < 0
      ? 1
      : maxFractionDigits;
  return {
    name: `percentage adheres to max digits of ${fractionDigits}`,
    message: `${fractionDigitNomenclature[fractionDigits]} not supported`,
    test: (value: string | null): boolean =>
      (value?.split('.')?.[1]?.length ?? 0) <= fractionDigits,
  };
};

/**
 * Percent represented as a `string` in a Yup shape. Defaults to only allowing
 * 1 fraction digit
 */
export const YupPercentage = (
  label: string,
  maxFractionDigits?: MaxFractionDigits,
): Yup.StringSchema<string, AnyObject, string> =>
  Yup.string()
    .label(label)
    .default('')
    .required()
    .transform((v) => ((v ?? null) === null ? null : v.replaceAll(',', '')))
    .test('must be a number', 'Must be a valid number', (v) => !Number.isNaN(parseFloat(v)))
    .test(TestForFractionDigit(maxFractionDigits));

// TODO: #923 We cannot use .toFixed here because the `value` in test gets Fixed.
//   Therefore, we can have a number like 100.09, but it will be transormed into 1.000
//   and will not display a validation error as we expect.
// .transform((v) => (parseFloat(v) / 100));

/**
 * Percent represented as a `string | null` in a Yup shape.
 */
export const YupNullablePercentage = (
  label: string,
  maxFractionDigits?: MaxFractionDigits,
): YupNullableStringType =>
  Yup.string()
    .label(label)
    .default(null)
    .nullable()
    .transform((p) => ((p ?? '') === '' ? null : p.replaceAll(',', '')))
    .test(
      'must be a number',
      'Must be a valid number',
      (v) => v === null || !Number.isNaN(parseFloat(v)),
    )
    .test(TestForFractionDigit(maxFractionDigits));
// TODO: #923 We cannot use .toFixed here because the `value` in test gets Fixed.
//   Therefore, we can have a number like 100.09, but it will be transormed into 1.000
//   and will not display a validation error as we expect.
// .transform((v) => (parseFloat(v) / 100));

/** Margins cannot exceed 100, so this check verifies that. null values are considered valid. */
export const marginPercentage = {
  name: 'percentage less than 100',
  message: 'Must be less than 100',
  test: (value: string | null): boolean => {
    const cleanedV = (value ?? '0').replaceAll(',', '');
    return parseFloat(cleanedV) < 100;
  },
};

/**
 * Dates represented as `string | null` in a Yup shape. Empty strings become null.
 * This will not transform the input date into ISO - this will not change the input datetime
 * due to influenced by the user's browser timezone.
 */
export const YupNullableLocalDate = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .nullable()
    .default(null)
    .transform((v) => (v === '' ? null : DateTime.fromISO(v).toISO()));

/**
 * Dates represented as `string` in a Yup shape. Empty strings are considered invalid.
 * This will not transform the input date into ISO - this will not change the input datetime
 * due to influenced by the user's browser timezone.
 */
export const YupLocalDate = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string()
    .label(label)
    .default('')
    .typeError('Required')
    .required()
    // This can return null if it is not properly processed.
    .transform((v): string => DateTime.fromISO(v).toISO() ?? '')
    .test('date-is-valid', 'Must be a valid date', (d: string) => d !== '');

/**
 * Dates represented as `string | null` in a Yup shape. Empty strings become null.
 * This will transform the input date into ISO - this may change the displayed date
 * depending on the user's browser timezone.
 */
export const YupNullableUTCDate = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .nullable()
    .default(null)
    .transform((v) => (v === '' ? null : DateTime.fromISO(v).toUTC().toISO()));

/**
 * Dates represented as `string` in a Yup shape. Empty strings are considered invalid.
 * This will transform the input date into ISO - this may change the displayed date
 * depending on the user's browser timezone.
 */
export const YupUTCDate = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string()
    .label(label)
    .default('')
    .typeError('Required')
    .required()
    .transform((v) => DateTime.fromISO(v).toUTC().toISO())
    .test('date-is-valid', 'Must be a valid date', (d: string) => d !== '');

/**
 * Durations represented as `string` in a Yup shape. Empty strings are considered invalid.
 */
export const YupDuration = (label: string): RequiredStringSchema<string, AnyObject> =>
  Yup.string()
    .label(label)
    .default('')
    .typeError('Required')
    .required()
    .test('duration-is-valid', 'Must be a valid duration', (d: string) => {
      const durFromString = Duration.fromISO(d);
      return durFromString.isValid;
    });

/**
 * Durations represented as `string | null` in a Yup shape. Empty strings become null.
 */
export const YupNullableDuration = (label: string): YupNullableStringType =>
  Yup.string()
    .label(label)
    .nullable()
    .default(null)
    .transform((v) => (v === '' ? null : v))
    .test('duration-is-valid', 'Must be a valid duration', (d: string | null) => {
      if (d === null) {
        return true;
      }
      const durFromString = Duration.fromISO(d);
      return durFromString.isValid;
    });

type YupLookupOptionType = OptionalObjectSchema<
  {
    value: RequiredStringSchema<string, AnyObject>;
    label: RequiredStringSchema<string, AnyObject>;
    sublabels: Yup.ArraySchema<Yup.StringSchema<string, AnyObject, string>>;
    group: Yup.StringSchema<string, AnyObject, string>;
  },
  AnyObject,
  TypeOfShape<{
    value: RequiredStringSchema<string, AnyObject>;
    label: RequiredStringSchema<string, AnyObject>;
    sublabels: Yup.ArraySchema<Yup.StringSchema<string, AnyObject, string>>;
    group: Yup.StringSchema<string, AnyObject, string>;
  }> | null
>;

/** Used in Yup.SchemaOf<> type declarations for ease of reading. */
export type YupSchemaReferenceType = {
  id: string | null;
  option: LookupInputOption | null;
};

type YupReferenceType<PROPS extends Record<string, Yup.AnySchema> = {}> = OptionalObjectSchema<
  PROPS & {
    id: YupNullableStringType;
    option: YupLookupOptionType;
  },
  AnyObject,
  TypeOfShape<
    PROPS & {
      id: YupNullableStringType;
      option: YupLookupOptionType;
    }
  >
>;

/**
 * A Yup shape for references to other objects. Objects may have many fields,
 * but at least have a UUID ID field (so long as it is not NIL_UUID).
 */
export const YupReference = <PROPS extends Record<string, Yup.AnySchema> = {}>(
  label: string,
  extraProps: PROPS = {} as PROPS,
): YupReferenceType<PROPS> =>
  Yup.object().shape({
    ...extraProps,
    id: Yup.string()
      /**
       * Allow YupReference id to be nullable - that is because that is the proper input-level value management.
       * If we set it to undefined, it omits the id key-value pair.
       * Then a very user-unfriendly message displays in the error box.
       * This `.nullable()` prevents that by allowing it to be set to `null`.
       */
      .nullable()
      .default('')
      .label(label)
      .test(
        'id-is-valid',
        'Required',
        (id: string | null) => id !== null && id !== '' && id !== NIL_UUID,
      )
      .required(),
    option: Yup.object()
      .shape({
        value: Yup.mixed(),
        label: Yup.string(),
        sublabels: Yup.array().of(Yup.string()),
        group: Yup.string(),
      })
      .nullable()
      .default(null)
      .test(
        'option-is-valid-if-exists',
        'Provided option is not valid',
        (option: LookupInputOption | null) => {
          // Option does not exist
          if (option === null) {
            return true;
          }

          // Option has at least value && label set
          if (
            ![undefined, null, '', NIL_UUID].includes(option.value) &&
            ![undefined, null, ''].includes(option.label)
          ) {
            return true;
          }

          // It has gotten into some untrue state, but do not cause an error.
          // Instead, fire to LogRocket to alert developers, otherwise users will be blocked
          // from saving without a clear error message to refer to.
          LogRocket.warn(
            'An invalid option was set in a formik state. The user should be unaffected.',
            label,
            option,
          );
          return true;
        },
      ),
  }) as YupReferenceType<PROPS>;

/** Used in Yup.SchemaOf<> type declarations for ease of reading. */
export type YupSchemaNullableReferenceType = {
  id: string | null;
  option: LookupInputOption | null;
} | null;

type YupNullableReferenceType = OptionalObjectSchema<
  {
    id: YupNullableStringType;
    option: YupLookupOptionType;
  },
  AnyObject,
  TypeOfShape<{
    id: YupNullableStringType;
    option: YupLookupOptionType;
  }> | null
>;

/**
 * A Yup shape for nullable references to other objects.
 *
 * If the ID is null or '', or the entire object is null, transform returns null.
 */
export const YupNullableReference = (label: string): YupNullableReferenceType =>
  Yup.object({
    id: Yup.string().label(label).default(null).nullable(),
    option: Yup.object()
      .shape({
        value: Yup.mixed(),
        label: Yup.string(),
        sublabels: Yup.array().of(Yup.string()),
        group: Yup.string(),
      })
      .nullable()
      .default(null)
      .test(
        'option-is-valid-if-exists',
        'Provided option is not valid',
        (option: LookupInputOption | null) => {
          // Option does not exist
          if (option === null) {
            return true;
          }

          // Option has at least value && label set
          if (
            ![undefined, null, '', NIL_UUID].includes(option.value) &&
            ![undefined, null, ''].includes(option.label)
          ) {
            return true;
          }

          // It has gotten into some untrue state, but do not cause an error.
          // Instead, fire to LogRocket to alert developers, otherwise users will be blocked
          // from saving without a clear error message to refer to.
          LogRocket.warn(
            'An invalid option was set in a formik state. The user should be unaffected.',
            label,
            option,
          );
          return true;
        },
      ),
  })
    .label(label)
    .default(null)
    .nullable()
    .transform((v: YupSchemaNullableReferenceType) =>
      v?.id === null || v?.id === '' || v?.id === NIL_UUID ? null : v,
    );

type YupAddressType = RequiredObjectSchema<
  {
    line1: YupNullableStringType;
    line2: YupNullableStringType;
    city: YupNullableStringType;
    country: YupNullableStringType;
    latitude: YupNullableStringType;
    longitude: YupNullableStringType;
    postalCode: YupNullableStringType;
    state: RequiredStringSchema<string, AnyObject>;
  },
  AnyObject,
  TypeOfShape<{
    line1: YupNullableStringType;
    line2: YupNullableStringType;
    city: YupNullableStringType;
    country: YupNullableStringType;
    latitude: YupNullableStringType;
    longitude: YupNullableStringType;
    postalCode: YupNullableStringType;
    state: RequiredStringSchema<string, AnyObject>;
  }>
>;

/**
 * Basic Yup Address validation matching Address.
 */
export const YupAddress = (label: string): YupAddressType =>
  Yup.object({
    line1: YupNullableString('Line 1'),
    line2: YupNullableString('Line 2'),
    city: YupNullableString('City'),
    country: YupNullableString('Country'),
    latitude: YupNullableString('Latitude'),
    longitude: YupNullableString('Longitude'),
    postalCode: YupNullableString('Postal code'),
    state: YupString('State'),
  })
    .label(label)
    .required();

export type YupSchemaDeliveryCostsType = {
  deliveryCostOverride: YupSchemaNullableCostType;
  perMinCost: YupSchemaNullableCurrencyType;
  loadTime: string | null;
  loadedInYardTime: string | null;
  driveToJobTime: string | null;
  waitTime: string | null;
  pourTime: string | null;
  unloadedAtJobTime: string | null;
  driveToPlantTime: string | null;
  drivingTimeAdjustmentPercentage: string | null;
  unengagedTimeAdjustmentPercentage: string | null;
  fuelVolumeUnit: Enums.Unit | null;
  fuelCostPerUnit: YupSchemaNullableCurrencyType;
  driveDistanceUnit: Enums.Unit | null;
  driveDistancePerFuelVolume: string | null;
  driveDistanceToJob: string | null;
  driveDistanceToPlant: string | null;
  loadSizeUnit: Enums.Unit | null;
  loadSize: string | null;
  displayOnly: {
    totalDeliveryCost: string | null;
  } | null;
};

type YupDeliveryCostsType = RequiredObjectSchema<
  {
    deliveryCostOverride: YupNullableCostType;
    perMinCost: YupNullableCurrencyType;
    loadTime: YupNullableStringType;
    loadedInYardTime: YupNullableStringType;
    driveToJobTime: YupNullableStringType;
    waitTime: YupNullableStringType;
    pourTime: YupNullableStringType;
    unloadedAtJobTime: YupNullableStringType;
    driveToPlantTime: YupNullableStringType;
    drivingTimeAdjustmentPercentage: YupNullableStringType;
    unengagedTimeAdjustmentPercentage: YupNullableStringType;
    fuelVolumeUnit: YupNullableEnumType<Enums.Unit>;
    fuelCostPerUnit: YupNullableCurrencyType;
    driveDistanceUnit: YupNullableEnumType<Enums.Unit>;
    driveDistancePerFuelVolume: YupNullableStringType;
    driveDistanceToJob: YupNullableStringType;
    driveDistanceToPlant: YupNullableStringType;
    loadSizeUnit: YupNullableEnumType<Enums.Unit>;
    loadSize: YupNullableStringType;
    displayOnly: OptionalObjectSchema<
      {
        totalDeliveryCost: YupNullableStringType;
      },
      AnyObject,
      TypeOfShape<{
        totalDeliveryCost: YupNullableStringType;
      }> | null
    >;
  },
  AnyObject,
  TypeOfShape<{
    deliveryCostOverride: YupNullableCostType;
    perMinCost: YupNullableCurrencyType;
    loadTime: YupNullableStringType;
    loadedInYardTime: YupNullableStringType;
    driveToJobTime: YupNullableStringType;
    waitTime: YupNullableStringType;
    pourTime: YupNullableStringType;
    unloadedAtJobTime: YupNullableStringType;
    driveToPlantTime: YupNullableStringType;
    drivingTimeAdjustmentPercentage: YupNullableStringType;
    unengagedTimeAdjustmentPercentage: YupNullableStringType;
    fuelVolumeUnit: YupNullableEnumType<Enums.Unit>;
    fuelCostPerUnit: YupNullableCurrencyType;
    driveDistanceUnit: YupNullableEnumType<Enums.Unit>;
    driveDistancePerFuelVolume: YupNullableStringType;
    driveDistanceToJob: YupNullableStringType;
    driveDistanceToPlant: YupNullableStringType;
    loadSizeUnit: YupNullableEnumType<Enums.Unit>;
    loadSize: YupNullableStringType;
    displayOnly: OptionalObjectSchema<
      {
        totalDeliveryCost: YupNullableStringType;
      },
      AnyObject,
      TypeOfShape<{
        totalDeliveryCost: YupNullableStringType;
      }> | null
    >;
  }>
>;

export const YupDeliveryCosts = (label: string): YupDeliveryCostsType => {
  const trueSchema = Yup.object({
    deliveryCostOverride: YupNullableCost('Delivery cost override'),
    perMinCost: YupNullableCurrency('Delivery cost per minute').transform(
      (value: DomainObject<Currency> | null) => {
        if (value === null) return Currency.zero();
        return value;
      },
    ),
    loadTime: YupNullableDuration('Load time'),
    loadedInYardTime: YupNullableDuration('Wash loaded in yard'),
    driveToJobTime: YupNullableDuration('Driving to the job'),
    waitTime: YupNullableDuration('Wait time'),
    pourTime: YupNullableDuration('Pour time'),
    unloadedAtJobTime: YupNullableDuration('Wash/complete unloaded'),
    driveToPlantTime: YupNullableDuration('Driving time to the location'),
    drivingTimeAdjustmentPercentage: YupNullableDecimal('Google Maps adjustment %').test(
      marginPercentage,
    ),
    unengagedTimeAdjustmentPercentage:
      YupNullableDecimal('Unengaged time %').test(marginPercentage),
    fuelVolumeUnit: YupNullableEnum(Enums.Unit, 'Fuel unit'),
    fuelCostPerUnit: YupNullableCurrency('Fuel cost per gallon'),
    driveDistanceUnit: YupNullableEnum(Enums.Unit, 'Distance unit'),
    driveDistancePerFuelVolume: YupNullableDecimal('Miles per gallon'),
    driveDistanceToJob: YupNullableDecimal('Distance to the job'),
    driveDistanceToPlant: YupNullableDecimal('Distance to the location'),
    loadSizeUnit: YupNullableEnum(Enums.Unit, 'Load unit'),
    loadSize: YupNullableDecimal('Load size').test(
      'load-size-positive',
      'load size must be greater than zero',
      (d: string | null) => {
        if (d === null) {
          return true;
        }
        const num = Number(d);
        return Number.isFinite(num) && num > 0;
      },
    ),
    displayOnly: Yup.object({
      totalDeliveryCost: YupNullableString('Total delivery cost'),
    }).nullable(),
  })
    .label(label)
    .required()
    .transform(
      (v): DomainObject<DeliveryCost> => ({
        deliveryCostOverride: v.deliveryCostOverride,
        perMinCost: v.perMinCost,
        loadTime: v.loadTime,
        loadedInYardTime: v.loadedInYardTime,
        driveToJobTime: v.driveToJobTime,
        waitTime: v.waitTime,
        pourTime: v.pourTime,
        unloadedAtJobTime: v.unloadedAtJobTime,
        driveToPlantTime: v.driveToPlantTime,
        drivingTimeAdjustmentPercentage: v.drivingTimeAdjustmentPercentage,
        unengagedTimeAdjustmentPercentage: v.unengagedTimeAdjustmentPercentage,
        fuelVolumeUnit: v.fuelVolumeUnit,
        fuelCostPerUnit: v.fuelCostPerUnit,
        driveDistanceUnit: v.driveDistanceUnit,
        driveDistancePerFuelVolume: v.driveDistancePerFuelVolume,
        driveDistanceToJob: v.driveDistanceToJob,
        driveDistanceToPlant: v.driveDistanceToPlant,
        loadSizeUnit: v.loadSizeUnit,
        loadSize: v.loadSize,
        // The server requires we send `totalDeliveryCost` to be valid, but it is not
        // processed so we send a dummy value.
        totalDeliveryCost: Cost.zero(),
      }),
    );

  // When we use `.omit`, it changes the type of the entire form, not just the returned object.
  // By having a separate defined value, then typing this returned schema as that type, we:
  // 1. Maintain the type expectency
  // 2. Are able to work with `displayOnly` while in the form state
  // 3. Return the exact expected object of `DomainObject<DeliveryCost>`
  const schemaOmittingDisplayOnly = trueSchema.omit(['displayOnly']) as typeof trueSchema;
  return schemaOmittingDisplayOnly;
};

export type YupSchemaNullableDeliveryCostsType = {
  deliveryCostOverride: YupSchemaNullableCostType;
  perMinCost: YupSchemaNullableCurrencyType;
  loadTime: string | null;
  loadedInYardTime: string | null;
  driveToJobTime: string | null;
  waitTime: string | null;
  pourTime: string | null;
  unloadedAtJobTime: string | null;
  driveToPlantTime: string | null;
  drivingTimeAdjustmentPercentage: string | null;
  unengagedTimeAdjustmentPercentage: string | null;
  fuelVolumeUnit: Enums.Unit | null;
  fuelCostPerUnit: YupSchemaNullableCurrencyType;
  driveDistanceUnit: Enums.Unit | null;
  driveDistancePerFuelVolume: string | null;
  driveDistanceToJob: string | null;
  driveDistanceToPlant: string | null;
  loadSizeUnit: Enums.Unit | null;
  loadSize: string | null;
  displayOnly: {
    totalDeliveryCost: string | null;
  } | null;
} | null;

type YupNullableDeliveryCostsType = OptionalObjectSchema<
  {
    deliveryCostOverride: YupNullableCostType;
    perMinCost: YupNullableCurrencyType;
    loadTime: YupNullableStringType;
    loadedInYardTime: YupNullableStringType;
    driveToJobTime: YupNullableStringType;
    waitTime: YupNullableStringType;
    pourTime: YupNullableStringType;
    unloadedAtJobTime: YupNullableStringType;
    driveToPlantTime: YupNullableStringType;
    drivingTimeAdjustmentPercentage: YupNullableStringType;
    unengagedTimeAdjustmentPercentage: YupNullableStringType;
    fuelVolumeUnit: YupNullableEnumType<Enums.Unit>;
    fuelCostPerUnit: YupNullableCurrencyType;
    driveDistanceUnit: YupNullableEnumType<Enums.Unit>;
    driveDistancePerFuelVolume: YupNullableStringType;
    driveDistanceToJob: YupNullableStringType;
    driveDistanceToPlant: YupNullableStringType;
    loadSizeUnit: YupNullableEnumType<Enums.Unit>;
    loadSize: YupNullableStringType;
    displayOnly: OptionalObjectSchema<
      {
        totalDeliveryCost: YupNullableStringType;
      },
      AnyObject,
      TypeOfShape<{
        totalDeliveryCost: YupNullableStringType;
      }> | null
    >;
  },
  AnyObject,
  TypeOfShape<{
    deliveryCostOverride: YupNullableCostType;
    perMinCost: YupNullableCurrencyType;
    loadTime: YupNullableStringType;
    loadedInYardTime: YupNullableStringType;
    driveToJobTime: YupNullableStringType;
    waitTime: YupNullableStringType;
    pourTime: YupNullableStringType;
    unloadedAtJobTime: YupNullableStringType;
    driveToPlantTime: YupNullableStringType;
    drivingTimeAdjustmentPercentage: YupNullableStringType;
    unengagedTimeAdjustmentPercentage: YupNullableStringType;
    fuelVolumeUnit: YupNullableEnumType<Enums.Unit>;
    fuelCostPerUnit: YupNullableCurrencyType;
    driveDistanceUnit: YupNullableEnumType<Enums.Unit>;
    driveDistancePerFuelVolume: YupNullableStringType;
    driveDistanceToJob: YupNullableStringType;
    driveDistanceToPlant: YupNullableStringType;
    loadSizeUnit: YupNullableEnumType<Enums.Unit>;
    loadSize: YupNullableStringType;
    displayOnly: OptionalObjectSchema<
      {
        totalDeliveryCost: YupNullableStringType;
      },
      AnyObject,
      TypeOfShape<{
        totalDeliveryCost: YupNullableStringType;
      }> | null
    >;
  }> | null
>;

export const YupNullableDeliveryCosts = (label: string): YupNullableDeliveryCostsType => {
  const trueSchema = Yup.object({
    deliveryCostOverride: YupNullableCost('Delivery cost override'),
    perMinCost: YupNullableCurrency('Delivery cost per minute').transform(
      (value: DomainObject<Currency> | null) => {
        if (value === null) return Currency.zero();
        return value;
      },
    ),
    loadTime: YupNullableDuration('Load time'),
    loadedInYardTime: YupNullableDuration('Wash loaded in yard'),
    driveToJobTime: YupNullableDuration('Driving to the job'),
    waitTime: YupNullableDuration('Wait time'),
    pourTime: YupNullableDuration('Pour time'),
    unloadedAtJobTime: YupNullableDuration('Wash/complete unloaded'),
    driveToPlantTime: YupNullableDuration('Driving time to the location'),
    drivingTimeAdjustmentPercentage: YupNullableDecimal('Google Maps adjustment %').test(
      marginPercentage,
    ),
    unengagedTimeAdjustmentPercentage:
      YupNullableDecimal('Unengaged time %').test(marginPercentage),
    fuelVolumeUnit: YupNullableEnum(Enums.Unit, 'Fuel unit'),
    fuelCostPerUnit: YupNullableCurrency('Fuel cost per gallon'),
    driveDistanceUnit: YupNullableEnum(Enums.Unit, 'Distance unit'),
    driveDistancePerFuelVolume: YupNullableDecimal('Miles per gallon'),
    driveDistanceToJob: YupNullableDecimal('Distance to the job'),
    driveDistanceToPlant: YupNullableDecimal('Distance to the location'),
    loadSizeUnit: YupNullableEnum(Enums.Unit, 'Load unit'),
    loadSize: YupNullableDecimal('Load size'),
    displayOnly: Yup.object({
      totalDeliveryCost: YupNullableString('Total delivery cost'),
    }).nullable(),
  })
    .label(label)
    .nullable()
    .transform((v): DomainObject<DeliveryCost> | null => {
      if (v === null) {
        return null;
      }
      return {
        deliveryCostOverride: v.deliveryCostOverride,
        perMinCost: v.perMinCost,
        loadTime: v.loadTime,
        loadedInYardTime: v.loadedInYardTime,
        driveToJobTime: v.driveToJobTime,
        waitTime: v.waitTime,
        pourTime: v.pourTime,
        unloadedAtJobTime: v.unloadedAtJobTime,
        driveToPlantTime: v.driveToPlantTime,
        drivingTimeAdjustmentPercentage: v.drivingTimeAdjustmentPercentage,
        unengagedTimeAdjustmentPercentage: v.unengagedTimeAdjustmentPercentage,
        fuelVolumeUnit: v.fuelVolumeUnit,
        fuelCostPerUnit: v.fuelCostPerUnit,
        driveDistanceUnit: v.driveDistanceUnit,
        driveDistancePerFuelVolume: v.driveDistancePerFuelVolume,
        driveDistanceToJob: v.driveDistanceToJob,
        driveDistanceToPlant: v.driveDistanceToPlant,
        loadSizeUnit: v.loadSizeUnit,
        loadSize: v.loadSize,
        // The server requires we send `totalDeliveryCost` to be valid, but it is not
        // processed so we send a dummy value.
        totalDeliveryCost: Cost.zero(),
      };
    });

  // When we use `.omit`, it changes the type of the entire form, not just the returned object.
  // By having a separate defined value, then typing this returned schema as that type, we:
  // 1. Maintain the type expectancy
  // 2. Are able to work with `displayOnly` while in the form state
  // 3. Return the exact expected object of `DomainObject<DeliveryCost>`
  const schemaOmittingDisplayOnly = trueSchema.omit(['displayOnly']) as typeof trueSchema;
  return schemaOmittingDisplayOnly;
};

export type YupSchemaForecastsType = DomainObject<Omit<Forecast, 'projectId' | 'plantId'>>[];

type YupSchemaForecastsTypeOne = YupSchemaForecastsType[number];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const YupForecasts = (label: string) =>
  Yup.array()
    .of(
      Yup.object({
        kind: YupEnum(Enums.ForecastKind, 'Kind'),
        value: YupString(label).transform((v: string) => v.replaceAll(',', '')),
        unit: YupEnum(Enums.Unit, 'Unit'),
        intervalLength: YupDuration('Interval length'),
        intervalStart: YupLocalDate('Interval start date'),
      }),
    )
    // Filter out any empty values & fill name, year, and month
    .transform((v) =>
      v.filter(
        (forecast: YupSchemaForecastsTypeOne) =>
          forecast.value !== undefined && forecast.value !== null && forecast.value !== '',
      ),
    )
    .required();

export type YupFormikForecasts = ReturnType<typeof YupForecasts>['__outputType'];

export type YupSchemaCostRuleType = {
  kind: Enums.CostRuleKind;
  markupRatio: string | null;
  flatChange: YupSchemaNullableCurrencyType;
};

type YupCostRuleType = RequiredObjectSchema<
  {
    kind: MixedSchema<Enums.CostRuleKind, AnyObject, Enums.CostRuleKind>;
    markupRatio: YupNullableStringType;
    flatChange: YupNullableCurrencyType;
  },
  AnyObject,
  TypeOfShape<{
    kind: MixedSchema<Enums.CostRuleKind, AnyObject, Enums.CostRuleKind>;
    markupRatio: YupNullableStringType;
    flatChange: YupNullableCurrencyType;
  }>
>;

/**
 * YupCostRule requires that at least one of the values have a valid value.
 */
export const YupCostRule = (label: string): YupCostRuleType =>
  Yup.object({
    kind: YupEnum(Enums.CostRuleKind, 'Kind'),
    markupRatio: YupNullablePercentage('Percentage change'),
    flatChange: YupNullableCurrency('Flat change'),
  })
    .label(label)
    .required()
    // Conditionally transform a value to be null if it is not the selected 'kind'.
    .transform((cr: YupSchemaCostRuleType): YupSchemaCostRuleType => {
      switch (cr.kind) {
        case Enums.CostRuleKind.Flat:
          return {
            ...cr,
            markupRatio: null,
          };
        case Enums.CostRuleKind.Ratio:
          return {
            ...cr,
            flatChange: null,
          };
        default:
          return cr;
      }
    })
    .test({
      name: 'must-require-one',
      message: 'Required',
      test: (cr) => {
        switch (cr.kind) {
          case Enums.CostRuleKind.Flat:
            return cr.flatChange !== null && cr.flatChange.number !== '';
          case Enums.CostRuleKind.Ratio:
            return cr.markupRatio !== null && cr.markupRatio !== '';
          default:
            return true;
        }
      },
    });

export type YupSchemaNullableCostRuleType = YupSchemaCostRuleType | null;

type YupNullableCostRuleType = OptionalObjectSchema<
  {
    kind: YupEnumType<Enums.CostRuleKind>;
    markupRatio: YupNullableStringType;
    flatChange: YupNullableCurrencyType;
  },
  AnyObject,
  TypeOfShape<{
    kind: YupEnumType<Enums.CostRuleKind>;
    markupRatio: YupNullableStringType;
    flatChange: YupNullableCurrencyType;
  }> | null
>;

/**
 * YupCostRule requires that at least one of the values have a valid value.
 */
export const YupNullableCostRule = (label: string): YupNullableCostRuleType =>
  Yup.object({
    kind: YupEnum(Enums.CostRuleKind, 'Kind'),
    markupRatio: YupNullablePercentage('Percentage change'),
    flatChange: YupNullableCurrency('Flat change'),
  })
    .label(label)
    .nullable()
    // Conditionally transform a value to be null if it is not the selected 'kind'.
    .transform((cr: YupSchemaNullableCostRuleType): YupSchemaNullableCostRuleType => {
      if (cr === null) {
        return null;
      }
      switch (cr.kind) {
        case Enums.CostRuleKind.Flat:
          if (cr.flatChange === null || cr.flatChange.number === '') {
            return null;
          }
          return {
            ...cr,
            markupRatio: null,
          };
        case Enums.CostRuleKind.Ratio:
          if (cr.markupRatio === null || cr.markupRatio === '') {
            return null;
          }
          return {
            ...cr,
            flatChange: null,
          };
        default:
          return cr;
      }
    });

export type YupSchemaQuotePriceEscalationType = {
  escalationDate: string;
  kind: Enums.EscalationKind;
  changeRatio: string | null;
  flatChange: YupSchemaNullableCurrencyType;
};

type YupQuotePriceEsclationType = RequiredObjectSchema<
  {
    escalationDate: RequiredStringSchema<string, AnyObject>;
    kind: YupEnumType<Enums.EscalationKind>;
    changeRatio: Yup.StringSchema<string | null, AnyObject, string | null>;
    flatChange: YupNullableCurrencyType;
  },
  AnyObject,
  TypeOfShape<{
    escalationDate: RequiredStringSchema<string, AnyObject>;
    kind: YupEnumType<Enums.EscalationKind>;
    changeRatio: Yup.StringSchema<string | null, AnyObject, string | null>;
    flatChange: YupNullableCurrencyType;
  }>
>;

/**
 * YupQuotePriceEscalation matches a DomainObject<QuotePriceEscalation> for Yup.
 * If an escalation date is selected & valid, then either the `changeRatio` or `flatChange`
 * will be considered 'required' based on the selected escalation kind.
 */
export const YupQuotePriceEscalation = (label: string): YupQuotePriceEsclationType =>
  Yup.object({
    escalationDate: YupLocalDate('Escalation date'),
    kind: YupEnum(Enums.EscalationKind, 'Kind'),
    changeRatio: YupNullablePercentage('Percentage change').test({
      name: 'changeRatio-required-if-date-exists',
      message: 'Required',
      test: (v, ctx) => {
        if (
          ctx.parent.escalationDate !== null &&
          ctx.parent.escalationDate !== '' &&
          ctx.parent.kind === Enums.EscalationKind.Percentage
        ) {
          return v !== null && v !== '';
        }
        return true;
      },
    }),
    flatChange: YupNullableCurrency('Flat change').test({
      name: 'flatChagne-required-if-date-exists',
      message: 'Required',
      test: (v, ctx) => {
        if (
          ctx.parent.escalationDate !== null &&
          ctx.parent.escalationDate !== '' &&
          ctx.parent.kind === Enums.EscalationKind.Flat
        ) {
          return v !== null && v.number !== '';
        }
        return true;
      },
    }),
  })
    .label(label)
    .required()
    // Conditionally transform a value to be null if it is not the selected 'kind'.
    .transform((cr: YupSchemaQuotePriceEscalationType): YupSchemaQuotePriceEscalationType => {
      switch (cr.kind) {
        case Enums.EscalationKind.Flat:
          return {
            ...cr,
            changeRatio: null,
          };
        case Enums.EscalationKind.Percentage:
          return {
            ...cr,
            flatChange: null,
          };
        default:
          return cr;
      }
    });

type YupQuotePriceEscalationsType = Yup.ArraySchema<YupQuotePriceEsclationType, AnyObject>;

/**
 * YupQuotePriceEscalations matches a DomainObject<QuotePriceEscalation>[] for Yup.
 * If an escalation date is selected & valid, then either the `changeRatio` or `flatChange`
 * will be considered 'required' based on the selected escalation kind. This specific array-based
 * schema also checks for unique escalation dates.
 */
export const YupQuotePriceEscalations = (label: string): YupQuotePriceEscalationsType => {
  const schema = Yup.array()
    .of(YupQuotePriceEscalation(label))
    .test({
      name: 'unique-escalation-dates',
      message: 'Escalation dates must be unique',
      test: (escalations = []) => {
        const escalationDates = new Set();
        escalations.forEach((e) => {
          escalationDates.add(e.escalationDate);
        });
        if (escalationDates.size !== escalations.length) {
          return false;
        }
        return true;
      },
    });
  return schema;
};
/**
 * This test helper verifies that all of the named keys inside of the schema it
 * is tied to, are not undefined in the current schema value. If it detects a field
 * that is undefined, it will report to LogRocket, but allow the user request to go
 * forward as if there was no issue.
 *
 * @example
 * export const CompanySchema = Yup.object().shape({
 *   ...
 * })
 *   .test(TestForUndefined('CompanySchema'));
 */
export const TestForUndefined = <T extends Record<string, unknown>>(
  schemaName: string,
): YupTest<T> => ({
  name: 'verify-no-undefined',
  test: (v: T, ctx: any): boolean => {
    const schemaFields: Record<string, any> = ctx.options?.from[0]?.schema?.fields ?? {};
    const keysWithUndefinedValues = Object.keys(schemaFields).filter((k) => v[k] === undefined);
    if (keysWithUndefinedValues.length > 0) {
      LogRocket.error(
        `The following keys have undefined values in ${schemaName}:`,
        keysWithUndefinedValues.join(', '),
      );
    }
    return true;
  },
});
