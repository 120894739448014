import LogRocket from 'logrocket';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppNavigation } from './components/AppNavigation/AppNavigation';
import { ErrorFullPage } from './components/Error/ErrorFullPage';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { MaintenanceNotice } from './components/MaintenanceNotice/MaintenanceNotice';
import { NewUserInfo } from './generated-types/UserInfo/UserInfo';
import { ServerErrorMessage, useSlabQuery } from './hooks/useSlabQuery';
import { NotFound } from './pages/NotFound/NotFound';
import { TenantList } from './pages/TenantsAdmin/TenantAdminList';
import { TenantAdminPage } from './pages/TenantsAdmin/TenantAdminPage';
import { SlabContext, SlabContextType } from './SlabContext';
import { BoundSlabRoutes } from './SlabRoutes';
import { withSlabErrorBoundary } from './utils/withSlabErrorBoundary';

// Renders a BrowserRouter to allow usage of the <Link> component to navigate between pages
const UnboundSlabRouter = (): JSX.Element | null => {
  const [context, setContext] = useState<SlabContextType>({ userInfo: NewUserInfo({}) });
  const [contextInitialized, setContextInitialized] = useState<boolean>(false);

  // We set `staleTime: Infinity` to avoid re-fetching this data and re-bootstrapping the router and its routes,
  // which was causing problems with closing edit drawers and etc after re-fetching an unchanged copy of this data.
  // We are assuming that the userinfo data won't change during the lifetime of a full-page-view, and/or that if it
  // does change then those changes can wait until the end of the session or will be enforced by the backend well
  // enough to trigger errors where appropriate (even if those errors are not particularly graceful).
  // See https://slabstack.slack.com/archives/C032DAD421L/p1696606903854809 for details.
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    error,
  } = useSlabQuery('GET user info', {}, { staleTime: Infinity });

  useEffect(() => {
    if (userInfo !== undefined && context?.userInfo !== userInfo) {
      setContext({ ...context, userInfo: NewUserInfo({ ...userInfo }) });
      setContextInitialized(true);
    }
  }, [userInfo]);

  // This check for userInfo being loaded & defined is essential, as it is our first
  // line of defense against rendering the the app for our users.
  //
  // All child components of this router will have access to the userInfo.
  if (isLoadingUserInfo || (!contextInitialized && !isErrorUserInfo)) {
    return <LoadingSpinner size={15} unit='rem' sx={{ height: '100vh' }} />;
  }

  if (isErrorUserInfo || userInfo === undefined) {
    LogRocket.error(
      'Error fetching user info while loading SlabRouter',
      ServerErrorMessage(error === null ? undefined : error),
    );
    return <div>An unknown error occurred</div>;
  }

  return (
    <BrowserRouter>
      <SlabContext.Provider value={context}>
        <AppNavigation>
          <MaintenanceNotice />
          <Routes>
            {BoundSlabRoutes(userInfo).map((routeSection) =>
              routeSection.children.map((route) => (
                <Route
                  key={`route-${routeSection.name}-${route.path}`}
                  path={route.path}
                  element={<route.main />}
                />
              )),
            )}

            <Route path='/_slabstack_staff' element={<TenantList />} />
            <Route path='/_slabstack_staff/:tenantID' element={<TenantAdminPage />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </AppNavigation>
      </SlabContext.Provider>
    </BrowserRouter>
  );
};

export const SlabRouter = withSlabErrorBoundary(UnboundSlabRouter, {
  FallbackComponent: ErrorFullPage,
});
