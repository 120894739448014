import { Add, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { TabBar } from '../../components/TabBar/TabBar';
import { useToast } from '../../components/Toast/useToast';
import { NewProjectConfig, ProjectConfig } from '../../generated-types/ProjectConfig/ProjectConfig';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { NullableCurrencyString } from '../../utils/Currency';
import { NIL_UUID } from '../../utils/UUID';
import { CompanyTable } from './components/CompanyTable';
import { ProjectConfigSection } from './components/ProjectConfigDrawerSections';
import { ProjectConfigProductTable } from './components/ProjectConfigProductTable';
import { ProjectConfigDrawer } from './ProjectConfigDrawer';

export const ProjectConfigDetailsPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const [isCloning, setIsCloning] = useState(false);

  const [initialSection, setInitialSection] = useState<ProjectConfigSection | undefined>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { showToast, toast } = useToast('project config');

  const {
    isLoading,
    isError,
    data: projectConfig,
  } = useSlabQuery('GET project config by ID', {
    pathParams: {
      id: params?.id ?? '',
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || projectConfig === undefined) {
    return <div>ERROR</div>;
  }

  const displayProjectConfig = {
    plantName: projectConfig.plant.name,
    estimatedVolumeOverrideString: projectConfig.estimatedVolumeOverride?.toLocaleString(),
    revenueOverrideString: NullableCurrencyString({ cur: projectConfig.revenueOverride }),
  };

  const title = projectConfig.name;

  return (
    <Page title={title}>
      {toast}
      <ProjectConfigDrawer
        projectConfigID={projectConfig.id}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        initialSection={initialSection}
        onSuccess={(pc): void => {
          showToast('success');
          setIsDrawerOpen(false);
          if (isCloning) {
            setIsCloning(false);
            navigate(`/projectconfigs/${pc.id}`);
          }
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
        onClose={(): void => {
          setIsCloning(false);
          setInitialSection(undefined);
        }}
        ensureDefined={(curProjectConfig): ProjectConfig => {
          if (isCloning && curProjectConfig !== undefined) {
            return NewProjectConfig({
              ...curProjectConfig,
              id: NIL_UUID,
              name: `${curProjectConfig.name} - Copy`,
            });
          }
          return curProjectConfig ?? ProjectConfig.zero();
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Typography variant='h1'>{title}</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='clone'
            variant='secondary'
            onClick={(): void => {
              setInitialSection('Details*');
              setIsCloning(true);
              setIsDrawerOpen(true);
            }}
            icon={Add}
          />
          <ButtonPill
            text='edit project config'
            variant='primary'
            onClick={(): void => {
              setInitialSection('Details*');
              setIsDrawerOpen(true);
            }}
            icon={Edit}
          />
        </Box>
      </Box>
      <Box display='flex'>
        <Box
          display='flex'
          flexDirection='column'
          width='fit-content'
          whiteSpace='nowrap'
          gap='1.25rem'
        >
          <DisplayField
            title='Details'
            onEditClick={(): void => {
              setInitialSection('Details*');
              setIsDrawerOpen(true);
            }}
            value={displayProjectConfig}
            displayMatrix={[
              [
                {
                  key: 'plantName',
                  label: 'Location',
                  type: 'uri',
                  uri: `/plants/${projectConfig.plant.id}`,
                },
              ],
              [
                {
                  key: 'estimatedVolumeOverrideString',
                  label: 'Estimated Volume Override',
                  type: 'default',
                },
              ],
              [{ key: 'revenueOverrideString', label: 'Revenue Override', type: 'default' }],
            ]}
          />
        </Box>
        <Box paddingLeft='2.5rem' flexGrow={1} sx={{ overflowX: 'hidden', overflowY: 'visible' }}>
          <TabBar
            tabs={[
              {
                label: 'Products',
                element: (
                  <ProjectConfigProductTable
                    projectConfigID={projectConfig.id}
                    onEditProducts={(): void => {
                      setInitialSection('Products');
                      setIsDrawerOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Contractors',
                element: (
                  <CompanyTable
                    tableName='project-configs-contractors'
                    projectConfigID={projectConfig.id}
                    isContractorsOnly
                    onEditCompanies={(): void => {
                      setInitialSection('Contractors');
                      setIsDrawerOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Other companies',
                element: (
                  <CompanyTable
                    tableName='project-configs-other-companies'
                    projectConfigID={projectConfig.id}
                    isContractorsOnly={false}
                    onEditCompanies={(): void => {
                      setInitialSection('Other companies');
                      setIsDrawerOpen(true);
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Page>
  );
};
