import * as Yup from 'yup';

import Enums from '../../generated-types/Enums';
import { Mix } from '../../generated-types/Mix/Mix';
import { DomainObject } from '../../utils/ApiClient';
import { NullableCostString } from '../../utils/Currency';
import { FormikNullableCurrency } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  marginPercentage,
  nonNegativeStringNumber,
  TestForUndefined,
  YupDecimal,
  YupEnum,
  YupNullableCurrency,
  YupNullableDecimal,
  YupNullablePercentage,
  YupNullableString,
  YupNumber,
  YupReference,
  YupSchemaNumberType,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type MixDesignFormikType = DomainObject<
  Omit<Mix, 'psi' | 'mixMaterials' | 'plant' | 'materialCost' | 'incompatibleBatchUnits'>
> & {
  psi: YupSchemaNumberType;
  mixMaterials: {
    material: YupSchemaReferenceType;
    quantity: string;
    // Additional materialType stored in formik so we can have formik <> FieldArray awareness
    // TODO: #971 this should be Enums.MaterialType rather than string
    materialType: string;
    batchUnit: Enums.Unit | null;
    displayOnly: {
      inProductCost: string | null;
      // Needed in formik state to persist materials that match names when switching plants.
      materialName: string | null;
    };
  }[];
  plant: YupSchemaReferenceType;
};

export const MixDesignSchema: Yup.SchemaOf<Omit<MixDesignFormikType, 'id' | 'externalID'>> =
  Yup.object()
    .shape({
      alternateID: YupString('Alternate ID'),
      name: YupString('Name'),
      psi: YupNumber({
        label: 'PSI',
        positive: true,
      }),
      mixMaterials: Yup.array().of(
        Yup.object().shape({
          material: YupReference('Material'),
          quantity: YupDecimal('Quantity').test(nonNegativeStringNumber),
          materialType: YupEnum(Enums.MaterialType, 'Material type'),
          batchUnit: YupEnum(Enums.Unit, 'batch unit'),
          displayOnly: Yup.object().shape({
            inProductCost: YupNullableString('material cost'),
            materialName: YupNullableString('MaterialName'),
          }),
        }),
      ),
      materialCostOverride: YupNullableCurrency('Material cost'),
      plant: YupReference('Location'),
      slump: YupNullableDecimal('Slump'),
      aggregateSize: YupNullableString('Aggregate size'),
      waterCementRatio: YupNullableDecimal('Water cement ratio').test(marginPercentage),
      flyAshPercentage: YupNullablePercentage('Flyash percentage', 2).test(marginPercentage),
    })
    .test(TestForUndefined('MixDesignSchema'));

export const FormikMixDesign = (mixData: Partial<Mix> | undefined): MixDesignFormikType => ({
  id: mixData?.id ?? NIL_UUID,
  alternateID: mixData?.alternateID ?? '',
  externalID: mixData?.externalID ?? null,
  name: mixData?.name ?? '',
  psi: mixData?.psi ?? '',
  mixMaterials: (mixData?.mixMaterials ?? []).map((mm) => ({
    material: {
      id: mm.material.id,
      option: {
        value: mm.material.id,
        label: mm.material.name,
      },
    },
    quantity: mm.quantity,
    materialType: mm.material.materialType,
    batchUnit: mm.batchUnit,
    displayOnly: {
      inProductCost: NullableCostString({ cost: mm.material.inProductCost }),
      materialName: mm.material.name,
    },
  })),
  materialCostOverride: FormikNullableCurrency(mixData?.materialCostOverride),
  plant: {
    id: mixData?.plant?.id ?? null,
    option:
      mixData?.plant === undefined
        ? null
        : {
            value: mixData.plant.id,
            label: mixData.plant.name,
          },
  },
  slump: mixData?.slump ?? null,
  aggregateSize: mixData?.aggregateSize ?? null,
  waterCementRatio:
    mixData !== undefined &&
    mixData.waterCementRatio !== undefined &&
    mixData.waterCementRatio !== null &&
    mixData.waterCementRatio !== ''
      ? String(parseFloat((parseFloat(mixData.waterCementRatio) * 100).toFixed(3)))
      : '',
  flyAshPercentage: mixData?.flyAshPercentage ?? null,
});

export const EmptyFormikMixDesign = FormikMixDesign(undefined);
