import MuiMenu from '@mui/icons-material/Menu';
import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import MuiToolbar from '@mui/material/Toolbar';

import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from './constants';
import {
  AppContentProps,
  DrawerProps,
  ListItemButtonProps,
  ListItemTextProps,
  ToolbarImpersonatingProps,
  ToolbarProps,
} from './models';

export const ListItem = styled(MuiListItem)({
  position: 'relative',
});

export const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<ListItemButtonProps>(({ isOpen }) => ({
  padding: '8px 20px',
  minHeight: 48,
  justifyContent: isOpen ? 'initial' : 'center',
}));

export const ListItemIcon = styled(MuiListItemIcon)(() => ({
  minWidth: 0,
  justifyContent: 'center',
}));

export const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isActive',
})<ListItemTextProps>(({ isOpen, isActive = false, theme }) => ({
  opacity: isOpen ? 1 : 0,
  textWrap: 'nowrap',
  span: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 12,
    fontWeight: isActive ? 'bold' : 'normal',
    color: isActive ? theme.palette.SlabYellow[500] : 'inherit',
  },
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<DrawerProps>(({ theme, isOpen = false }) => ({
  width: isOpen ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: isOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  '& .MuiDrawer-paper': {
    width: isOpen ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED,
    overflowX: 'hidden',
    backgroundColor: theme.palette.SlabGray['950'],
    color: theme.palette.SlabGray['500'],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: isOpen
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('lg')]: {
      width: isOpen ? DRAWER_WIDTH : 0,
    },
  },
}));

export const AppContent = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<AppContentProps>(({ isOpen, theme }) => ({
  flexGrow: 1,
  paddingTop: '86px',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: isOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('lg')]: {
    marginLeft: isOpen ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED,
  },
}));

export const Toolbar = styled(MuiToolbar, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<ToolbarProps>(({ isOpen, theme }) => ({
  height: '74px',
  justifyContent: 'space-between',
  marginLeft: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: isOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('lg')]: {
    marginLeft: isOpen ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED,
  },
}));

export const ToolbarImpersonating = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'isImpersonating',
})<ToolbarImpersonatingProps>(({ theme, isImpersonating }) => ({
  flex: 1,
  height: '100%',
  backgroundColor: isImpersonating ? theme.palette.SlabBrown['600'] : 'transparent',
}));

export const Menu = styled(MuiMenu)(({ theme }) => ({
  marginRight: 24,
  [theme.breakpoints.up('lg')]: { display: 'none' },
}));

export const SectionWrapper = styled(MuiBox)(({ theme }) => ({
  borderTopColor: theme.palette.SlabGray['600'],
  borderTopStyle: 'solid',
  borderTopWidth: 1,
  padding: '16px 0',
}));

export const AppNavbarLogoWrapper = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: { display: 'none' },
}));
