import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import AppDrawer from './AppDrawer';
import AppNavbar from './AppNavbar';
import { AppContent } from './components/styles';

interface AppNavigationProps {
  children: React.ReactNode;
}

export const AppNavigation: FC<AppNavigationProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('lg'));

  const [isOpenDrawer, setOpenDrawer] = useState<boolean>(!isMobile);

  const closeDrawer = (): void => setOpenDrawer(false);
  const openDrawer = (): void => setOpenDrawer(true);
  const toggleDrawer = (): void => setOpenDrawer((p) => !p);

  useEffect(() => {
    if (isMobile) closeDrawer();
  }, [isMobile]);

  return (
    <Box>
      <AppNavbar isOpen={isOpenDrawer} toggleDrawer={toggleDrawer} />
      <AppDrawer
        isMobile={isMobile}
        openDrawer={openDrawer}
        isOpen={isOpenDrawer}
        closeDrawer={closeDrawer}
      />

      <AppContent isOpen={isOpenDrawer}>{children}</AppContent>
    </Box>
  );
};
