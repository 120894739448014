import { cloneElement, Fragment, PropsWithChildren, useMemo } from 'react';

interface Section<T> {
  name: string;
  children: T[];
}

interface SectionListProps<T> {
  sectionWrapper?: JSX.Element;
  sections: Section<T>[];
  keyExtractor(props: T, idx: number): string;
  sectionKeyExtractor(props: Section<T>, idx: number): string;
  renderSectionHeader?(props: { section: Section<T>; index: number }): JSX.Element;
  renderItem(props: { item: T; index: number }): JSX.Element;
}

export interface ElementWithKey extends PropsWithChildren {
  key: React.Key;
}

export const SectionList = <T,>({
  sections,
  renderItem,
  keyExtractor,
  sectionKeyExtractor,
  renderSectionHeader,
  sectionWrapper,
}: SectionListProps<T>): JSX.Element => {
  const SectionWrapper = useMemo(() => {
    if (sectionWrapper != null)
      return (props: ElementWithKey): JSX.Element => cloneElement(sectionWrapper, props);

    return Fragment;
  }, [sectionWrapper]);

  return (
    <>
      {sections.map((section, sectionIdx) => (
        <SectionWrapper key={sectionKeyExtractor(section, sectionIdx)}>
          {renderSectionHeader?.({ section, index: sectionIdx })}

          {section.children.map((item, index) => (
            <Fragment key={keyExtractor(item, index)}>{renderItem({ item, index })}</Fragment>
          ))}
        </SectionWrapper>
      ))}
    </>
  );
};
