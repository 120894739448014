import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import * as Yup from 'yup';

import {
  MutationRouteBarrelTypes,
  MutationRouteBarrelVals,
  QueryRouteBarrelKeys,
} from '../utils/ApiClient';
import { QueryError } from '../utils/Query';
import { SlabConfig } from '../utils/SlabConfig';
import { useSlabAuth } from './useSlabAuth';
import { UrlFromParams } from './useSlabQuery';

type Params = Record<string, string | number | boolean>;

export type UpdateType<T extends { body: any }> = {
  args: T;
  schema?: Yup.BaseSchema<any> | { cast: (data: T['body']) => any };
};

export const useSlabMutation = <
  TKey extends keyof MutationRouteBarrelTypes,
  TArgs extends MutationRouteBarrelTypes[TKey]['args'],
  TReturn extends MutationRouteBarrelTypes[TKey]['returns'],
  TError extends QueryError,
>(
  key: TKey,
  options?: Omit<UseMutationOptions<TReturn, TError, UpdateType<TArgs>, unknown>, 'mutationFn'>,
): UseMutationResult<TReturn, TError, UpdateType<TArgs>, unknown> => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useSlabAuth();

  const barrel = MutationRouteBarrelVals[key];
  const updateObject = async ({
    args,
    schema = { cast: (data: TArgs['body']) => data },
  }: UpdateType<TArgs>): Promise<TReturn> => {
    // Check the user has a valid access token
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: SlabConfig.auth0.audience,
      },
    });

    // Extract the raw ID_Token into the authorization bearer header
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const fullUrl = UrlFromParams({
      queryParams: ((args as any)?.queryParams as Params) ?? {},
      pathParams: ((args as any)?.pathParams as Params) ?? {},
      urlSplits: barrel.urlSplits,
    });

    // Run any schema transforms on data before submission
    const castBody = schema.cast((args as any)?.body) as unknown as TArgs['body'];
    switch (barrel.method) {
      case 'POST':
        return axios.post<TReturn>(fullUrl, castBody, headers).then((d) => barrel.new(d.data));
      case 'PUT':
        return axios.put<TReturn>(fullUrl, castBody, headers).then((d) => barrel.new(d.data));
      // default to 'POST' since this will give a compile error if method is not implemented
      default:
        return axios.post<TReturn>(fullUrl, castBody, headers).then((d) => barrel.new(d.data));
    }
    // TODO: I think there should be a .catch on these here that custom wraps the error and returns
    //   it to the page that called this mutation...
  };

  return useMutation<TReturn, TError, UpdateType<TArgs>, unknown>({
    ...options,
    mutationFn: updateObject,
    onSuccess: (d, v, c) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          barrel.invalidations.includes(query.queryKey[1] as QueryRouteBarrelKeys),
      });
      if (options?.onSuccess !== undefined) {
        options.onSuccess(d, v, c);
      }
    },
  });
};
