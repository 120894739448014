import { Add, Edit } from '@mui/icons-material';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ProjectMap } from '../../components/Map/ProjectMap';
import { MarkAsSentAndShowToast } from '../../components/Nylas/NylasFlyout';
import { Page } from '../../components/Page/Page';
import { TabBar } from '../../components/TabBar/TabBar';
import { Company } from '../../generated-types/Company/Company';
import { Contact } from '../../generated-types/Contact/Contact';
import Enums from '../../generated-types/Enums';
import { NewProject, Project } from '../../generated-types/Project/Project';
import { ProjectProduct } from '../../generated-types/ProjectProduct/ProjectProduct';
import { Quote } from '../../generated-types/Quote/Quote';
import { NewQuoteProduct } from '../../generated-types/QuoteProduct/QuoteProduct';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { getTenantRequiresApprovals } from '../../utils/TenantConfig';
import { NIL_UUID } from '../../utils/UUID';
import { QuoteDrawer } from '../Quotes/QuoteDrawer';
import { FormikQuote, QuoteFormikType } from '../Quotes/QuoteFormik';
import { QuoteStatusDrawer } from '../Quotes/QuoteStatusDrawer';
import { CompanyTable } from './components/CompanyTable';
import { ForecastTable } from './components/ForecastTable';
import { ProductTable } from './components/ProductTable';
import { ProjectActivities } from './components/ProjectActivities';
import { ProjectDetailsColumn } from './components/ProjectDetailsColumn';
import { ProjectSection } from './components/ProjectDrawerSections';
import { ProjectQuoteTable } from './components/ProjectQuoteTable';
import { ProjectDrawer } from './ProjectDrawer';

/**
 * Create a quote context from a project and its products.
 */
export const QuoteContextFromProject = (
  project: Project,
  projectProducts: ProjectProduct[],
): Partial<Quote> => {
  const projectQuoteProducts = projectProducts.map((p) =>
    NewQuoteProduct({
      product: p.product,
      kind: Enums.QuoteProductKind.Primary,
      externalName:
        p.externalName !== null && p.externalName !== ''
          ? p.externalName
          : p.product.externalName(),
      quantity: p.quantity,
      price: p.price,
      deliveryCosts: p.deliveryCosts,
      usage: p.usage,
      deliveryType: p.deliveryType,
      haulRate: p.haulRate,
      minimumHaulCharge: p.minimumHaulCharge,
      taxRate: p.taxRate,
      truckingType: p.truckingType,
    }),
  );

  const { quoteCompany, quoteContact } = ((): {
    quoteCompany: Company | null;
    quoteContact: Contact | null;
  } => {
    if (project.winningCompany !== null) {
      return {
        quoteCompany: project.winningCompany.company,
        quoteContact: project.winningCompany.contact,
      };
    }
    const contractorProjectCompanies = project.companies.filter(
      (pc) => pc.company.category === Enums.CompanyCategory.Contractor,
    );
    if (contractorProjectCompanies.length === 1) {
      return {
        quoteCompany: contractorProjectCompanies[0].company,
        quoteContact: contractorProjectCompanies[0].contact,
      };
    }
    return {
      quoteCompany: null,
      quoteContact: null,
    };
  })();

  return {
    project,
    name: project.name,
    // Default to 30 days from now.
    expirationDate: DateTime.now().plus({ days: 30 }),
    user: project.user,
    company: quoteCompany,
    contact: quoteContact,
    products: projectQuoteProducts,
  };
};

export const ProjectDetailsPage = (): JSX.Element => {
  const ctx = useContext(SlabContext);
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const projectID = params?.id ?? NIL_UUID;

  const [drawerQuoteID, setDrawerQuoteID] = useState<string | null>(null);
  const [initialSection, setInitialSection] = useState<ProjectSection | undefined>();
  const [selectedYear, setSelectedYear] = useState(DateTime.now().year);
  const [isCloning, setIsCloning] = useState(false);

  const { toastHook: projectToastHook, ...projectDrawerProps } = useDrawerManager({
    baseUrl: '/projects',
    resourceTypeName: 'project',
    drawerProps: {
      resourceID: projectID,
    },
  });

  const { toastHook: quoteToastHook, ...quoteDrawerProps } = useDrawerManager({
    baseUrl: '/quotes',
    resourceTypeName: 'quote',
    drawerProps: {
      resourceID: drawerQuoteID,
    },
  });
  const { toastHook: quoteStatusToastHook, ...quoteStatusDrawerProps } = useDrawerManager({
    baseUrl: '/quotes', // unused
    resourceTypeName: 'quote status',
    drawerProps: {
      resourceID: drawerQuoteID,
    },
  });

  const {
    isLoading: isLoadingQuotePolicies,
    isError: isErrorQuotePolicies,
    data: quotePolicies,
  } = useSlabQuery('GET quote policies', {});

  const {
    isLoading: isLoadingProject,
    isError: isErrorProject,
    data: project,
  } = useSlabQuery('GET project by ID', {
    pathParams: {
      id: projectID,
    },
  });

  const {
    isLoading: isLoadingProjectProducts,
    isError: isErrorProjectProducts,
    data: projectProducts,
  } = useSlabQuery('GET project products by project ID', {
    pathParams: {
      id: projectID,
    },
  });

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET project statuses', {});

  const markAsSent = useSlabMutation('POST mark quote as sent');

  const isLoading =
    isLoadingProject || isLoadingProjectProducts || isLoadingStatuses || isLoadingQuotePolicies;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError =
    isErrorProject || isErrorProjectProducts || isErrorStatuses || isErrorQuotePolicies;

  const isDataUndefined =
    project === undefined ||
    projectProducts === undefined ||
    statusList === undefined ||
    quotePolicies === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const title =
    project.projectNumber !== null ? `${project.name} - ${project.projectNumber}` : project.name;

  const projectLocation =
    project.address.latitude !== null && project.address.longitude !== null
      ? {
          lat: parseFloat(project.address.latitude),
          lng: parseFloat(project.address.longitude),
        }
      : undefined;
  const plantLocation =
    project.plant.address.latitude !== null && project.plant.address.longitude !== null
      ? {
          lat: parseFloat(project.plant.address.latitude),
          lng: parseFloat(project.plant.address.longitude),
        }
      : undefined;

  const hasUOMIssue = projectProducts.some((pp) => pp.product.incompatibleMixBatchUnits);
  const quoteDrawerSubmitType = drawerQuoteID === null ? 'saveAsNewQuote' : 'save';
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const lowestPriorityStatus = statusList.items.reduce((prev, curr) =>
    curr.priority < prev.priority ? curr : prev,
  );

  return (
    <Page title={title}>
      {projectToastHook.toast}
      {quoteToastHook.toast}
      {quoteStatusToastHook.toast}
      <ProjectDrawer
        {...projectDrawerProps}
        projectID={projectDrawerProps.resourceID}
        initialSection={initialSection}
        isCloning={isCloning}
        initialForecastYear={selectedYear}
        onSuccess={(p): void => {
          projectDrawerProps.onSave(p);
          if (isCloning) {
            setIsCloning(false);
            navigate(`/projects/${p.id}`);
          }
        }}
        onClose={(): void => {
          setIsCloning(false);
          setInitialSection(undefined);
        }}
        ensureDefined={(curProject): Project => {
          if (isCloning && curProject !== undefined) {
            return NewProject({
              ...curProject,
              id: NIL_UUID,
              name: `${curProject.name} - Copy`,
              projectStatus: lowestPriorityStatus,
            });
          }
          return curProject ?? Project.zero();
        }}
      />
      <QuoteDrawer
        {...quoteDrawerProps}
        resourceID={quoteDrawerProps.resourceID}
        onRequestSuccess={() => {
          quoteToastHook.showToast('success');
        }}
        onNylasSuccess={() => {
          quoteToastHook.showToast('success', 'The quote was sent successfully');
        }}
        onRequestError={() => {
          quoteToastHook.showToast('error');
        }}
        onNylasError={() => {
          quoteToastHook.showToast('error', 'Failed to send the quote');
        }}
        onClose={(): void => {
          setDrawerQuoteID(null);
        }}
        makeDefaultFormikQuote={(): QuoteFormikType =>
          FormikQuote(
            QuoteContextFromProject(project, projectProducts),
            usesDispatchCustomer,
            quoteDrawerSubmitType,
          )
        }
        submitType={quoteDrawerSubmitType}
      />
      <QuoteStatusDrawer {...quoteStatusDrawerProps} />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Box display='flex' alignItems='center' gap='1.25rem'>
          <Typography variant='h1'>{title}</Typography>
          {hasUOMIssue && <Chip color='error' label='UOM issue' />}
        </Box>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='clone'
            variant='secondary'
            icon={Add}
            onClick={(): void => {
              setInitialSection('Project Information*');
              setIsCloning(true);
              projectDrawerProps.setIsOpen(true);
            }}
          />
          <ButtonPill
            text='edit project'
            variant='primary'
            onClick={(): void => {
              setInitialSection('Project Information*');
              projectDrawerProps.setIsOpen(true);
            }}
            icon={Edit}
          />
        </Box>
      </Box>
      {hasUOMIssue && (
        <Box paddingBottom='1rem'>
          <Typography color={theme.palette.error.main}>
            The products in this project contain UOM issues
          </Typography>
        </Box>
      )}
      <Box display='flex'>
        <ProjectDetailsColumn
          project={project}
          onProjectDetailsClick={(): void => {
            setInitialSection('Project Information*');
            projectDrawerProps.setIsOpen(true);
          }}
          onAddressInformationClick={(): void => {
            setInitialSection('Job Site Address*');
            projectDrawerProps.setIsOpen(true);
          }}
          onDetailsClick={(): void => {
            setInitialSection('Details');
            projectDrawerProps.setIsOpen(true);
          }}
          onTermsClick={(): void => {
            setInitialSection('Terms');
            projectDrawerProps.setIsOpen(true);
          }}
          onNotesClick={(): void => {
            setInitialSection('Notes');
            projectDrawerProps.setIsOpen(true);
          }}
          onCustomFieldsClick={(): void => {
            setInitialSection('Additional Information');
            projectDrawerProps.setIsOpen(true);
          }}
        />
        <Box paddingLeft='2.5rem' flexGrow={1} sx={{ overflowX: 'hidden', overflowY: 'visible' }}>
          <TabBar
            tabs={[
              {
                label: 'Quotes',
                element: (
                  <ProjectQuoteTable
                    onEditPress={(row): void => {
                      setDrawerQuoteID(row.id);
                      if (
                        !getTenantRequiresApprovals({
                          tenant: ctx.userInfo.tenant,
                          quotePolicies,
                        }) ||
                        row.isDraft ||
                        row.isApprovalRequest
                      ) {
                        quoteDrawerProps.setIsOpen(true);
                      } else {
                        quoteStatusDrawerProps.setIsOpen(true);
                      }
                    }}
                    projectID={projectID}
                    onSendQuote={async (quoteID): Promise<void> => {
                      await MarkAsSentAndShowToast({
                        quoteID: quoteID ?? '',
                        markAsSentMutation: markAsSent,
                        toastHook: quoteToastHook,
                      });
                    }}
                    onSendQuoteError={(err): void => {
                      quoteToastHook.showToast(
                        'error',
                        ServerErrorMessage(err) ?? 'Unable to send quote',
                      );
                    }}
                    onCreatePress={(): void => {
                      setDrawerQuoteID(null);
                      quoteDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Contractors',
                element: (
                  <CompanyTable
                    projectID={project.id}
                    isContractorsOnly
                    tableName='project-contractors'
                    onEditCompanies={(): void => {
                      setInitialSection('Contractors');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Other companies',
                element: (
                  <CompanyTable
                    projectID={project.id}
                    isContractorsOnly={false}
                    tableName='project-other-companies'
                    onEditCompanies={(): void => {
                      setInitialSection('Other companies');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Products',
                element: (
                  <ProductTable
                    projectID={project.id}
                    onEditProducts={(): void => {
                      setInitialSection('Product(s) info');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Forecasts',
                element: (
                  <ForecastTable
                    projectID={project.id}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    onEditForecasts={(): void => {
                      setInitialSection('Forecasts');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Activities',
                element: <ProjectActivities project={project} />,
              },
              {
                label: 'Directions',
                element: (
                  <ProjectMap projectLocation={projectLocation} plantLocation={plantLocation} />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Page>
  );
};
