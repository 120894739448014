import { QuestionMark } from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIconProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';

type VariantOpts = 'primary' | 'secondary';

type SizeOpts = 'normal' | 'small';

type ButtonCssProps = {
  border: string;
  backgroundColor: string;
  color: string;
  disabledColor: string;
  hoverBgColor: string;
};

type SizeCssProps = {
  fontSize: string;
  iconSize: number;
};

type ButtonPillProps = {
  text: string;
  variant: VariantOpts;
  size?: SizeOpts;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: FC<SvgIconProps>;
  capitalize?: boolean;
  disabled?: boolean;
  tip?: string;
};

type ButtonPillCssProps = ButtonCssProps & {
  boxShadow: string;
};

const sizeProps: Record<SizeOpts, SizeCssProps> = {
  normal: {
    fontSize: '1.125rem',
    iconSize: 24,
  },
  small: {
    fontSize: '0.875rem',
    iconSize: 20,
  },
};

export const ButtonPill = ({
  text,
  variant,
  size = 'normal',
  onClick,
  icon: Icon,
  capitalize = true,
  disabled = false,
  tip,
}: ButtonPillProps): JSX.Element => {
  const theme = useTheme();

  // Need to have variant props declared inside component so it can use useTheme
  const variantProps = (isDisabled: boolean): Record<VariantOpts, ButtonPillCssProps> => ({
    primary: {
      border: 'none',
      backgroundColor: isDisabled ? theme.palette.SlabYellow[400] : theme.palette.SlabYellow[500],
      color: theme.palette.SlabGray['900'],
      disabledColor: theme.palette.SlabGray['600'],
      hoverBgColor: theme.palette.SlabYellow[500],
      boxShadow: '0px 4px 32px 0px #ffd64180',
    },
    secondary: {
      border: `1px solid ${theme.palette.SlabGray['900']}`,
      backgroundColor: theme.palette.SlabGray[0],
      color: theme.palette.SlabGray['900'],
      disabledColor: theme.palette.SlabGray['600'],
      hoverBgColor: 'inherit',
      boxShadow: '0px 4px 32px 0px #c9d0fa80',
    },
  });

  const button = (
    <Button
      variant='pill'
      disabled={disabled}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        backgroundColor: variantProps(disabled)[variant].backgroundColor,
        color: variantProps(disabled)[variant].color,
        border: variantProps(disabled)[variant].border,
        '&:hover': {
          backgroundColor: variantProps(disabled)[variant].backgroundColor,
          boxShadow: variantProps(disabled)[variant].boxShadow,
        },
      }}
      startIcon={Icon != null && <Icon />}
    >
      <Typography fontSize={sizeProps[size].fontSize}>
        {capitalize ? text.toUpperCase() : text}
      </Typography>
    </Button>
  );

  return tip === undefined || tip === '' ? (
    button
  ) : (
    <Tooltip enterDelay={200} title={tip} placement='bottom' arrow>
      <Box sx={{ display: 'flex' }}>
        {button}
        <QuestionMark
          sx={{
            backgroundColor: theme.palette.SlabGray['950'],
            color: theme.palette.SlabGray[0],
            borderRadius: '50%',
            width: '0.75em',
            height: '0.75em',
          }}
        />
      </Box>
    </Tooltip>
  );
};

type ButtonMenuOption = {
  label: string;
  description?: string;
  icon?: FC<SvgIconProps>;
  onClick: () => void;
};

type ButtonMenuProps = {
  label: string;
  description?: string;
  icon?: FC<SvgIconProps>;
  options: ButtonMenuOption[];
} & Omit<ButtonPillProps, 'onClick' | 'text' | 'icon'>;

/**
 * ButtonMenu is a ButtonPill that triggers a dropdown menu to offer a primary
 * action and one or more secondary actions.
 *
 * The available actions are specified by the menu's `options`, where the first
 * item in the `options` list is the primary action (used to label the
 * ButtonPill as a whole), and all of the `options` are displayed in the
 * specified order within the dropdown menu.
 */
export const ButtonMenu = ({ icon: Icon, ...props }: ButtonMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isMenuOpen = anchorEl !== null;

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>): void => setAnchorEl(e.currentTarget);

  const closeMenu = (): void => setAnchorEl(null);

  const handleOptionPress = (onClick: () => void) => (): void => {
    onClick();
    closeMenu();
  };

  return (
    <>
      <ButtonPill {...props} icon={Icon} text={props.label} onClick={openMenu} />
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={closeMenu} sx={{ margin: 1 }}>
        {props.options.map(({ label, description, icon: MenuIcon, onClick }) => (
          <MenuItem sx={{ padding: 1.5 }} key={label} onClick={handleOptionPress(onClick)}>
            <ListItemIcon>{MenuIcon != null && <MenuIcon fontSize='medium' />}</ListItemIcon>
            <Box>
              <ListItemText>
                <Typography fontWeight='600' variant='h6'>
                  {label}
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography variant='body2'>{description}</Typography>
              </ListItemText>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
