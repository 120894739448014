import { AppBar, Box } from '@mui/material';
import logo from '@public/logo-words-grey.png';
import { FC } from 'react';
import { useCookies } from 'react-cookie';

import { useSlabAuth } from '../../hooks/useSlabAuth';
import { TenantSelectionMenu } from '../NavBar/TenantSelectionMenu';
import { AppNavbarLogoWrapper, Menu, Toolbar, ToolbarImpersonating } from './components/styles';

interface AppNavbarProps {
  isOpen: boolean;
  toggleDrawer(): void;
}

const AppNavbar: FC<AppNavbarProps> = ({ toggleDrawer, isOpen }) => {
  const { logout, user } = useSlabAuth();
  const [{ AuthOverride: authOverride }] = useCookies(['AuthOverride']);

  const isImpersonating = user?.admin && (authOverride ?? '') !== '';

  return (
    <AppBar position='fixed'>
      <Toolbar isOpen={isOpen}>
        <AppNavbarLogoWrapper display='flex'>
          <Box component='img' height={48} src={logo} />
        </AppNavbarLogoWrapper>

        <Menu onClick={toggleDrawer} />

        <ToolbarImpersonating isImpersonating={isImpersonating} />

        {user !== undefined && (
          <Box display='flex'>
            <TenantSelectionMenu auth0User={user} logout={logout} />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppNavbar;
