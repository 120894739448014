import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';
import { useContext, useEffect, useState } from 'react';

import { CostRuleInput } from '../../../components/CostRuleInput/CostRuleInput';
import { Input } from '../../../components/Input/Input';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { AddressMap } from '../../../components/Map/AddressMap';
import { SingleUnitDurationInput } from '../../../components/SingleUnitDurationInput/SingleUnitDurationInput';
import { SlabStep, SlabStepper } from '../../../components/SlabStepper/SlabStepper';
import { DeliveryCost } from '../../../generated-types/DeliveryCost/DeliveryCost';
import Enums from '../../../generated-types/Enums';
import { Unit } from '../../../generated-types/generated-enums';
import { Plant } from '../../../generated-types/Plant/Plant';
import { SlabContext } from '../../../SlabContext';
import { NullableCostString } from '../../../utils/Currency';
import { lookups } from '../../../utils/DomainHelpers';
import { SetFormikValue } from '../../../utils/FormikHelpers';
import { UnitFromString } from '../../../utils/UnitHelpers';
import { NIL_UUID } from '../../../utils/UUID';
import { NewPlantFromFormik, PlantFormikType } from '../PlantFormik';
import { ForecastsSection } from './ForecastsSection';

export const RecalculateTotalDeliveryCost = (deliveryCosts: DeliveryCost | null): string =>
  NullableCostString({ cost: deliveryCosts?.calculateTotalDeliveryCost() ?? null });

const PlantDetailsSection = (): JSX.Element => {
  const formikBag = useFormikContext<PlantFormikType>();

  return (
    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input name='name' label='Name*' />
        </Grid>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            name='market'
            label='Market*'
            route={{
              barrel: 'GET markets',
              args: ConstructListQueryParams,
              transform: (marketList): LookupInputOption[] =>
                lookups({
                  ...marketList,
                  label: (m) => m.name,
                }),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PlantLocationSection = (): JSX.Element => <AddressMap name='address' />;

const PlantDeliveryCostsSection = (): JSX.Element => {
  const formikBag = useFormikContext<PlantFormikType>();
  return (
    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input
            label='Delivery cost override'
            name='deliveryCosts.deliveryCostOverride'
            startAdornment='$'
            type='cost'
            onInputChange={async (deliveryCostOverride): Promise<void> => {
              const plant = NewPlantFromFormik(formikBag.values, {
                deliveryCosts: {
                  deliveryCostOverride: { amount: { number: deliveryCostOverride } },
                },
              });
              const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
              SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={
              UnitFromString(formikBag.values.deliveryCosts?.deliveryCostOverride?.unit)?.label ??
              ''
            }
            fullWidth
            disabled
            label='Unit'
          />
        </Grid>
      </Grid>
      {formikBag.values.deliveryCosts?.deliveryCostOverride === null ||
      formikBag.values.deliveryCosts?.deliveryCostOverride?.amount?.number === '' ? (
        <>
          <Box>
            <Typography variant='h5' color='textSecondary' sx={{ fontWeight: 500 }}>
              Time costs per trip
            </Typography>
          </Box>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={6}>
              <Input
                label='Cost per minute'
                name='deliveryCosts.perMinCost'
                startAdornment='$'
                type='currency'
                onInputChange={async (perMinCost): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: {
                      perMinCost: { number: perMinCost },
                    },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg wait'
                name='deliveryCosts.waitTime'
                unit='minutes'
                onInputChange={async (waitTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { waitTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg pour'
                name='deliveryCosts.pourTime'
                unit='minutes'
                onInputChange={async (pourTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { pourTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg load'
                name='deliveryCosts.loadTime'
                unit='minutes'
                onInputChange={async (loadTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { loadTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg wash, loaded in yard'
                name='deliveryCosts.loadedInYardTime'
                unit='minutes'
                onInputChange={async (loadedInYardTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { loadedInYardTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg wash, unloaded'
                name='deliveryCosts.unloadedAtJobTime'
                unit='minutes'
                onInputChange={async (unloadedAtJobTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { unloadedAtJobTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label='Unengaged time'
                name='deliveryCosts.unengagedTimeAdjustmentPercentage'
                type='number'
                endAdornment='%'
                onInputChange={async (unengagedTimeAdjustmentPercentage): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { unengagedTimeAdjustmentPercentage },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg time driving to job'
                name='deliveryCosts.driveToJobTime'
                unit='minutes'
                onInputChange={async (driveToJobTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { driveToJobTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleUnitDurationInput
                label='Avg time driving to location'
                name='deliveryCosts.driveToPlantTime'
                unit='minutes'
                onInputChange={async (driveToPlantTime): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { driveToPlantTime },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label='Driving time adjustment'
                name='deliveryCosts.drivingTimeAdjustmentPercentage'
                type='number'
                endAdornment='%'
                onInputChange={async (drivingTimeAdjustmentPercentage): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { drivingTimeAdjustmentPercentage },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography variant='h5' color='textSecondary' sx={{ fontWeight: 500 }}>
              Fuel costs per trip
            </Typography>
          </Box>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={4}>
              <Input
                label='Miles per gallon'
                name='deliveryCosts.driveDistancePerFuelVolume'
                type='number'
                onInputChange={async (driveDistancePerFuelVolume): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { driveDistancePerFuelVolume },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label='Distance unit'
                value={
                  UnitFromString(formikBag.values.deliveryCosts?.driveDistanceUnit)?.label ?? ''
                }
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={1} marginTop='16px' textAlign='center'>
              per
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Fuel unit'
                value={UnitFromString(formikBag.values.deliveryCosts?.fuelVolumeUnit)?.label ?? ''}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={4}>
              <Input
                label='Fuel cost per gallon'
                name='deliveryCosts.fuelCostPerUnit'
                type='currency'
                startAdornment='$'
                onInputChange={async (fuelCostPerUnit): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { fuelCostPerUnit: { number: fuelCostPerUnit } },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label='Avg miles driven to job'
                name='deliveryCosts.driveDistanceToJob'
                type='number'
                onInputChange={async (driveDistanceToJob): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { driveDistanceToJob },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label='Avg miles driven to location'
                name='deliveryCosts.driveDistanceToPlant'
                type='number'
                onInputChange={async (driveDistanceToPlant): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { driveDistanceToPlant },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography variant='h5' color='textSecondary' sx={{ fontWeight: 500 }}>
              Load size
            </Typography>
          </Box>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item xs={4}>
              <Input
                label='Avg load size'
                name='deliveryCosts.loadSize'
                type='number'
                onInputChange={async (loadSize): Promise<void> => {
                  const plant = NewPlantFromFormik(formikBag.values, {
                    deliveryCosts: { loadSize, loadSizeUnit: Unit.CuYd },
                  });
                  const totalDeliveryCost = RecalculateTotalDeliveryCost(plant.deliveryCosts);
                  SetFormikValue(formikBag, 'deliveryCosts.displayOnly', { totalDeliveryCost });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Load unit'
                value={UnitFromString(formikBag.values.deliveryCosts?.loadSizeUnit)?.label ?? ''}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Box>
            <Typography variant='h6' color='textSecondary' sx={{ fontWeight: 500 }}>
              Total delivery costs: {formikBag.values.deliveryCosts?.displayOnly?.totalDeliveryCost}
            </Typography>
          </Box>
        </>
      ) : (
        <Box>
          <Typography color='textSecondary'>
            Detailed cost inputs have been hidden because the calculated delivery cost is overridden
            above.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const PlantConstantsSection = (): JSX.Element => {
  const { userInfo } = useContext(SlabContext);
  const isSlabAdmin = userInfo.hasRoles([Enums.RoleName.SlabstackAdministrator]);
  const formikBag = useFormikContext<PlantFormikType>();

  return (
    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input
            label='SGA cost*'
            name='readyMixConstants.sgaCost'
            startAdornment='$'
            type='cost'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={UnitFromString(formikBag.values.readyMixConstants?.sgaCost.unit)?.label ?? ''}
            fullWidth
            disabled
            label='Unit'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input
            label='Operating cost*'
            name='readyMixConstants.operatingCost'
            startAdornment='$'
            type='cost'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={
              UnitFromString(formikBag.values.readyMixConstants?.operatingCost.unit)?.label ?? ''
            }
            fullWidth
            disabled
            label='Unit'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input
            label='Minimum margin*'
            name='readyMixConstants.minimumMargin'
            endAdornment='%'
            disabled={!isSlabAdmin}
            tip={
              !isSlabAdmin
                ? 'To request a change to your minimum margin, please contact our Customer Success team at support@slabstack.com.'
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label='Target margin*'
            name='readyMixConstants.targetMargin'
            endAdornment='%'
            disabled={!isSlabAdmin}
            tip={
              !isSlabAdmin
                ? 'To request a change to your target margin, please contact our Customer Success team at support@slabstack.com.'
                : undefined
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const MaterialCostRuleSection = (): JSX.Element => (
  <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
    <Grid container spacing={2} style={{ flexGrow: 0 }}>
      <Grid item style={{ maxWidth: '30rem' }}>
        Configure an adjustment to the total cost of each material, when used in cost calculations
        for products.
      </Grid>
    </Grid>
    <Grid container spacing={2} wrap='nowrap'>
      <Grid item xs={12}>
        <CostRuleInput name='materialCostRule' />
      </Grid>
    </Grid>
  </Box>
);

const PlantCapacityForecastsSection = ({
  initialForecastYear,
}: {
  initialForecastYear: number;
}): JSX.Element => (
  <ForecastsSection type='capacityForecasts' initialForecastYear={initialForecastYear} />
);

const PlantBudgetForecastsSection = ({
  initialForecastYear,
}: {
  initialForecastYear: number;
}): JSX.Element => (
  <ForecastsSection type='budgetForecasts' initialForecastYear={initialForecastYear} />
);

const PlantTruckingTypeSection = (): JSX.Element => {
  const theme = useTheme();
  const formikBag = useFormikContext<PlantFormikType>();

  return (
    <Box>
      <FieldArray
        name='truckingTypes'
        render={(arrayHelpers): JSX.Element => (
          <>
            {(formikBag.values.truckingTypes ?? []).map((tt, index) => (
              <Grid
                // eslint-disable-next-line react/no-array-index-key
                key={`trucking-type-grid-${index}`}
                container
                spacing={2}
                paddingBottom='0.5rem'
              >
                <Grid item xs={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Input name={`truckingTypes.${index}.name`} label='Name*' />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  pt='0rem !important'
                  xs={2}
                >
                  <Button
                    onClick={(): void => {
                      arrayHelpers.remove(index);
                    }}
                  >
                    <Trash variant='TwoTone' color={theme.palette.SlabIndigo['700']} />
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              type='button'
              onClick={(): void => {
                arrayHelpers.push<Plant['truckingTypes'][number]>({
                  id: NIL_UUID,
                  name: '',
                  plantId: formikBag.values.id,
                });
              }}
              sx={{
                textTransform: 'none',
                color: theme.palette.SlabIndigo['700'],
              }}
            >
              <Box display='flex' gap='0.5rem'>
                <Add color={theme.palette.SlabIndigo['700']} />
                Add {formikBag.values.truckingTypes.length === 0 ? 'trucking type' : 'another'}
              </Box>
            </Button>
          </>
        )}
      />
    </Box>
  );
};

const PlantNotesSection = (): JSX.Element => (
  <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
    <Input type='textarea' name='notes' />
  </Box>
);

export type PlantSection =
  | 'Location details*'
  | 'Location address*'
  | 'Delivery costs'
  | 'Constants*'
  | 'Material cost adjustment'
  | 'Capacity forecasts'
  | 'Budget forecasts'
  | 'Trucking types'
  | 'Notes';

const steps: SlabStep<PlantFormikType>[] = [
  {
    label: 'Location details*',
    content: PlantDetailsSection,
    maybeErrorFieldNames: ['name', 'market'],
  },
  {
    label: 'Location address*',
    content: PlantLocationSection,
    maybeErrorFieldNames: ['address'],
  },
  {
    label: 'Delivery costs',
    content: PlantDeliveryCostsSection,
    maybeErrorFieldNames: ['deliveryCosts'],
  },
  {
    label: 'Constants*',
    content: PlantConstantsSection,
    maybeErrorFieldNames: ['readyMixConstants'],
  },
  {
    label: 'Material cost adjustment',
    content: MaterialCostRuleSection,
    maybeErrorFieldNames: [],
  },
  {
    label: 'Capacity forecasts',
    content: (initialForecastYear) => PlantCapacityForecastsSection({ initialForecastYear }),
    maybeErrorFieldNames: [],
  },
  {
    label: 'Budget forecasts',
    content: (initialForecastYear) => PlantBudgetForecastsSection({ initialForecastYear }),
    maybeErrorFieldNames: [],
  },
  {
    label: 'Trucking types',
    content: PlantTruckingTypeSection,
    maybeErrorFieldNames: [],
  },
  {
    label: 'Notes',
    content: PlantNotesSection,
    maybeErrorFieldNames: [],
  },
];

type PlantDrawerSectionsProps = {
  initialSection: PlantSection;
  initialForecastYear: number;
};

export const PlantDrawerSections = ({
  initialSection,
  initialForecastYear,
}: PlantDrawerSectionsProps): JSX.Element => {
  const ctx = useContext(SlabContext);
  const hasAggregatesDemo = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagAggregatesDemo,
  ]);

  const initialStep = steps.map((s) => s.label).indexOf(initialSection);

  const stepStateHook = useState(initialStep);
  const [, setActiveStep] = stepStateHook;

  // If the initialSection changes, we use this hook to update the state
  useEffect(() => {
    const newInitialStep = steps.map((s) => s.label).indexOf(initialSection);
    setActiveStep(newInitialStep);
  }, [initialSection]);

  return (
    <SlabStepper
      steps={steps
        // Only show the TruckingTypes section if the aggregates feature flag is enabled
        .filter((s) => s.label !== 'Trucking types' || hasAggregatesDemo)
        .map((s) => ({
          ...s,
          content: () => s.content(initialForecastYear),
        }))}
      activeStepHook={stepStateHook}
    />
  );
};
