// Code generated by domaingen. DO NOT EDIT.

/* eslint-disable no-shadow, quotes, no-multiple-empty-lines */

export enum AccessType {
  Read = 'read',
  Write = 'write',
}
export enum ActivityCategory {
  Task = 'Task',
  Call = 'Call',
  Email = 'Email',
  SalesVisit = 'Sales Visit',
  SiteVisit = 'Site Visit',
  Other = 'Other',
}
export enum ActivityStatus {
  Planned = 'Planned',
  Completed = 'Completed',
  Canceled = 'Canceled',
}
export enum CompanyCategory {
  Contractor = 'Contractor',
  Engineer = 'Engineer',
  Subcontractor = 'Subcontractor',
  Owner = 'Owner',
  Vendor = 'Vendor',
  Supplier = 'Supplier',
  OutsideDesigner = 'Outside Designer',
  Architect = 'Architect',
  GeneralContractor = 'General Contractor',
}
export enum Comparator {
  Lt = 'lt',
  Lte = 'lte',
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
}
export enum CostRuleKind {
  Ratio = 'Ratio',
  Flat = 'Flat',
}
export enum CustomFieldDefinitionDomainType {
  Projects = 'projects',
  Plants = 'plants',
}
export enum CustomFieldType {
  String = 'string',
  Number = 'number',
  Date = 'Date',
  Options = 'options',
  Text = 'text',
}
export enum DashboardKind {
  Admin = 'Admin',
  CRM = 'CRM',
  Database = 'Database',
  Reporting = 'Reporting',
}
export enum DomainType {
  Activities = 'activities',
  Companies = 'companies',
  Contacts = 'contacts',
  CustomFieldDefinitions = 'custom_field_definitions',
  Dashboards = 'dashboards',
  Forecasts = 'forecasts',
  Materials = 'materials',
  Mixes = 'mixes',
  PdfTemplates = 'pdf_templates',
  Plants = 'plants',
  Products = 'products',
  ProjectStatuses = 'project_statuses',
  Projects = 'projects',
  QuoteConfigs = 'quote_configs',
  QuoteStatuses = 'quote_statuses',
  Quotes = 'quotes',
  Roles = 'roles',
  TaxCodes = 'tax_codes',
  Users = 'users',
  Markets = 'markets',
  Segments = 'segments',
  DispatchCustomers = 'dispatch_customers',
  ProjectConfigs = 'project_configs',
  QueuedNotificationEmails = 'queued_notification_emails',
  QuotePolicies = 'quote_policies',
  PlantTruckingTypes = 'plant_trucking_types',
}
export enum EscalationKind {
  Percentage = 'Percentage',
  Flat = 'Flat',
}
export enum FeatureFlagName {
  TestDummy = '_',
  DataTablePrinting = 'DataTablePrinting',
  FeatureFlagDispatchCustomer = 'DispatchCustomer',
  FeatureFlagIntegrationImport = 'IntegrationImport',
  FeatureFlagIntegrationExport = 'IntegrationExport',
  FeatureFlagAggregatesDemo = 'AggregatesDemo',
  FeatureFlagRequiresQuoteTerms = 'RequiresQuoteTerms',
}
export enum FilterOperation {
  Equals = 'equals',
  EqualsOrNull = 'equalsornull',
  NotEquals = 'notequals',
  NotEqualsAndNotNull = 'notequalsnornull',
  Lookup = 'lookup',
  Search = 'search',
}
export enum ForecastKind {
  Project = 'project',
  Budget = 'budget',
  Capacity = 'capacity',
}
export enum MaterialType {
  Aggregate = 'Aggregate',
  Cementitious = 'Cementitious',
  Admixture = 'Admixture',
  Fiber = 'Fiber',
  Water = 'Water',
  Other = 'Other',
}
export enum NylasProvider {
  Google = 'google',
  Icloud = 'icloud',
  Imap = 'imap',
  Microsoft = 'microsoft',
  Yahoo = 'yahoo',
  Ews = 'ews',
  Custom = 'custom',
  Eas = 'eas',
  Gmail = 'gmail',
}
export enum NylasTokenType {
  Bearer = 'Bearer',
  Digest = 'Digest',
  Basic = 'Basic',
}
export enum PdfTemplateType {
  Code = 'code',
  PdfGenerator = 'pdf_generator',
}
export enum PlantCategory {
  ReadyMix = 'ready_mix',
  Quarry = 'quarry',
}
export enum ProductCategory {
  Mix = 'Mix',
  Resale = 'Resale',
  Precast = 'Precast',
  Other = 'Other',
  Aggregate = 'Aggregate',
  Asphalt = 'Asphalt',
}
export enum ProductDeliveryType {
  Pickup = 'pickup',
  Hauling = 'hauling',
  InternalHauling = 'internal_hauling',
}
export enum QuotePolicyThresholdKind {
  MarginMixProductsAverageLessThanDollars = 'margin_mix_products_average_less_than_dollars',
  AlwaysTrigger = 'always_trigger',
  MixProductsUnitPriceBelowListPrice = 'mix_products_unit_price_below_list_price',
  AggregateProductsUnitPriceBelowListPrice = 'aggregate_products_unit_price_below_list_price',
  TotalVolumeBelowValue = 'total_volume_below_value',
  MarginMixProductsLessThanDollars = 'margin_mix_products_less_than_dollars',
  MarginMixProductsGreaterThanDollars = 'margin_mix_products_greater_than_dollars',
  MarginAggregateProductsLessThanDollars = 'margin_aggregate_products_less_than_dollars',
  MarginAggregateProductsGreaterThanDollars = 'margin_aggregate_products_greater_than_dollars',
  MarginAsphaltProductsLessThanDollars = 'margin_asphalt_products_less_than_dollars',
  MarginAsphaltProductsGreaterThanDollars = 'margin_asphalt_products_greater_than_dollars',
  MarginMixProductsAverageLessThanPercent = 'margin_mix_products_average_less_than_percent',
  AsphaltProductsUnitPriceBelowListPrice = 'asphalt_products_unit_price_below_list_price',
  AsphaltProductsUnitPriceAboveListPrice = 'asphalt_products_unit_price_above_list_price',
  MixProductsUnitPriceAboveListPrice = 'mix_products_unit_price_above_list_price',
  AggregateProductsUnitPriceAboveListPrice = 'aggregate_products_unit_price_above_list_price',
  QuantityLineItemMixProductsLessThan = 'quantity_line_item_mix_products_less_than',
  QuantityLineItemMixProductsGreaterThan = 'quantity_line_item_mix_products_greater_than',
  QuantityLineItemAggregateProductsLessThan = 'quantity_line_item_aggregate_products_less_than',
  QuantityLineItemAggregateProductsGreaterThan = 'quantity_line_item_aggregate_products_greater_than',
  QuantityLineItemAsphaltProductsLessThan = 'quantity_line_item_asphalt_products_less_than',
  QuantityLineItemAsphaltProductsGreaterThan = 'quantity_line_item_asphalt_products_greater_than',
  QuantityMixProductsLessThan = 'quantity_mix_products_less_than',
  QuantityMixProductsGreaterThan = 'quantity_mix_products_greater_than',
  QuantityAggregateProductsLessThan = 'quantity_aggregate_products_less_than',
  QuantityAggregateProductsGreaterThan = 'quantity_aggregate_products_greater_than',
  QuantityAsphaltProductsLessThan = 'quantity_asphalt_products_less_than',
  QuantityAsphaltProductsGreaterThan = 'quantity_asphalt_products_greater_than',
  MarginProductsAverageMoreThanPercent = 'margin_products_average_more_than_percent',
  MarginMixProductLineItemPercentLessThan = 'margin_mix_product_line_item_percent_less_than',
  MarginMixProductLineItemPercentGreaterThan = 'margin_mix_product_line_item_percent_greater_than',
  MarginAggregateProductLineItemPercentLessThan = 'margin_aggregate_product_line_item_percent_less_than',
  MarginAggregateProductLineItemPercentGreaterThan = 'margin_aggregate_product_line_item_percent_greater_than',
  MarginAsphaltProductLineItemPercentLessThan = 'margin_asphalt_product_line_item_percent_less_than',
  MarginAsphaltProductLineItemPercentGreaterThan = 'margin_asphalt_product_line_item_percent_greater_than',
  MarginMixProductsAverageGreaterThanDollars = 'margin_mix_products_average_greater_than_dollars',
  MarginAggregateProductsAverageLessThanDollars = 'margin_aggregate_products_average_less_than_dollars',
  MarginAggregateProductsAverageGreaterThanDollars = 'margin_aggregate_products_average_greater_than_dollars',
  MarginAsphaltProductsAverageLessThanDollars = 'margin_asphalt_products_average_less_than_dollars',
  MarginAsphaltProductsAverageGreaterThanDollars = 'margin_asphalt_products_average_greater_than_dollars',
  MarginMixProductsAverageGreaterThanPercent = 'margin_mix_products_average_greater_than_percent',
  MarginAggregateProductsAverageLessThanPercent = 'margin_aggregate_products_average_less_than_percent',
  MarginAggregateProductsAverageGreaterThanPercent = 'margin_aggregate_products_average_greater_than_percent',
  MarginAsphaltProductsAverageLessThanPercent = 'margin_asphalt_products_average_less_than_percent',
  MarginAsphaltProductsAverageGreaterThanPercent = 'margin_asphalt_products_average_greater_than_percent',
}
export enum QuotePolicyThresholdKindShort {
  MarginPercent = 'margin_percent',
  MarginDollars = 'margin_dollars',
  Quantity = 'quantity',
  UnitPriceVsListPrice = 'unit_price_vs_list_price',
  AlwaysTrigger = 'always_trigger',
}
export enum QuotePolicyThresholdProductAggregationKind {
  LineItem = 'line_item',
  Average = 'average',
  Total = 'total',
}
export enum QuotePolicyViolationState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}
export enum QuoteProductKind {
  Primary = 'primary',
  Additional = 'additional',
}
export enum RoleName {
  Salesperson = 'Salesperson',
  SlabstackAdministrator = 'Slabstack Administrator',
  ITAdministrator = 'IT Administrator',
  Manager = 'Manager',
  QualityControl = 'Quality Control',
  Purchasing = 'Purchasing',
}
export enum SortDirection {
  Descending = 'desc',
  Ascending = 'asc',
}
export enum Unit {
  CuYd = 'cu_yd',
  Ea = 'ea',
  Lb = 'lb',
  Tn = 'tn',
  FlOz = 'fl_oz',
  FlOzPerCwt = 'fl_oz_per_cwt',
  Gal = 'gal',
  PerHr = 'per_hr',
  In = 'in',
  Ft = 'ft',
  Yd = 'yd',
  Lf = 'lf',
  Mm = 'mm',
  Cm = 'cm',
  M = 'm',
  Km = 'km',
  SqIn = 'sq_in',
  SqFt = 'sq_ft',
  SqYd = 'sq_yd',
  SqMi = 'sq_mi',
  Ac = 'ac',
  SqMm = 'sq_mm',
  SqCm = 'sq_cm',
  SqM = 'sq_m',
  SqKm = 'sq_km',
  CuIn = 'cu_in',
  CuFt = 'cu_ft',
  CuMm = 'cu_mm',
  CuCm = 'cu_cm',
  CuM = 'cu_m',
  Pt = 'pt',
  Qt = 'qt',
  L = 'L',
  ML = 'mL',
  Oz = 'oz',
  G = 'g',
  Kg = 'kg',
  T = 't',
  PerL = 'per_L',
  PerQty = 'per_qty',
  PerCuYd = 'per_cu_yd',
  PerM = 'per_m',
  Bag = 'bag',
  Dosage = 'dosage',
  Percent = 'percent',
  PerTn = 'per_tn',
  PerLoad = 'per_load',
  Mi = 'mi',
  PerCuM = 'per_cu_m',
  BlockEquivalentUnit = 'block_equivalent_unit',
}
