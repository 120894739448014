// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "defaultName": "Enums", "path": "../Enums" }

import { DateTime } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as DateHelpers from '../../utils/DateHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Company, NewCompany } from '../Company/Company';
import { Contact, NewContact } from '../Contact/Contact';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';
import { NewProject, Project } from '../Project/Project';
import { NewQuoteConfig, QuoteConfig } from '../QuoteConfig/QuoteConfig';
import {
  NewQuotePriceEscalation,
  QuotePriceEscalation,
} from '../QuotePriceEscalation/QuotePriceEscalation';
import { NewQuoteProduct, QuoteProduct } from '../QuoteProduct/QuoteProduct';
import { NewQuoteStatus, QuoteStatus } from '../QuoteStatus/QuoteStatus';
import { NewUser, User } from '../User/User';

export class Quote {
  readonly id: string;
  readonly quoteNumber: string;
  readonly name: string | null;
  readonly revisionNumber: number;
  readonly status: QuoteStatus;
  readonly externalID: string | null;
  readonly terms: string | null;
  readonly expirationDate: DateTime | null;
  readonly project: Project;
  readonly company: Company | null;
  readonly contact: Contact | null;
  readonly quoteConfig: QuoteConfig | null;
  readonly products: QuoteProduct[];
  readonly priceEscalations: QuotePriceEscalation[];
  readonly user: User;
  readonly creationDate: DateTime | null;
  readonly revisionDate: DateTime | null;
  readonly revenue: Currency;
  readonly estimatedVolume: number;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.quoteNumber = data.quoteNumber === undefined ? '' : data.quoteNumber;
    this.name = data.name === undefined ? null : data.name;
    this.revisionNumber = data.revisionNumber === undefined ? 0 : data.revisionNumber;
    this.status = NewQuoteStatus(data.status);
    this.externalID =
      // eslint-disable-next-line no-nested-ternary
      (data.externalID ?? null) === null
        ? null
        : data.externalID === undefined
          ? ''
          : data.externalID;
    this.terms = data.terms === undefined ? null : data.terms;
    this.expirationDate = DateHelpers.ParseServerNullableDate(data.expirationDate);
    this.project = NewProject(data.project);
    this.company =
      // eslint-disable-next-line no-nested-ternary
      (data.company ?? null) === null ? null : NewCompany(data.company);
    this.contact =
      // eslint-disable-next-line no-nested-ternary
      (data.contact ?? null) === null ? null : NewContact(data.contact);
    this.quoteConfig =
      // eslint-disable-next-line no-nested-ternary
      (data.quoteConfig ?? null) === null ? null : NewQuoteConfig(data.quoteConfig);
    this.products = (data.products ?? []).map((products: any) => NewQuoteProduct(products));
    this.priceEscalations = (data.priceEscalations ?? []).map((priceEscalations: any) =>
      NewQuotePriceEscalation(priceEscalations),
    );
    this.user = NewUser(data.user);
    this.creationDate = DateHelpers.ParseServerNullableDate(data.creationDate);
    this.revisionDate = DateHelpers.ParseServerNullableDate(data.revisionDate);
    this.revenue = NewCurrency(data.revenue);
    this.estimatedVolume = data.estimatedVolume === undefined ? 0 : data.estimatedVolume;
  }

  static zero(): Quote {
    const zeroValues: ExcludeMethodsDeep<Quote> = {
      id: NIL_UUID,
      quoteNumber: '',
      name: null,
      revisionNumber: 0,
      status: QuoteStatus.zero(),
      externalID: null,
      terms: null,
      expirationDate: null,
      project: Project.zero(),
      company: null,
      contact: null,
      quoteConfig: null,
      products: [],
      priceEscalations: [],
      user: User.zero(),
      creationDate: null,
      revisionDate: null,
      revenue: Currency.zero(),
      estimatedVolume: 0,
    };
    return new Quote(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  expirationDateDisplay(): string | null {
    if (this.expirationDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.expirationDate);
  }

  creationDateDisplay(): string | null {
    if (this.creationDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.creationDate);
  }

  revisionDateDisplay(): string | null {
    if (this.revisionDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.revisionDate);
  }

  filterProducts(kind: Enums.QuoteProductKind): QuoteProduct[] {
    return this.products.filter((qp) => qp.kind === kind);
  }

  /**
   * This will return the quote name as a `.pdf` appended string.
   * If the quote name is "empty", then it will return the project name as a `.pdf` appended string.
   * If the project name is "empty", it will return a default value of `quote.pdf`.
   */
  fileName(): string {
    if (this.name !== undefined && this.name !== null && this.name !== '') {
      return `${this.name}.pdf`;
    }
    if (this.project.name !== undefined && this.project.name !== null && this.project.name !== '') {
      return `${this.project.name}.pdf`;
    }
    return 'quote.pdf';
  }

  /**
   * hasUOMIssues will return true if a single product in this quote has an
   * incompatible mix batch unit.
   */
  hasUOMIssues(): boolean {
    return this.products.some((qp) => qp.product.incompatibleMixBatchUnits);
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuote = (props: PartialDeep<Quote, { recurseIntoArrays: true }>): Quote =>
  new Quote(props);

export const NewQuoteFromDomainObject = (
  props: PartialDeep<DomainObject<Quote>, { recurseIntoArrays: true }>,
): Quote => new Quote(props);
