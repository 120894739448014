import * as Yup from 'yup';

import {
  FormikProductReference,
  MainProjectOrQuoteProductsFormikSchema,
  ProjectOrQuoteProductEditableFieldsFormikType,
  ProjectOrQuoteProductFormikType,
} from '../../components/ProductSection/ProjectOrQuoteProductFormik';
import { Company } from '../../generated-types/Company/Company';
import { Contact } from '../../generated-types/Contact/Contact';
import { Cost, NewCost } from '../../generated-types/Cost/Cost';
import { Currency } from '../../generated-types/Currency/Currency';
import { CustomFieldDefinition } from '../../generated-types/CustomFieldDefinition/CustomFieldDefinition';
import Enums from '../../generated-types/Enums';
import { Forecast } from '../../generated-types/Forecast/Forecast';
import { Project } from '../../generated-types/Project/Project';
import {
  NewProjectProduct,
  ProjectProduct,
} from '../../generated-types/ProjectProduct/ProjectProduct';
import { NewQuoteProduct } from '../../generated-types/QuoteProduct/QuoteProduct';
import { CalculateAggregateTotalPrice } from '../../utils/AggregateCalculations';
import { DomainObject } from '../../utils/ApiClient';
import { DineroString, NullableCostString, NullableCurrencyString } from '../../utils/Currency';
import {
  FormikAddress,
  FormikCurrency,
  FormikDeliveryCosts,
  FormikNullableCurrency,
} from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  nonNegativeStringNumber,
  TestForUndefined,
  YupAddress,
  YupForecasts,
  type YupFormikForecasts,
  YupNullableCurrency,
  YupNullableDecimal,
  YupNullableDuration,
  YupNullableLocalDate,
  YupNullableNumber,
  YupNullablePercentage,
  YupNullableReference,
  YupNullableString,
  YupReference,
  YupSchemaNullableReferenceType,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

/**
 * CompanyContactToFormik converts a Company and a maybe-null Contact into a formik-friendly
 * object.
 */
export const CompanyContactToFormik = ({
  usesDispatchCustomer,
  company,
  contact,
}: {
  usesDispatchCustomer: boolean;
  company: Company;
  contact: Contact | null;
}): {
  company: YupSchemaReferenceType;
  contact: YupSchemaNullableReferenceType;
} => ({
  company: {
    id: company.id,
    option: {
      value: company.id,
      label: company.name,
      sublabels: company.lookupSublabels(usesDispatchCustomer),
    },
  },
  contact: {
    id: contact?.id ?? null,
    option:
      contact === null
        ? null
        : {
            value: contact.id,
            label: contact.fullName(),
          },
  },
});

export type ProjectFormikType = DomainObject<
  Omit<
    Project,
    | 'plant'
    | 'companies'
    | 'products'
    | 'totalCosts'
    | 'estimatedVolume'
    | 'revenue'
    | 'margin'
    | 'customFields'
    | 'winningCompany'
    | 'user'
    | 'projectStatus'
    | 'forecasts'
    | 'taxCode'
    | 'segment'
    | 'projectConfig'
  >
> & {
  user: YupSchemaReferenceType;
  plant: YupSchemaReferenceType;
  contractors: {
    company: YupSchemaReferenceType;
    contact: YupSchemaNullableReferenceType;
  }[];
  otherCompanies: {
    company: YupSchemaReferenceType;
    contact: YupSchemaNullableReferenceType;
  }[];
  winningCompany: {
    company: YupSchemaNullableReferenceType;
  };
  products: ProjectOrQuoteProductFormikType[];
  projectStatus: YupSchemaReferenceType;
  customFields: {
    definition: Pick<DomainObject<CustomFieldDefinition>, 'id' | 'name' | 'type' | 'options'>;
    value: string | null;
  }[];
  // projectID & plantID are purposefully omitted since the server does not look at it
  forecasts: YupFormikForecasts;
  taxCode: YupSchemaNullableReferenceType;
  segment: YupSchemaNullableReferenceType;
  projectConfig: YupSchemaNullableReferenceType;
  // This flag is required to know if the plant distance has changed
  // so that we can recalculate the products' delivery costs.
  hasDistanceChanged: boolean;
};

const sharedProjectSchemaFields = {
  name: YupString('Name'),
  projectNumber: YupNullableString('Project number'),
  projectStatus: YupReference('Status'),
  estimatedVolumeOverride: YupNullableNumber({
    label: 'Volume',
    integer: true,
    positive: true,
  }),
  totalCostsOverride: YupNullableCurrency('Total cost'),
  revenueOverride: YupNullableCurrency('Revenue'),
  confidence: YupNullablePercentage('Confidence').test(nonNegativeStringNumber),
  terms: YupNullableString('Terms'),
  notes: YupNullableString('Notes'),
  plant: YupReference('Location'),
  plantDistanceMiles: YupNullableDecimal('Location distance')
    .transform((v) => v?.replaceAll(',', ''))
    .test(nonNegativeStringNumber),
  plantDriveTime: YupNullableDuration('Drive time'),

  // When dates are cleared, the value is replaced with empty string
  estimatedStartDate: YupNullableLocalDate('Start date'),
  estimatedEndDate: YupNullableLocalDate('End date'),
  bidDate: YupNullableLocalDate('Bid date'),
  expirationDate: YupNullableLocalDate('Expiration date'),

  address: YupAddress('Address'),
  user: YupReference('User'),

  products: MainProjectOrQuoteProductsFormikSchema,

  winningCompany: Yup.object()
    .shape({
      company: YupNullableReference('Company'),
    })
    .nullable()
    .transform((c: { company: YupSchemaNullableReferenceType } | null) =>
      c?.company?.id === null ? null : c,
    ),

  owner: YupNullableString('Owner'),
  competitor: YupNullableString('Competitor'),

  customFields: Yup.array().of(
    Yup.object().shape({
      definition: Yup.object().shape({
        id: YupString('ID'),
        // Use Yup.mixed() for values that we do not care to validate
        name: Yup.mixed(),
        type: Yup.mixed(),
        options: Yup.mixed(),
      }),
      value: YupNullableString('Value'),
    }),
  ),

  forecasts: YupForecasts('Forecast'),

  taxCode: YupNullableReference('Tax code'),
  segment: YupNullableReference('Segment'),
  projectConfig: YupNullableReference('Quote config'),
};

/** This schema will be used for yup validation */
export const ProjectSchemaFormik: Yup.SchemaOf<
  Omit<ProjectFormikType, 'id' | 'products' | 'hasDistanceChanged'> & {
    products: ProjectOrQuoteProductEditableFieldsFormikType[];
  }
> = Yup.object()
  .shape({
    ...sharedProjectSchemaFields,

    contractors: Yup.array().of(
      Yup.object().shape({
        company: YupReference('Company'),
        contact: YupNullableReference('Contact'),
      }),
    ),
    otherCompanies: Yup.array().of(
      Yup.object().shape({
        company: YupReference('Company'),
        contact: YupNullableReference('Contact'),
      }),
    ),
  })
  .test(TestForUndefined('ProjectSchemaFormik'));

/** This schema will be used for yup validation */
export const ProjectSchemaWire: Yup.SchemaOf<
  Omit<
    ProjectFormikType,
    'id' | 'contractors' | 'otherCompanies' | 'products' | 'hasDistanceChanged'
  > & {
    products: ProjectOrQuoteProductEditableFieldsFormikType[];
  }
> = Yup.object().shape({
  ...sharedProjectSchemaFields,

  companies: Yup.array().of(
    Yup.object().shape({
      company: YupReference('Company'),
      contact: YupNullableReference('Contact'),
    }),
  ),
});

export const RecalculateSuggestedPrice = (projectProduct: ProjectProduct): Currency | null => {
  const p = NewProjectProduct({
    ...projectProduct,
    deliveryCosts: {
      ...projectProduct.deliveryCosts,
      totalDeliveryCost: projectProduct.deliveryCosts.calculateTotalDeliveryCost() ?? Cost.zero(),
    },
  });
  const price = p.calculateSuggestedPrice();
  return price;
};

export const FormatSuggestedPrice = (amount: Currency | null, unit: Enums.Unit): string =>
  NullableCostString({
    cost: NewCost({
      amount: amount ?? Currency.zero(),
      unit,
    }),
  });

/** Returns the form data for the provided Project, including the specified custom fields. */
export const FormikProject = (
  projectData: Partial<Project> | undefined,
  customFieldDefinitions: CustomFieldDefinition[],
  usesDispatchCustomer: boolean,
): ProjectFormikType => {
  const projectForecasts = Forecast.fillForecasts({
    kind: Enums.ForecastKind.Project,
    currentForecasts: projectData?.forecasts ?? [],
  }).map<ProjectFormikType['forecasts'][number]>((pf) => ({
    kind: pf.kind,
    value: pf.value,
    unit: pf.unit,
    intervalLength: pf.intervalLength.toISO() ?? '',
    intervalStart: pf.intervalStart.toISO() ?? '',
  }));

  return {
    id: projectData?.id ?? NIL_UUID,
    name: projectData?.name ?? '',
    projectNumber: projectData?.projectNumber ?? null,
    projectStatus: {
      id: projectData?.projectStatus?.id ?? null,
      option:
        projectData?.projectStatus === undefined
          ? null
          : {
              value: projectData.projectStatus.id,
              label: projectData.projectStatus.name,
            },
    },
    totalCostsOverride: projectData?.totalCostsOverride ?? null,
    estimatedVolumeOverride: projectData?.estimatedVolumeOverride ?? null,
    revenueOverride: FormikNullableCurrency(projectData?.revenueOverride),
    estimatedStartDate: projectData?.estimatedStartDate?.toISO() ?? null,
    estimatedEndDate: projectData?.estimatedEndDate?.toISO() ?? null,
    bidDate: projectData?.bidDate?.toISO() ?? null,
    expirationDate: projectData?.expirationDate?.toISO() ?? null,
    confidence:
      projectData?.confidence !== undefined && projectData.confidence !== null
        ? String(parseFloat((parseFloat(projectData.confidence) * 100).toFixed(3)))
        : null,
    terms: projectData?.terms ?? null,
    notes: projectData?.notes ?? null,
    owner: projectData?.owner ?? null,
    competitor: projectData?.competitor ?? null,

    address: FormikAddress(projectData?.address),

    plant: {
      id: projectData?.plant?.id ?? null,
      option:
        projectData?.plant === undefined
          ? null
          : {
              value: projectData.plant.id,
              label: projectData.plant.name,
            },
    },
    plantDistanceMiles:
      (projectData?.plantDistanceMiles ?? null) === null
        ? null
        : Number(projectData?.plantDistanceMiles).toLocaleString(),
    plantDriveTime: projectData?.plantDriveTime?.toISO() ?? null,

    user: {
      id: projectData?.user?.id ?? null,
      option:
        projectData?.user === undefined
          ? null
          : {
              value: projectData.user.id,
              label: projectData.user.fullName(),
            },
    },
    winningCompany: {
      company: {
        id: projectData?.winningCompany?.company?.id ?? null,
        option:
          projectData?.winningCompany?.company === undefined
            ? null
            : {
                value: projectData.winningCompany.company.id,
                label: projectData.winningCompany.company.name,
              },
      },
    },
    contractors: (projectData?.companies ?? [])
      .filter((pc) => pc.company.category === Enums.CompanyCategory.Contractor)
      .map((pc) =>
        CompanyContactToFormik({
          usesDispatchCustomer,
          company: pc.company,
          contact: pc.contact,
        }),
      ),
    otherCompanies: (projectData?.companies ?? [])
      .filter((pc) => pc.company.category !== Enums.CompanyCategory.Contractor)
      .map((pc) =>
        CompanyContactToFormik({
          usesDispatchCustomer,
          company: pc.company,
          contact: pc.contact,
        }),
      ),
    products:
      projectData?.products?.map((p) => {
        // TODO: This instantiation is temporary until we cut over this to use the
        // same QuoteProductToProjectOrQuoteProductFormik (or someething)
        const qp = NewQuoteProduct({ ...p });

        const taxRate = parseFloat(qp.taxRate ?? '0') / 100;
        const haulRate = qp.haulRate ?? Currency.zero();
        const aggSubtotal = haulRate.add(qp.price);
        const aggTotal = CalculateAggregateTotalPrice({
          unitPrice: qp.price,
          haulRate,
          taxRate,
          aggHaulTaxable: qp.aggHaulTaxable,
        });

        return {
          product: FormikProductReference(p.product),
          quantity: p.quantity,
          externalName: p.externalName,
          usage: p.usage,
          price: FormikCurrency(p.price),
          deliveryCosts: FormikDeliveryCosts(p.deliveryCosts),
          isMoneySwitchOn: false,
          displayOnly: {
            // Product-specific
            listPrice: NullableCurrencyString({ cur: p.product.listPrice }),
            targetMargin: p.product.targetMarginDisplay(),

            // ProjectProduct-as-QuoteProduct-specific
            suggestedPrice: NullableCurrencyString({ cur: qp.calculateSuggestedPrice() }),
            actualMarginPercentage: qp.marginAsPercentage(),
            unitCost: DineroString({ dinero: qp.costPerUnit() }),
            actualMarginCurrency: qp.marginAsFormattedCurrency(),
            marginOverMaterialsCurrency: qp.marginOverMaterialAsFormattedCurrency(),
            marginOverMaterialsPercentage: qp.marginOverMaterialAsPercentage(),
          },

          // Shared fields with Quote Products that always remain empty.
          class: null,
          kind: Enums.QuoteProductKind.Primary,

          // Aggregate-displayOnly fields
          aggSubtotal: NullableCurrencyString({ cur: aggSubtotal }),
          aggTotal: NullableCurrencyString({ cur: aggTotal }),

          // Aggregate-specific fields
          aggHaulTaxable: qp.aggHaulTaxable,
          deliveryType: qp.deliveryType ?? null,
          haulRate: FormikNullableCurrency(qp.haulRate),
          minimumHaulCharge: qp.minimumHaulCharge,
          taxRate: qp.taxRate !== null ? parseFloat(qp.taxRate).toFixed(2) : null,
          truckingType: {
            id: qp.truckingType?.id ?? null,
            option:
              qp.truckingType === null
                ? null
                : {
                    value: qp.truckingType.id,
                    label: qp.truckingType.name,
                  },
          },
        };
      }) ?? [],
    customFields: customFieldDefinitions.map((cfd) => {
      const value = ((): string | null => {
        const cfValue =
          projectData?.customFields?.find((cf) => cf.definition.id === cfd.id)?.value ?? null;
        return cfValue === null ? null : String(cfValue);
      })();
      return {
        definition: cfd,
        value,
      };
    }),
    forecasts: projectForecasts,
    taxCode: {
      id: projectData?.taxCode?.id ?? null,
      option:
        projectData?.taxCode === undefined || projectData.taxCode === null
          ? null
          : {
              value: projectData.taxCode.id,
              label: projectData.taxCode.name,
              sublabels: [projectData.taxCode.code],
            },
    },
    segment: {
      id: projectData?.segment?.id ?? null,
      option:
        projectData?.segment === undefined || projectData.segment === null
          ? null
          : {
              value: projectData.segment.id,
              label: projectData.segment.name,
            },
    },
    projectConfig: {
      id: projectData?.projectConfig?.id ?? null,
      option:
        projectData?.projectConfig === undefined || projectData.projectConfig === null
          ? null
          : {
              value: projectData.projectConfig.id,
              label: projectData.projectConfig.name,
            },
    },
    hasDistanceChanged: false,
  };
};

export const EmptyFormikProject = FormikProject(undefined, [], false);
