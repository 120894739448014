import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import Enums from '../../../../generated-types/Enums';
import { Quote } from '../../../../generated-types/Quote/Quote';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { QuotePdf } from '../../../QuotePdf';
import { PdfNotes } from '../../components/PdfNotes';
import { PdfPriceEscalationTable } from '../../components/PdfPriceEscalationTable';
import { PdfProductsTable } from '../../components/PdfProductsTable';
import { PdfSpacer } from '../../components/PdfSpacer';
import { PdfHeaderTable } from '../components/PdfHeaderTable';
import { PdfSignatures } from '../components/PdfSignatures';
import { PdfTerms } from '../components/PdfTerms';

const viewStyle: Style = {
  textAlign: 'center',
};

const customUnitLabels = (label: string): string => {
  switch (label) {
    case 'CUYD':
      return '/cy';
    case '/CUYD':
      return '/cy';
    default:
      return label;
  }
};

type CarraraDefaultTemplateProps = {
  quote: Quote;
};

export const CarraraDefaultTemplate = ({ quote }: CarraraDefaultTemplateProps): JSX.Element => (
  <QuotePdf quote={quote} disableHyphenation>
    <PdfHeaderTable quote={quote} />
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='Ready Mixed Concrete'
      viewStyle={{
        alignItems: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfProductsTable
      products={quote.filterProducts(Enums.QuoteProductKind.Primary)}
      displayHeaders
      columnConfigs={[
        {
          id: 'name',
          label: 'Product Description',
          type: 'string',
          headerViewStyle: viewStyle,
          rowViewStyle: viewStyle,
          width: '60%',
        },
        {
          id: 'price',
          label: 'Price',
          type: 'Currency',
          headerViewStyle: viewStyle,
          rowViewStyle: viewStyle,
          width: '20%',
        },
        {
          id: 'measurementUnit',
          label: 'Unit',
          type: 'string',
          headerViewStyle: viewStyle,
          rowViewStyle: viewStyle,
          width: '20%',
          formatValueToString: customUnitLabels,
        },
      ]}
      striped
      bordered
    />
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='Additional Products and Services'
      viewStyle={{
        alignItems: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfProductsTable
      products={quote.filterProducts(Enums.QuoteProductKind.Additional)}
      displayHeaders
      columnConfigs={[
        {
          id: 'name',
          label: 'Product Description',
          type: 'string',
          headerViewStyle: viewStyle,
          rowViewStyle: viewStyle,
          width: '60%',
        },
        {
          id: 'price',
          label: 'Price',
          type: 'Currency',
          headerViewStyle: viewStyle,
          rowViewStyle: viewStyle,
          width: '20%',
        },
        {
          id: 'measurementUnit',
          label: 'Unit',
          type: 'string',
          headerViewStyle: viewStyle,
          rowViewStyle: viewStyle,
          width: '20%',
          formatValueToString: customUnitLabels,
        },
      ]}
      striped
      bordered
    />
    {quote.priceEscalations.length === 0 ? null : (
      <View>
        <PdfSpacer height='0.25in' />
        <PdfTextBlock
          content='Price Escalations'
          viewStyle={{
            alignItems: 'center',
          }}
        />
        <PdfSpacer height='0.1in' />
        <PdfPriceEscalationTable
          quotePriceEscalations={quote.priceEscalations}
          columnConfigs={[
            {
              id: 'escalationDate',
              label: 'Escalation Date',
              headerViewStyle: viewStyle,
              rowViewStyle: viewStyle,
            },
            {
              id: 'amount',
              label: 'Amount',
              headerViewStyle: viewStyle,
              rowViewStyle: viewStyle,
            },
          ]}
          displayHeaders
          striped
          bordered
        />
      </View>
    )}
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='HOURS OF OPERATION: Normal business hours are 7 am to 4 pm Monday - Friday. Delivery outside of these hours will incur extra charges.'
      textStyle={{
        fontFamily: 'OpenSans',
        fontStyle: 'bold',
        fontSize: '8pt',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfSpacer height='0.25in' />
    {quote.terms !== null && (
      <View>
        <PdfNotes quote={quote} fontSize={10} />
        <PdfSpacer height='0.25in' />
      </View>
    )}
    <PdfSignatures quote={quote} />
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='A SIGNED AND DATED COPY OF THIS QUOTE MUST BE RETURNED WITH THE PURCHASE ORDER'
      textStyle={{
        fontSize: '9pt',
        textAlign: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfTextBlock
      content='MAIL PURCHASE ORDER TO: 2464 CASE STREET - MIDDLEBURY, VT 05753'
      textStyle={{
        fontSize: '9pt',
        textAlign: 'center',
      }}
    />
    <View break />
    <PdfHeaderTable quote={quote} />
    <PdfSpacer height='0.25in' />
    <PdfTerms />
  </QuotePdf>
);
