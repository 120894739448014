import { Cached } from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import LogRocket from 'logrocket';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { InputDropdown, InputOption } from '../../../components/InputDropdown/InputDropdown';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import { LocalLookupInput } from '../../../components/LookupInput/LocalLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import {
  ProductSection,
  ProductSectionProps,
} from '../../../components/ProductSection/ProductSection';
import {
  ProjectOrQuoteProductFormikType,
  QuoteProductToProjectOrQuoteProductFormik,
} from '../../../components/ProductSection/ProjectOrQuoteProductFormik';
import { SlabStep, SlabStepper } from '../../../components/SlabStepper/SlabStepper';
import { Contact } from '../../../generated-types/Contact/Contact';
import { Cost } from '../../../generated-types/Cost/Cost';
import { Currency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { Product } from '../../../generated-types/Product/Product';
import { NewProject, Project } from '../../../generated-types/Project/Project';
import { ProjectProduct } from '../../../generated-types/ProjectProduct/ProjectProduct';
import { ProjectSummary } from '../../../generated-types/ProjectSummary/ProjectSummary';
import { QuoteConfig } from '../../../generated-types/QuoteConfig/QuoteConfig';
import { QuoteConfigProduct } from '../../../generated-types/QuoteConfigProduct/QuoteConfigProduct';
import { NewQuoteProduct } from '../../../generated-types/QuoteProduct/QuoteProduct';
import { QuoteStatus } from '../../../generated-types/QuoteStatus/QuoteStatus';
import { User } from '../../../generated-types/User/User';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { ListURLParams, QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { isValidReference, lookups } from '../../../utils/DomainHelpers';
import { SetFormikValue } from '../../../utils/FormikHelpers';
import { List } from '../../../utils/List';
import { getTenantRequiresApprovals } from '../../../utils/TenantConfig';
import { YupSchemaNullableReferenceType } from '../../../utils/YupHelpers';
import { EmptyFormikQuote, QuoteFormikType } from '../QuoteFormik';
import { PriceEscalationSection } from './PriceEscalationSection';

// This is used for defaulting when we add a new product, or when we add a plant that has no delivery costs.
const zeroDeliveryCost = {
  deliveryCostOverride: Cost.zero(),
};

export const ProjectProductToQuoteProductFormik = (
  projectProduct: ProjectProduct,
): ProjectOrQuoteProductFormikType =>
  QuoteProductToProjectOrQuoteProductFormik(
    NewQuoteProduct({
      class: null,
      deliveryCosts: projectProduct.deliveryCosts,
      externalName: projectProduct.product.name,
      kind: Enums.QuoteProductKind.Primary,
      price: projectProduct.price,
      product: projectProduct.product,
      quantity: projectProduct.quantity,
      usage: projectProduct.usage,
    }),
  );

export const QuoteConfigProductToQuoteProductFormik = (
  qcp: QuoteConfigProduct,
): ProjectOrQuoteProductFormikType =>
  QuoteProductToProjectOrQuoteProductFormik(
    NewQuoteProduct({
      class: null,
      externalName: qcp.product.name,

      kind: Enums.QuoteProductKind.Additional,
      product: qcp.product,
      quantity: null,
      deliveryCosts: qcp.product.plant.deliveryCosts ?? zeroDeliveryCost,
      usage: qcp.usage,

      price: qcp.product.listPrice ?? Currency.zero(),
    }),
  );

const contactLookups = (companyID: string | null, contactList: List<Contact>): InputOption[] =>
  lookups({
    items: contactList.items.filter((c) => c.company.id === companyID),
    label: (c) => c.fullName(),
    // I think this needs a refactoring, so for now just returning matching length.
    count: contactList.items.filter((c) => c.company.id === companyID).length,
  });

// TODO: #1138 stop MUI warnings
export const onCompanyChange = (
  companyID: string | null,
  contactList: List<Contact>,
): YupSchemaNullableReferenceType => {
  const contactOpts = contactLookups(companyID, contactList);
  if (contactOpts.length !== 1) {
    return EmptyFormikQuote.contact;
  }
  return {
    id: contactOpts[0].value ?? null,
    option: contactOpts[0],
  };
};

export const onProjectChange = (
  project: Project | undefined,
): {
  company: QuoteFormikType['company'];
  contact: QuoteFormikType['contact'];
} => {
  if (project === undefined) {
    return {
      company: EmptyFormikQuote.company,
      contact: EmptyFormikQuote.contact,
    };
  }

  // If the selected project only has one contractor, use their company & contact IDs.
  const contractors = project.companies.filter(
    (pc) => pc.company.category === Enums.CompanyCategory.Contractor,
  );
  if (contractors.length === 1) {
    return {
      company: {
        id: contractors[0].company.id,
        option: {
          value: contractors[0].company.id,
          label: contractors[0].company.name,
        },
      },
      contact:
        contractors[0].contact === null
          ? EmptyFormikQuote.contact
          : {
              id: contractors[0].contact.id,
              option: {
                value: contractors[0].contact.id,
                label: contractors[0].contact.fullName(),
              },
            },
    };
  }
  return {
    company: EmptyFormikQuote.company,
    contact: EmptyFormikQuote.contact,
  };
};

/**
 * Given a project, return lookups for all quote configs that exist for the plant of the selected project.
 */
const quoteConfigLookups = ({
  quoteConfigList,
  project,
}: {
  quoteConfigList: List<QuoteConfig>;
  project: Project | undefined;
}): InputOption[] => {
  if (project === undefined) {
    return [];
  }

  return lookups({
    items: quoteConfigList.items.filter((qc) => qc.plant.id === project.plant.id),
    label: (qc) => qc.name,
    // TODO: #3544 will refactor this. Choosing 0 more items for now.
    count: 0,
  });
};

type ProductsSectionProps = {
  curProductsRef: ProductSectionProps['curProductsRef'];
};

type HandleProductStateOpts = {
  curProductState: ProductSectionProps['curProductsRef']['current'];
  quoteConfigList: List<QuoteConfig>;
  project: Project | undefined;
  formikMainProducts: QuoteFormikType['mainProducts'];
  formikAdditionalProducts: QuoteFormikType['additionalProducts'];
  formikTerms: QuoteFormikType['terms'];
  formikQuoteConfig: QuoteFormikType['quoteConfig'];

  projectID?: string | null;
  quoteConfigID?: string | null;
};

type HandleProductStateReturn = {
  newProductState: ProductSectionProps['curProductsRef']['current'];
  newFormikMainProducts: QuoteFormikType['mainProducts'];
  newFormikAdditionalProducts: QuoteFormikType['additionalProducts'];
  newFormikTerms: QuoteFormikType['terms'];
  newFormikQuoteConfig: QuoteFormikType['quoteConfig'];
};

/**
 * The QuoteProduct state can be adjusted from changing project, quoteConfig, and
 * manually adding/removing values. This helper function takes inputs and returns appropriate
 * values to set based on the outside factors of project/quote config.
 */
export const HandleProductState = ({
  curProductState,
  formikMainProducts,
  formikAdditionalProducts,
  formikTerms,
  formikQuoteConfig,
  quoteConfigList,
  project,

  projectID,
  quoteConfigID,
}: HandleProductStateOpts): HandleProductStateReturn => {
  // No project is selected, or a project was just cleared, so always clear state and formik products.
  if (project === undefined || projectID === null) {
    return {
      newProductState: {},
      newFormikMainProducts: [],
      newFormikAdditionalProducts: [],
      newFormikTerms: formikTerms,
      newFormikQuoteConfig: EmptyFormikQuote.quoteConfig,
    };
  }

  // A new project was selected
  if (projectID !== undefined) {
    const projectProducts = project.products;
    const newFormikMainProducts = projectProducts.map(ProjectProductToQuoteProductFormik);

    const newProductState: Record<number, Product> = {};
    projectProducts.forEach((pp, idx) => {
      newProductState[idx] = pp.product;
    });

    // If there is only one matching quote config, use that to populate additional products
    const quoteConfigSharingPlant = quoteConfigList.items.filter(
      (qc) => qc.plant.id === project.plant.id,
    );

    if (quoteConfigSharingPlant.length === 1) {
      const newQuoteConfig = quoteConfigSharingPlant[0];
      const newFormikAdditionalProducts = newQuoteConfig.products.map(
        QuoteConfigProductToQuoteProductFormik,
      );

      const curProductLength = projectProducts.length;
      newQuoteConfig.products.forEach((qcp, idx) => {
        const newIdx = curProductLength + idx;
        newProductState[newIdx] = qcp.product;
      });

      return {
        newProductState,
        newFormikMainProducts,
        newFormikAdditionalProducts,
        newFormikTerms: newQuoteConfig.terms,
        newFormikQuoteConfig: {
          id: newQuoteConfig.id,
          option: {
            label: newQuoteConfig.name,
            value: newQuoteConfig.id,
          },
        },
      };
    }

    // The new project does not have a single matching quote config, so return current values.
    return {
      newProductState,
      newFormikMainProducts,
      newFormikAdditionalProducts: [],
      newFormikTerms: formikTerms,
      newFormikQuoteConfig: formikQuoteConfig,
    };
  }

  // Quote config was cleared, so clear all additional products
  if (quoteConfigID === null) {
    // Construct a new state only using values inside the formikMainProducts
    const newProductState: Record<number, Product | null> = Object.entries(curProductState).reduce(
      (acc: Record<number, Product | null>, [k, qp]) => {
        if (Number(k) < formikMainProducts.length) {
          const numKey = Number(k);
          return {
            ...acc,
            [numKey]: qp,
          };
        }
        return acc;
      },
      {} as Record<number, Product | null>,
    );

    return {
      newProductState,
      newFormikMainProducts: formikMainProducts,
      newFormikAdditionalProducts: [],
      newFormikTerms: formikTerms,
      newFormikQuoteConfig: EmptyFormikQuote.quoteConfig,
    };
  }

  // A new quote config was selected
  if (quoteConfigID !== undefined) {
    // Construct a new state only using values inside the formikMainProducts
    const newProductState: Record<number, Product | null> = Object.entries(curProductState).reduce(
      (acc: Record<number, Product | null>, [k, qp]) => {
        if (Number(k) <= formikMainProducts.length) {
          const numKey = Number(k);
          return {
            ...acc,
            [numKey]: qp,
          };
        }
        return acc;
      },
      {} as Record<number, Product | null>,
    );

    // Then append the new additional products from the quote config
    const newQuoteConfig =
      quoteConfigList.items.find((qc) => qc.id === quoteConfigID) ?? QuoteConfig.zero();
    const newQuoteConfigProducts = newQuoteConfig.products;
    const newFormikAdditionalProducts = newQuoteConfigProducts.map(
      QuoteConfigProductToQuoteProductFormik,
    );

    const curProductLength = formikMainProducts.length;
    newQuoteConfigProducts.forEach((qcp, idx) => {
      const newIdx = curProductLength + idx;
      newProductState[newIdx] = qcp.product;
    });

    return {
      newProductState,
      newFormikMainProducts: formikMainProducts,
      newFormikAdditionalProducts,
      newFormikTerms: newQuoteConfig.terms,
      newFormikQuoteConfig: {
        id: newQuoteConfig.id,
        option: {
          label: newQuoteConfig.name,
          value: newQuoteConfig.id,
        },
      },
    };
  }

  // Theoretically unreachable state (by developers), so alert but let existing values through.
  LogRocket.error('Reached an unexpected return state in QuoteDrawer -> HandleProductState');
  return {
    newProductState: curProductState,
    newFormikMainProducts: formikMainProducts,
    newFormikAdditionalProducts: formikAdditionalProducts,
    newFormikTerms: formikTerms,
    newFormikQuoteConfig: formikQuoteConfig,
  };
};

export type QuoteSection =
  | 'Quote Information*'
  | 'Buyer Information'
  | 'Products'
  | 'Additional services'
  | 'Price Escalations'
  | 'Terms';

const QuoteInformationSection = ({ curProductsRef }: ProductsSectionProps): JSX.Element => {
  const ctx = useContext(SlabContext);
  const formikBag = useFormikContext<QuoteFormikType>();
  // This ref flag will short-circuit the Project useEffect. If an end user
  // remounts this section by selecting a new step, it would otherwise consider a change to have happened.
  const changeDetected = useRef(false);

  const {
    isLoading: isLoadingSalespeople,
    isError: isErrorSalespeople,
    data: salespersonList,
  } = useSlabQuery('GET users', {
    queryParams: {
      filterBy: [
        {
          operation: Enums.FilterOperation.Equals,
          name: 'isSalesperson',
          value: true,
        },
      ],
      // HACK: don't paginate; API treats 0 limits the same way as unset
      perPage: 0,
      page: 0,
    },
  });

  const {
    isLoading: isLoadingProject,
    isError: isErrorProject,
    data: project,
  } = useSlabQuery(
    'GET project by ID',
    { pathParams: { id: formikBag.values.project.id ?? '' } },
    { enabled: formikBag.values.project.id !== null },
  );

  const {
    isLoading: isLoadingQuoteConfigs,
    isError: isErrorQuoteConfigs,
    data: quoteConfigList,
  } = useSlabQuery('GET quote configs', {});

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET quote statuses', {});

  const {
    isLoading: isLoadingQuotePolicies,
    isError: isErrorQuotePolicies,
    data: quotePolicies,
  } = useSlabQuery('GET quote policies', {});

  const {
    isLoading: isLoadingProjectProducts,
    isError: isErrorProjectProducts,
    data: projectProducts,
  } = useSlabQuery(
    'GET project products by project ID',
    {
      pathParams: {
        id: formikBag.values.project.id ?? '',
      },
    },
    { enabled: formikBag.values.project.id !== null },
  );

  const isDataUndefined =
    salespersonList === undefined ||
    quoteConfigList === undefined ||
    statusList === undefined ||
    quotePolicies === undefined;

  // Handle the automatically set values based on a changing project ID.
  useEffect(() => {
    if (!changeDetected.current) {
      return;
    }
    if (isDataUndefined || isLoadingProject || isLoadingProjectProducts) {
      return;
    }
    changeDetected.current = false;
    // If the project ID is null, it is always safe to set the form to the cleared values.
    if (formikBag.values.project.id === null && !isLoadingProject && !isLoadingProjectProducts) {
      const {
        newFormikAdditionalProducts,
        newFormikMainProducts,
        newFormikTerms,
        newProductState,
        newFormikQuoteConfig,
      } = HandleProductState({
        curProductState: curProductsRef.current,
        formikAdditionalProducts: formikBag.values.additionalProducts,
        formikMainProducts: formikBag.values.mainProducts,
        formikTerms: formikBag.values.terms,
        formikQuoteConfig: formikBag.values.quoteConfig,
        project: undefined,
        quoteConfigList,
        projectID: null,
      });

      SetFormikValue(formikBag, 'quoteConfig', newFormikQuoteConfig);
      SetFormikValue(formikBag, 'mainProducts', newFormikMainProducts);
      SetFormikValue(formikBag, 'additionalProducts', newFormikAdditionalProducts);
      SetFormikValue(formikBag, 'terms', newFormikTerms);

      curProductsRef.current = newProductState;

      const { company, contact } = onProjectChange(undefined);
      SetFormikValue(formikBag, 'company', company);
      SetFormikValue(formikBag, 'contact', contact);
      return;
    }

    // A project has been selected, so once Project and ProjectProducts are loaded, adjust form/state values.
    if (formikBag.dirty && project !== undefined && projectProducts !== undefined) {
      const filledProject = NewProject({
        ...project,
        products: projectProducts,
      });

      const {
        newFormikAdditionalProducts,
        newFormikMainProducts,
        newFormikTerms,
        newFormikQuoteConfig,
        newProductState,
      } = HandleProductState({
        curProductState: curProductsRef.current,
        formikAdditionalProducts: formikBag.values.additionalProducts,
        formikMainProducts: formikBag.values.mainProducts,
        formikTerms: formikBag.values.terms,
        formikQuoteConfig: formikBag.values.quoteConfig,
        project: filledProject,
        quoteConfigList,
        projectID: formikBag.values.project.id,
      });

      SetFormikValue(formikBag, 'quoteConfig', newFormikQuoteConfig);
      SetFormikValue(formikBag, 'mainProducts', newFormikMainProducts);
      SetFormikValue(formikBag, 'additionalProducts', newFormikAdditionalProducts);
      SetFormikValue(formikBag, 'terms', newFormikTerms);

      curProductsRef.current = newProductState;

      const { company, contact } = onProjectChange(project);
      SetFormikValue(formikBag, 'company', company);
      SetFormikValue(formikBag, 'contact', contact);
    }
  }, [changeDetected.current, project, projectProducts]);

  const isLoading =
    isLoadingSalespeople || isLoadingQuoteConfigs || isLoadingStatuses || isLoadingQuotePolicies;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError =
    isErrorSalespeople ||
    isErrorProject ||
    isErrorProjectProducts ||
    isErrorQuoteConfigs ||
    isErrorQuotePolicies ||
    isErrorStatuses;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const userLookups = lookups({
    items: salespersonList.items,
    label: (u: User) => u.fullName(),
    count: salespersonList.items.length,
  });

  const initialStatus = statusList.items.find(
    (status) => status.id === formikBag.initialValues.status.id,
  );
  const possibleDestinationStatuses = statusList.items.filter((status) =>
    (initialStatus ?? QuoteStatus.zero()).canTransitionTo(status),
  );

  const filteredStatusLookups = lookups({
    items: possibleDestinationStatuses,
    count: possibleDestinationStatuses.length,
    label: (cqs: QuoteStatus) => cqs.name,
    compare: null,
  });
  const allStatusLookups = lookups({
    ...statusList,
    label: (qs: QuoteStatus) => qs.name,
    compare: null,
  });

  const quoteConfigDisabled = !isValidReference(formikBag.values.project);
  const quoteConfigLabel = !isValidReference(formikBag.values.project)
    ? 'Select a project'
    : 'Configuration template';

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='name' label='Name' />
        </Grid>
        <Grid item xs={6}>
          <Input name='revisionNumber' label='Revision' disabled />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='creationDate' label='Creation date' type='date' />
        </Grid>
        <Grid item xs={6}>
          <Input name='revisionDate' label='Revision date' type='date' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='expirationDate' label='Expiration date' type='date' />
        </Grid>
        <Grid item xs={6}>
          <InputDropdown
            name='status.id'
            label='Status*'
            options={
              getTenantRequiresApprovals({ tenant: ctx.userInfo.tenant, quotePolicies })
                ? filteredStatusLookups
                : allStatusLookups
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputDropdown name='user.id' label='Salesperson*' options={userLookups} />
        </Grid>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            name='project'
            label='Project*'
            route={{
              barrel: 'GET project summaries',
              args: (inputText): QueryRouteBarrelTypes['GET project summaries']['args'] => {
                const args = ConstructListQueryParams(inputText);
                const filterBy: ListURLParams<ProjectSummary>['filterBy'] = [
                  ...(args.queryParams?.filterBy ?? []),
                  {
                    name: 'isArchived',
                    operation: Enums.FilterOperation.Equals,
                    value: false,
                  },
                ];
                return {
                  ...args,
                  queryParams: {
                    ...args.queryParams,
                    filterBy,
                  },
                };
              },
              transform: (pl): LookupInputOption[] =>
                lookups({
                  ...pl,
                  label: (p) => p.name,
                }),
            }}
            onMatchChange={(): void => {
              changeDetected.current = true;
            }}
            tip='Selecting a project will update the current products based on those project products.'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocalLookupInput
            formState={formikBag.values}
            name='quoteConfig'
            label={quoteConfigLabel}
            disabled={quoteConfigDisabled}
            options={quoteConfigLookups({
              project,
              quoteConfigList,
            })}
            tip={
              quoteConfigDisabled
                ? undefined
                : 'Selecting a configuration will update the current additional services and terms sections.'
            }
            onMatchChange={(quoteConfigID): void => {
              const { newFormikAdditionalProducts, newFormikTerms, newProductState } =
                HandleProductState({
                  curProductState: curProductsRef.current,
                  formikAdditionalProducts: formikBag.values.additionalProducts,
                  formikMainProducts: formikBag.values.mainProducts,
                  formikTerms: formikBag.values.terms,
                  formikQuoteConfig: formikBag.values.quoteConfig,
                  project,
                  quoteConfigList,

                  quoteConfigID,
                });

              SetFormikValue(formikBag, 'additionalProducts', newFormikAdditionalProducts);
              SetFormikValue(formikBag, 'terms', newFormikTerms);

              curProductsRef.current = newProductState;
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BuyerInformationSection = (): JSX.Element => {
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const formikBag = useFormikContext<QuoteFormikType>();

  const companyDisabled = !isValidReference(formikBag.values.project);
  const companyLabel = companyDisabled ? 'Select a project' : 'Company';

  const contactDisabled = !isValidReference(formikBag.values.company);
  const contactLabel = ((): string => {
    if (companyDisabled) {
      return 'Select a project';
    }
    if (contactDisabled) {
      return 'Select a company';
    }
    return 'Contact';
  })();

  return (
    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ApiLookupInput
            formState={formikBag.values}
            name='company'
            label={companyLabel}
            disabled={companyDisabled}
            route={{
              barrel: 'GET project companies by project ID',
              args: () => ({
                pathParams: {
                  id: formikBag.values.project.id ?? '',
                },
              }),
              transform: (pcs): LookupInputOption[] => {
                const contractors = pcs.filter(
                  (pc) => pc.company.category === Enums.CompanyCategory.Contractor,
                );
                return lookups({
                  items: contractors.map((pc) => pc.company),
                  label: (pc) => pc.name,
                  sublabels: (pc) => pc.lookupSublabels(usesDispatchCustomer),
                  count: contractors.length,
                });
              },
              options: {
                enabled: formikBag.values.project.id !== null,
              },
            }}
            onMatchChange={(newCompanyID, pcs): void => {
              const newProjectCompany = (pcs ?? []).find((pc) => pc.company.id === newCompanyID);
              if (newProjectCompany === undefined) {
                SetFormikValue(formikBag, 'contact', EmptyFormikQuote.contact);
                return;
              }
              if (newProjectCompany.contact !== null) {
                const formikContact: QuoteFormikType['contact'] = {
                  id: newProjectCompany.contact.id,
                  option: {
                    value: newProjectCompany.contact.id,
                    label: newProjectCompany.contact.fullName(),
                  },
                };
                SetFormikValue(formikBag, 'contact', formikContact);
              }
              // New project company has no contact, so do not pre-fill it.
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ApiLookupInput
            formState={formikBag.values}
            name='contact'
            label={contactLabel}
            disabled={contactDisabled}
            route={{
              barrel: 'GET contacts',
              args: (inputText) => ({
                queryParams: {
                  page: 0,
                  perPage: DEFAULT_LOOKUP_LENGTH,
                  filterBy: [
                    {
                      name: 'companyId',
                      operation: Enums.FilterOperation.Equals,
                      value: formikBag.values.company?.id ?? '',
                    },
                    {
                      operation: Enums.FilterOperation.Lookup,
                      value: inputText,
                    },
                  ],
                },
              }),
              transform: (cl): LookupInputOption[] =>
                lookups({
                  ...cl,
                  items: cl.items,
                  label: (c) => c.fullName(),
                }),
              options: {
                enabled: formikBag.values.company !== null && formikBag.values.company.id !== null,
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProductsSection = ({ curProductsRef }: ProductsSectionProps): JSX.Element => {
  const formikBag = useFormikContext<QuoteFormikType>();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isErrorProject,
  } = useSlabQuery(
    'GET project by ID',
    {
      pathParams: {
        id: formikBag.values.project.id ?? '',
      },
    },
    {
      enabled: formikBag.values.project.id !== null,
    },
  );

  const {
    isLoading: isLoadingProjectProducts,
    isError: isErrorProjectProducts,
    data: projectProducts,
  } = useSlabQuery(
    'GET project products by project ID',
    {
      pathParams: {
        id: formikBag.values.project.id ?? '',
      },
    },
    {
      enabled: formikBag.values.project.id !== null,
    },
  );

  const projectProductsByProductID = useMemo(() => {
    if (formikBag.values.project.id === null) {
      return {};
    }
    const byProductID: Record<string, ProjectProduct> = {};
    projectProducts?.forEach((pp) => {
      byProductID[pp.product.id] = pp;
    });
    return byProductID;
  }, [projectProducts]);

  if (formikBag.values.project.id === null) {
    return (
      <Box>
        <Typography variant='h5'>Please select a project.</Typography>
      </Box>
    );
  }

  if (isLoadingProject || isLoadingProjectProducts || isRefreshing) {
    return <LoadingSpinner />;
  }

  if (
    isErrorProject ||
    project === undefined ||
    isErrorProjectProducts ||
    projectProducts === undefined
  ) {
    return (
      <Box>
        <Typography variant='h5'>Error loading products.</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Tooltip
          enterDelay={200}
          title='Clears the current products and reloads from project'
          placement='top-start'
          arrow
        >
          <Button
            onClick={(): void => {
              setIsRefreshing(true);
              const newFormikMainProducts = projectProducts.map(ProjectProductToQuoteProductFormik);
              SetFormikValue(formikBag, 'mainProducts', newFormikMainProducts);
              setIsRefreshing(false);
            }}
          >
            <Cached />
          </Button>
        </Tooltip>
      </Box>

      <ProductSection
        sectionType={Enums.QuoteProductKind.Primary}
        plant={project.plant}
        project={project}
        curProductsRef={curProductsRef}
        projectProductsByProductID={projectProductsByProductID}
      />
    </>
  );
};

/** If there is no selected quote config, everything is considered synced. */
export const isQuoteConfigSynced = (
  curQuoteConfig: QuoteConfig | undefined,
  formikAdditionalProducts: QuoteFormikType['additionalProducts'],
  formikTerms: QuoteFormikType['terms'],
): { areTermsSynced: boolean; areProductsSynced: boolean } => {
  if (curQuoteConfig === undefined) {
    return {
      areProductsSynced: true,
      areTermsSynced: true,
    };
  }

  const areProductsSynced =
    curQuoteConfig.products.length === formikAdditionalProducts.length &&
    curQuoteConfig.products.every((qcp) => {
      const matchingProduct = formikAdditionalProducts.find(
        (ap) => ap.product.id === qcp.product.id,
      );
      return (
        matchingProduct !== undefined &&
        matchingProduct.price.number !== '' &&
        Number(matchingProduct.price.number.replaceAll(',', '')) ===
          Number(qcp.product.listPrice?.number?.replaceAll(',', '') ?? '0.00')
      );
    });

  return {
    areProductsSynced,
    areTermsSynced: curQuoteConfig.terms === formikTerms,
  };
};

const AdditionalServicesSection = ({ curProductsRef }: ProductsSectionProps): JSX.Element => {
  const formikBag = useFormikContext<QuoteFormikType>();

  const {
    isLoading: isLoadingQuoteConfigs,
    isError: isErrorQuoteConfigs,
    data: quoteConfigList,
  } = useSlabQuery('GET quote configs', {});

  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isErrorProject,
  } = useSlabQuery(
    'GET project by ID',
    {
      pathParams: {
        id: formikBag.values.project.id ?? '',
      },
    },
    {
      enabled: formikBag.values.project.id !== null,
    },
  );

  const {
    isLoading: isLoadingProjectProducts,
    isError: isErrorProjectProducts,
    data: projectProducts,
  } = useSlabQuery(
    'GET project products by project ID',
    {
      pathParams: {
        id: formikBag.values.project.id ?? '',
      },
    },
    {
      enabled: formikBag.values.project.id !== null,
    },
  );

  const projectProductsByProductID = useMemo(() => {
    if (formikBag.values.project.id === null) {
      return {};
    }
    const byProductID: Record<string, ProjectProduct> = {};
    projectProducts?.forEach((pp) => {
      byProductID[pp.product.id] = pp;
    });
    return byProductID;
  }, [projectProducts]);

  if (formikBag.values.project.id === null) {
    return (
      <Box>
        <Typography variant='h5'>Please select a project.</Typography>
      </Box>
    );
  }

  const isLoading = isLoadingQuoteConfigs || isLoadingProject || isLoadingProjectProducts;
  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorQuoteConfigs || isErrorProject || isErrorProjectProducts;
  const dataUndefined = project === undefined || projectProducts === undefined;
  if (isError || dataUndefined) {
    return (
      <Box>
        <Typography variant='h5'>Error.</Typography>
      </Box>
    );
  }

  const curQuoteConfig = quoteConfigList?.items.find(
    (qc) => qc.id === formikBag.values.quoteConfig?.id,
  );
  const { areProductsSynced } = isQuoteConfigSynced(
    curQuoteConfig,
    formikBag.values.additionalProducts,
    formikBag.values.terms,
  );
  const tip = ((): JSX.Element | null => {
    if (areProductsSynced) {
      return null;
    }
    return (
      <Box pb='0.5rem'>
        <Typography variant='body3'>
          Current additional services are out-of-sync with selected quote configuration.
        </Typography>
      </Box>
    );
  })();

  return (
    <>
      {tip}
      <ProductSection
        sectionType={Enums.QuoteProductKind.Additional}
        plant={project.plant}
        project={project}
        curProductsRef={curProductsRef}
        projectProductsByProductID={projectProductsByProductID}
      />
    </>
  );
};

const TermsSection = (): JSX.Element => {
  const formikBag = useFormikContext<QuoteFormikType>();

  const {
    isLoading: isLoadingQuoteConfigs,
    isError: isErrorQuoteConfigs,
    data: quoteConfigList,
  } = useSlabQuery('GET quote configs', {});

  if (isLoadingQuoteConfigs) {
    return <LoadingSpinner />;
  }

  if (isErrorQuoteConfigs) {
    return (
      <Box>
        <Typography variant='h5'>Error loading terms.</Typography>
      </Box>
    );
  }

  const curQuoteConfig = quoteConfigList?.items.find(
    (qc) => qc.id === formikBag.values.quoteConfig?.id,
  );
  const { areTermsSynced } = isQuoteConfigSynced(
    curQuoteConfig,
    formikBag.values.additionalProducts,
    formikBag.values.terms,
  );
  const tip = ((): JSX.Element | null => {
    if (areTermsSynced) {
      return null;
    }
    return (
      <Box pb='0.5rem'>
        <Typography variant='body3'>
          Current terms are out-of-sync with selected quote configuration.
        </Typography>
      </Box>
    );
  })();

  return (
    <Box>
      {tip}
      <Input type='textarea' name='terms' />
    </Box>
  );
};

const steps: SlabStep<QuoteFormikType>[] = [
  {
    label: 'Quote Information*',
    content: ({ curProductsRef }) => <QuoteInformationSection curProductsRef={curProductsRef} />,
    maybeErrorFieldNames: ['status', 'user', 'project'],
  },
  {
    label: 'Buyer Information',
    content: BuyerInformationSection,
    maybeErrorFieldNames: [],
  },
  {
    label: 'Products',
    content: ({ curProductsRef }) => <ProductsSection curProductsRef={curProductsRef} />,
    maybeErrorFieldNames: ['mainProducts'],
  },
  {
    label: 'Additional services',
    content: ({ curProductsRef }) => <AdditionalServicesSection curProductsRef={curProductsRef} />,
    maybeErrorFieldNames: ['additionalProducts'],
  },
  {
    label: 'Price Escalations',
    content: PriceEscalationSection,
    maybeErrorFieldNames: ['priceEscalations'],
  },
  {
    label: 'Terms',
    content: TermsSection,
    maybeErrorFieldNames: ['terms'],
  },
];

type QuoteDrawerSectionsProps = {
  initialSection: QuoteSection;
  curProductsRef: ProductSectionProps['curProductsRef'];
};

export const QuoteDrawerSections = ({
  initialSection,
  curProductsRef,
}: QuoteDrawerSectionsProps): JSX.Element => {
  const formikBag = useFormikContext<QuoteFormikType>();

  const initialStep = steps.map((s) => s.label).indexOf(initialSection);

  const stepStateHook = useState(initialStep);
  const [, setActiveStep] = stepStateHook;

  // If the initialSection changes, we use this hook to update the state
  useEffect(() => {
    const newInitialStep = steps.map((s) => s.label).indexOf(initialSection);
    setActiveStep(newInitialStep);
  }, [initialSection]);

  return (
    <SlabStepper
      steps={steps.map((s) => ({
        ...s,
        content: () =>
          s.content({
            curProductsRef,
          }),
      }))}
      activeStepHook={stepStateHook}
      isStepDisabled={(step: SlabStep<QuoteFormikType>): boolean =>
        (step.label === 'Products' ||
          step.label === 'Additional services' ||
          step.label === 'Buyer Information') &&
        formikBag.values.project.id === null
      }
    />
  );
};
