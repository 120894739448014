import { QuotePolicy } from '../generated-types/QuotePolicy/QuotePolicy';
import { Tenant } from '../generated-types/Tenant/Tenant';
import { List } from './List';

export type GetTenantRequiresApprovalsParams = {
  tenant: Tenant;
  quotePolicies: List<QuotePolicy> | undefined;
};

export const getTenantRequiresApprovals = ({
  tenant,
  quotePolicies,
}: GetTenantRequiresApprovalsParams): boolean => {
  if (quotePolicies === undefined) {
    return tenant.requiresQuoteApprovals;
  }
  if (tenant.requiresQuoteApprovals && quotePolicies.count > 0) {
    return true;
  }

  return false;
};
