import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SlabRoute } from '../../SlabRoutes';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from './components/styles';

interface AsideOptionProps {
  route: SlabRoute;
  isOpen: boolean;
  isMobile: boolean;
  closeDrawer(): void;
}

const NavOption: FC<AsideOptionProps> = ({ route, isOpen, closeDrawer, isMobile }) => {
  const theme = useTheme();
  const location = useLocation();

  const navigate = useNavigate();

  const isActive = route.path === `/${location.pathname.split('/')[1]}`;

  const handleOptionClick = (): void => {
    if (isMobile) closeDrawer();

    navigate(route.path);
  };

  if (route.icon === undefined || route?.icon?.() === null) return null;

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleOptionClick} isOpen={isOpen}>
        <ListItemIcon>
          {route.icon(isActive ? theme.palette.SlabYellow[500] : theme.palette.SlabGray['500'])}
        </ListItemIcon>
        <ListItemText isActive={isActive} primary={route.name} isOpen={isOpen} />

        {isActive && (
          <Box
            sx={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
            right={0}
            bottom={0}
            top={0}
            position='absolute'
            height='100%'
            bgcolor={theme.palette.SlabYellow[500]}
            width='3px'
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default NavOption;
