import {
  Box1,
  Briefcase,
  Brush3,
  Buildings,
  Buildings2,
  Calendar,
  Chart,
  DeviceMessage,
  Element3,
  I3Square,
  Notepad,
  Notepad2,
  ReceiptItem,
  UserSquare,
} from 'iconsax-react';

import { ErrorPage } from './components/Error/ErrorPage';
import Enums from './generated-types/Enums';
import { UserInfo } from './generated-types/UserInfo/UserInfo';
import { ActivitiesList } from './pages/Activities/ActivitiesList';
import { CompanyDetailsPage } from './pages/Companies/CompanyDetails';
import { CompanyList } from './pages/Companies/CompanyList';
import { ContactDetailsPage } from './pages/Contacts/ContactDetails';
import { ContactList } from './pages/Contacts/ContactList';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { ForecastList } from './pages/Forecasts/ForecastList';
import { MaterialDetailsPage } from './pages/Materials/MaterialDetails';
import { MaterialList } from './pages/Materials/MaterialList';
import { MixDetailsPage } from './pages/MixDesigns/MixDesignDetails';
import { MixList } from './pages/MixDesigns/MixDesignList';
import { NylasAccountManagementPage } from './pages/Nylas/NylasAccountManagement';
import { PlantDetailsPage } from './pages/Plants/PlantDetails';
import { PlantList } from './pages/Plants/PlantList';
import { ProductDetailsPage } from './pages/Products/ProductDetails';
import { ProductList } from './pages/Products/ProductList';
import { ProjectConfigDetailsPage } from './pages/ProjectConfigs/ProjectConfigDetails';
import { ProjectConfigList } from './pages/ProjectConfigs/ProjectConfigList';
import { ProjectDetailsPage } from './pages/Projects/ProjectDetails';
import { ProjectList } from './pages/Projects/ProjectList';
import { QuoteConfigDetailsPage } from './pages/QuoteConfigs/QuoteConfigDetails';
import { QuoteConfigList } from './pages/QuoteConfigs/QuoteConfigList';
import { QuoteDetailsPage } from './pages/Quotes/QuoteDetails';
import { QuoteList } from './pages/Quotes/QuoteList';
import { withSlabErrorBoundary } from './utils/withSlabErrorBoundary';

export type SlabRoute = {
  /** Role permissions required to access this route. */
  requiredScopes: Enums.DomainType[];
  /** User roles which grant a user access to this route: a user must have
   *  at least one. An undefined value is equivalent to listing all roles.
   */
  allowedRoles?: Enums.RoleName[];
  /** URL path (excluding hostname) */
  path: string;
  /** The element rendered at that route */
  main: () => JSX.Element;
  /** Icon to display (if it is in the NavBar) */
  icon?: (color?: string) => JSX.Element | null;
  /** Name to display (if it is in the NavBar) */
  name?: string;
  /** Feature flags required to access this route. */
  requiredFlags?: Enums.FeatureFlagName[];
};

export type SlabRoutesSection = {
  name: string;
  children: SlabRoute[];
};

// All possible routes allowed within our frontend app
const UnboundSlabRoutes: SlabRoutesSection[] = [
  {
    name: 'Dashboard',
    children: [
      {
        requiredScopes: [Enums.DomainType.Dashboards],
        path: '/',
        name: 'Dashboard',
        main: (): JSX.Element => <Dashboard title='CRM' kind={Enums.DashboardKind.CRM} />,
      },
      {
        requiredScopes: [Enums.DomainType.Dashboards],
        path: '/dashboard',
        icon: (color?: string): JSX.Element => <Element3 variant='TwoTone' color={color} />,
        name: 'Dashboard',
        main: (): JSX.Element => <Dashboard title='CRM' kind={Enums.DashboardKind.CRM} />,
      },
      {
        requiredScopes: [Enums.DomainType.Projects],
        path: '/projects',
        icon: (color?: string): JSX.Element => <Briefcase variant='TwoTone' color={color} />,
        name: 'Projects',
        main: (): JSX.Element => <ProjectList />,
      },
      {
        requiredScopes: [Enums.DomainType.Projects],
        path: '/projects/:id',
        main: (): JSX.Element => <ProjectDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Quotes],
        path: '/quotes',
        icon: (color?: string): JSX.Element => <ReceiptItem variant='TwoTone' color={color} />,
        name: 'Quotes',
        main: (): JSX.Element => <QuoteList />,
      },
      {
        requiredScopes: [Enums.DomainType.Quotes],
        path: '/quotes/:id',
        main: (): JSX.Element => <QuoteDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Forecasts],
        path: '/forecasts',
        icon: (color?: string): JSX.Element => <Chart variant='TwoTone' color={color} />,
        name: 'Forecasts',
        main: (): JSX.Element => <ForecastList />,
      },
      {
        requiredScopes: [Enums.DomainType.Companies],
        path: '/companies',
        icon: (color?: string): JSX.Element => <Buildings variant='TwoTone' color={color} />,
        name: 'Companies',
        main: (): JSX.Element => <CompanyList />,
      },
      {
        requiredScopes: [Enums.DomainType.Companies],
        path: '/companies/:id',
        main: (): JSX.Element => <CompanyDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Contacts],
        path: '/contacts',
        icon: (color?: string): JSX.Element => <UserSquare variant='TwoTone' color={color} />,
        name: 'Contacts',
        main: (): JSX.Element => <ContactList />,
      },
      {
        requiredScopes: [Enums.DomainType.Contacts],
        path: '/contacts/:id',
        main: (): JSX.Element => <ContactDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Activities],
        path: '/activities',
        icon: (color?: string): JSX.Element => <Calendar variant='TwoTone' color={color} />,
        name: 'Activities',
        main: (): JSX.Element => <ActivitiesList />,
      },
      {
        requiredScopes: [Enums.DomainType.Dashboards],
        path: '/reporting',
        icon: (color?: string): JSX.Element => <Element3 variant='TwoTone' color={color} />,
        name: 'Reporting',
        main: (): JSX.Element => (
          <Dashboard title='Reporting' kind={Enums.DashboardKind.Reporting} />
        ),
      },
    ],
  },
  {
    name: 'Database',
    children: [
      {
        requiredScopes: [Enums.DomainType.Dashboards],
        path: '/database',
        icon: (color?: string): JSX.Element => <Element3 variant='TwoTone' color={color} />,
        name: 'Database',
        main: (): JSX.Element => <Dashboard title='Database' kind={Enums.DashboardKind.Database} />,
      },
      {
        requiredScopes: [Enums.DomainType.Products],
        path: '/products',
        icon: (color?: string): JSX.Element => <Box1 variant='TwoTone' color={color} />,
        name: 'Products',
        main: (): JSX.Element => <ProductList />,
      },
      {
        requiredScopes: [Enums.DomainType.Products],
        path: '/products/:id',
        main: (): JSX.Element => <ProductDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Mixes],
        path: '/mixdesigns',
        icon: (color?: string): JSX.Element => <I3Square variant='TwoTone' color={color} />,
        name: 'Mix Designs',
        main: (): JSX.Element => <MixList />,
      },
      {
        requiredScopes: [Enums.DomainType.Mixes],
        path: '/mixdesigns/:id',
        main: (): JSX.Element => <MixDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Materials],
        path: '/materials',
        icon: (color?: string): JSX.Element => <Brush3 variant='TwoTone' color={color} />,
        name: 'Materials',
        main: (): JSX.Element => <MaterialList />,
      },
      {
        requiredScopes: [Enums.DomainType.Materials],
        path: '/materials/:id',
        main: (): JSX.Element => <MaterialDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Plants],
        path: '/plants',
        icon: (color?: string): JSX.Element => <Buildings2 variant='TwoTone' color={color} />,
        name: 'Locations',
        main: (): JSX.Element => <PlantList />,
      },
      {
        requiredScopes: [Enums.DomainType.Plants],
        path: '/plants/:id',
        main: (): JSX.Element => <PlantDetailsPage />,
      },
    ],
  },
  {
    name: 'Admin',
    children: [
      {
        requiredScopes: [Enums.DomainType.Dashboards],
        allowedRoles: [Enums.RoleName.SlabstackAdministrator, Enums.RoleName.ITAdministrator],
        path: '/admin',
        icon: (color?: string): JSX.Element => <Element3 variant='TwoTone' color={color} />,
        name: 'Admin',
        main: (): JSX.Element => <Dashboard title='Admin' kind={Enums.DashboardKind.Admin} />,
      },
      {
        requiredScopes: [Enums.DomainType.ProjectConfigs],
        path: '/projectconfigs',
        icon: (color?: string): JSX.Element | null => <Notepad variant='TwoTone' color={color} />,
        name: 'Project Configs',
        main: (): JSX.Element => <ProjectConfigList />,
      },
      {
        requiredScopes: [Enums.DomainType.ProjectConfigs],
        path: '/projectconfigs/:id',
        main: (): JSX.Element => <ProjectConfigDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.QuoteConfigs],
        path: '/quoteconfigs',
        icon: (color?: string): JSX.Element | null => <Notepad2 variant='TwoTone' color={color} />,
        name: 'Quote Configs',
        main: (): JSX.Element => <QuoteConfigList />,
      },
      {
        requiredScopes: [Enums.DomainType.QuoteConfigs],
        path: '/quoteconfigs/:id',
        main: (): JSX.Element => <QuoteConfigDetailsPage />,
      },
      {
        requiredScopes: [Enums.DomainType.Quotes],
        path: '/emailconfig',
        icon: (color?: string): JSX.Element => <DeviceMessage variant='TwoTone' color={color} />,
        name: 'Email Config',
        main: (): JSX.Element => <NylasAccountManagementPage />,
      },
    ],
  },
];

export const BoundSlabRoutes = (userInfo: UserInfo): SlabRoutesSection[] =>
  UnboundSlabRoutes.map((section) => {
    const enabledFlagNames = userInfo.featureFlags
      .filter((flag) => flag.isEnabled)
      .map((flag) => flag.name);
    return {
      name: section.name,
      children: section.children
        .filter((route) =>
          route.requiredScopes.every((requiredScope) =>
            userInfo.readableDomainTypes.includes(requiredScope),
          ),
        )
        .filter((route) =>
          (route.requiredFlags ?? []).every((requiredFlag) =>
            enabledFlagNames.includes(requiredFlag),
          ),
        )
        .filter(
          (route) =>
            route.allowedRoles === undefined ||
            route.allowedRoles.length === 0 ||
            route.allowedRoles.some((allowedRole) => userInfo.hasRoles([allowedRole])),
        )
        .map((route) => {
          const BoundMain = withSlabErrorBoundary(route.main, { FallbackComponent: ErrorPage });
          return {
            ...route,
            main: () => <BoundMain />,
          };
        }),
    };
  })
    // After scope-filtering, remove any now-empty sections.
    .filter((section) => section.children.length > 0);
