import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { SlabStep, SlabStepper } from '../../../components/SlabStepper/SlabStepper';
import { Material } from '../../../generated-types/Material/Material';
import { NullableCostString } from '../../../utils/Currency';
import { lookups } from '../../../utils/DomainHelpers';
import { MixDesignFormikType } from '../MixDesignFormik';
import { DynamicMaterialLookup } from './DynamicMaterialLookup';

/** Convert a Material + quantity into a formik-friendly mixMaterial. */
export const materialToFormikMixMaterial = (
  material: Material,
  quantity: string,
): MixDesignFormikType['mixMaterials'][number] => ({
  material: {
    id: material.id,
    option: {
      value: material.id,
      label: material.name,
    },
  },
  quantity,
  materialType: material.materialType,
  batchUnit: material.rawMaterialCost.unit,
  displayOnly: {
    inProductCost: NullableCostString({ cost: material.inProductCost }),
    materialName: material.name,
  },
});

const MixDesignDetailsSection = (): JSX.Element => {
  const formikBag = useFormikContext<MixDesignFormikType>();

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='alternateID' label='Mix ID*' />
        </Grid>
        <Grid item xs={6}>
          <Input name='name' label='Name*' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='psi' label='PSI*' type='number' />
        </Grid>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            name='plant'
            label='Location*'
            route={{
              barrel: 'GET plants',
              args: ConstructListQueryParams,
              transform: (plantList): LookupInputOption[] =>
                lookups({
                  ...plantList,
                  label: (p) => p.name,
                }),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='slump' label='Slump' type='number' />
        </Grid>
        <Grid item xs={6}>
          <Input name='aggregateSize' label='Aggregate size' type='text' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            name='waterCementRatio'
            label='Water cement ratio'
            type='number'
            endAdornment='%'
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='flyAshPercentage'
            label='Fly ash percentage'
            type='number'
            endAdornment='%'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            name='materialCostOverride'
            label='Cost override'
            type='currency'
            startAdornment='$'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const MixDesignMaterialsSection = (): JSX.Element => {
  const formikBag = useFormikContext<MixDesignFormikType>();

  if (formikBag.values.plant.id === null) {
    return (
      <Box>
        <Typography>Please select a plant.</Typography>
      </Box>
    );
  }

  return <DynamicMaterialLookup />;
};

export type MixDesignLabelOpts = 'Mix design details*' | 'Materials';

const steps: SlabStep<MixDesignFormikType>[] = [
  {
    label: 'Mix design details*',
    content: MixDesignDetailsSection,
    maybeErrorFieldNames: ['alternateID', 'name', 'psi', 'plant'],
  },
  {
    label: 'Materials',
    content: MixDesignMaterialsSection,
    maybeErrorFieldNames: ['mixMaterials'],
  },
];

type MixDesignDrawerSectionsProps = {
  initialSection: MixDesignLabelOpts;
};

export const MixDesignDrawerSections = ({
  initialSection,
}: MixDesignDrawerSectionsProps): JSX.Element => {
  const formikBag = useFormikContext<MixDesignFormikType>();

  const initialStep = steps.map((s) => s.label).indexOf(initialSection);

  const stepStateHook = useState(initialStep);
  const [, setActiveStep] = stepStateHook;

  // If the initialSection changes, we use this hook to update the state
  useEffect(() => {
    const newInitialStep = steps.map((s) => s.label).indexOf(initialSection);
    setActiveStep(newInitialStep);
  }, [initialSection]);

  return (
    <Box paddingTop='0.75rem'>
      <SlabStepper
        steps={steps}
        activeStepHook={stepStateHook}
        isStepDisabled={(step: SlabStep<MixDesignFormikType>): boolean =>
          step.label !== 'Mix design details*' && formikBag.values.plant.id === null
        }
      />
    </Box>
  );
};
