import { Box, Typography } from '@mui/material';

import { FormatLinkCell } from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithoutAggregationsParams,
  useApiTableDataWithoutAggregations,
} from '../../../components/DataTable/useApiTableData';
import Enums from '../../../generated-types/Enums';
import { Material } from '../../../generated-types/Material/Material';
import { MaterialSummary } from '../../../generated-types/MaterialSummary/MaterialSummary';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { NestedKeyOf } from '../../../utils/Types';

const columns: ColumnConfig<MaterialSummary, NestedKeyOf<MaterialSummary>>[] = [
  {
    id: 'alternateID',
    label: 'Material ID',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/materials/${row.id}` }),
  },
  {
    id: 'materialType',
    label: 'Material Type',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'inProductCost',
    label: 'Cost',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'plantName',
    label: 'Location',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'supplierCompany',
    label: 'Supplier',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'rawMaterialCost',
    label: 'Raw Material Cost',
    type: 'Cost',
    isDisplayed: false,
  },
  {
    id: 'haulingCost',
    label: 'Hauling Cost',
    type: 'Cost',
    isDisplayed: false,
  },
  {
    id: 'plantLoadingCost',
    label: 'Location Loading Cost',
    type: 'Cost',
    isDisplayed: false,
  },
];

const buildApiTableDataModelConfig = ({
  material,
}: {
  material: Material;
}): ApiTableDataWithoutAggregationsParams<
  MaterialSummary,
  'GET material summaries',
  QueryRouteBarrelTypes['GET material summaries']
> => ({
  rowsRouteKey: 'GET material summaries',
  extractRows: (materialList) => materialList.items,
  initialSortBy: {
    name: 'plantName',
    direction: Enums.SortDirection.Ascending,
  },
  initialFilterBy: [
    {
      name: 'name',
      operation: Enums.FilterOperation.Equals,
      value: material.name,
    },
    {
      name: 'id',
      operation: Enums.FilterOperation.NotEquals,
      value: material.id,
    },
  ],
  makeColumnConfigs: () => columns,
});

type SimilarMaterialTableProps = {
  material: Material;
  onEditPress: (value: MaterialSummary) => void;
};

export const SimilarMaterialTable = ({
  material,
  onEditPress,
}: SimilarMaterialTableProps): JSX.Element => {
  const tableModel = useApiTableDataWithoutAggregations(buildApiTableDataModelConfig({ material }));

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' textAlign='center' paddingBottom='1.25rem'>
        <Box alignSelf='center'>
          <Typography variant='h4'>Similar Materials</Typography>
        </Box>
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='materials-similar'
          tableModel={tableModel}
          onEditPress={onEditPress}
        />
      </Box>
    </Box>
  );
};
